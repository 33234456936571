import React from "react";
import "./Provider.css";
import ProviderHeader from "./ProviderHeader";
import ProviderTab from "./ProviderTab";

const Provider = () => {
  return (
    <>
      
          <ProviderTab />
       
    </>
  );
};

export default Provider;
