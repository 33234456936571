import React, { useEffect, useState } from "react";
import "./Wallet.css";
// import Ppay from "../../assets/ppay.png";
import Usdt from "../../assets/usdt.png";
import Trs from "../../assets/trs.png";
import Busd from "../../assets/busd.png";
// import Qr from "../../assets/qr.png";
import ButtonImg from "../../assets/buttonIm.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyPaymentRequest,
  saveAdminPaymentRequest,
} from "../../store/actions/paymentRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Loader } from "../../utills/tools";
import { getPaymentSettings } from "../../store/actions/users";

const Wallet = () => {
  const dispatch = useDispatch();
  const [pRequestdata, setPRequest] = useState([]);
  const [paymentSetting, setPaymentSetting] = useState(null);

  const paymentRequests = useSelector((state) => state.paymentRequest);
  useEffect(() => {
    dispatch(getPaymentSettings()).then((res) => {
      setPaymentSetting(res?.payload?.result?.[0]);
    });
  }, [dispatch]);
  useEffect(() => {
    dispatch(getMyPaymentRequest({ limit: 25, page: 1 }));
  }, [dispatch]);
  useEffect(() => {
    setPRequest(paymentRequests.payRequestList);
  }, [paymentRequests]);

  const handleNewPaymentRequest = (e) => {
    e.preventDefault();
    const targetValue = e.target;
    if (targetValue.amount.value === "") {
      toast.error("Please enter amount", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    if (targetValue.transectionId.value === "") {
      toast.error("Please enter transection id", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    let formData = new FormData();
    if (
      e.target.SaveTxnScreen.files &&
      e.target.SaveTxnScreen.files.length > 0
    ) {
      formData.append("SaveTxnScreen", e.target.SaveTxnScreen.files[0]);
    }
    formData.append("transectionId", targetValue.transectionId.value);
    formData.append("amount", targetValue.amount.value);
    // Handle the cancel order logic here
    dispatch(saveAdminPaymentRequest(formData));
    toast.success("Payment request created successfully", {
      position: "top-right",
      autoClose: 4000,
      onClose: window.location.reload(),
    });
  };

  return (
    <div className="wallet-complete">
      <div className="wallet-main">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-20 items-center">
          <div className=" lg:w-[40%] w-full">
            <h2 className="wallet-heading">Payment Methods</h2>
            <div className="wallet-one">
              <div className="wallet-one-main">
                <div className="wallet-one-data">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}/${paymentSetting?.rule?.qrcode_company}`}
                    alt="qr company logo"
                  />
                  <h4>{paymentSetting?.rule?.qrcode_company_name}</h4>
                </div>
                <div className="wallet-one-data">
                  <img src={Usdt} alt="" />
                  <h4>USDT TRC</h4>
                </div>
                <div className="wallet-one-data">
                  <img src={Busd} alt="" />
                  <h4>BUSD</h4>
                </div>
                <div className="wallet-one-data">
                  <img src={Trs} alt="" />
                  <h4>TRX</h4>
                </div>
              </div>
            </div>
            <div className="">
              <form
                onSubmit={handleNewPaymentRequest}
                className="space-y-4 mt-3 mb-2"
              >
                <div className="col-span-full">
                  <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-5">
                    <div className="col-span-2 md:col-span-1">
                      <label
                        htmlFor="qrImg"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Transection Screenshot
                      </label>
                      <input
                        type="file"
                        id="SaveTxnScreen"
                        name="SaveTxnScreen"
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-3 space-x-3 ">
                  {/* Icon (File input) */}

                  <input
                    placeholder="Transaction Id"
                    id="transectionId"
                    name="transectionId"
                    className="w-full px-4 py-2 text-sm border border-gray-300 rounded-lg text-gray-700 focus:ring-1 focus:ring-blue-500 focus:outline-none"
                  />

                  <input
                    placeholder="Payment Amount"
                    id="amount"
                    name="amount"
                    className="w-full px-4 py-2 mt-2 sm:mt-0 text-sm border border-gray-300 rounded-lg text-gray-700 focus:ring-1 focus:ring-blue-500 focus:outline-none"
                  />
                </div>

                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-800 w-full text-white text-base font-normal flex items-center justify-center space-x-2 py-2 rounded-lg focus:outline-none transition duration-300 ease-in-out"
                >
                  <h2>Send payment for moderation</h2>
                  <img
                    src={ButtonImg}
                    alt="Submit button icon"
                    className="w-5 h-5"
                  />
                </button>
              </form>
            </div>
          </div>
          <div className="">
            <img
              className=" w-full md:h-52 md:w-52 lg:h-[350px] lg:w-[380px] rounded-md shadow-lg"
              src={`${process.env.REACT_APP_IMAGE_URL}/${paymentSetting?.rule?.qrcode_img}`}
              alt="Qr Code"
            />
          </div>
        </div>

        <div className="wallet-three">
          <h1>Name: {paymentSetting?.rule?.qrcode_name}</h1>
          <h2>{paymentSetting?.rule?.qrcode_desc}</h2>
          <h3>
            within 3-5 hour payment will be added <br /> minimum amount for
            topup is rs.100 <br /> If you have not exceeded a deposit to your
            account within 2 hours please contact support
          </h3>
        </div>
      </div>
      <div className="wallet-top">
        <h1>Topup History</h1>
        {pRequestdata && paymentRequests.loading ? (
          <Loader />
        ) : (
          pRequestdata.map((item, index) => (
            <h2 key={item._id}>
              {item.transectionId}{" "}
              {moment(item.payment_date).format("DD/MM/YYYY HH:mm")}{" "}
              {item.amount} {item.payment_status}
            </h2>
          ))
        )}
      </div>
      <form className="w-full">
        <div className="p-4 bg-gray-100 rounded-lg shadow-md">
          <h1 className="text-xl font-semibold mb-4">Promo Code</h1>
          <div className="flex flex-row items-center space-x-4">
            <div className="flex">
              <input
                type="text" // Added type attribute for better semantics
                placeholder="Transaction Id"
                className="w-full  px-4 py-1 border border-gray-300 rounded-lg focus:ring-0 focus:ring-blue-500 focus:outline-none transition duration-200"
              />
            </div>
            <div>
              <button
                type="submit" // Added type attribute to define button purpose
                className="flex items-center justify-center text-white  bg-blue-600 hover:bg-blue-800 px-4 py-1 rounded-lg transition duration-300 ease-in-out"
              >
                <h2 className="mr-2">Top up</h2>
                <img
                  src={ButtonImg}
                  alt="Top up button icon"
                  className="w-5 h-5"
                />
              </button>
            </div>
          </div>
        </div>
      </form>

      <ToastContainer className="custom-toast" />
    </div>
  );
};

export default Wallet;
