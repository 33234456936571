import { createSlice } from "@reduxjs/toolkit";
import {
  getAllTransectionByAdmin,
  getAllUserTransection,
  getProfitReports,
  getTransectionDetailsByAdmin,
} from "../actions/transection";

export const transectionSlice = createSlice({
  name: "transections",
  initialState: {
    loading: false,
    transectionList: [],
    transectionAdminList: [],
    profitReportList: [],
    currentTransection: {},
    totalCount: 0,
  },
  reducers: {
    setCurrentTransection: (state, action) => {
      state.currentTransection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //getAllTransectionByAdmin
      .addCase(getProfitReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfitReports.fulfilled, (state, action) => {
        state.loading = false;
        state.profitReportList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getProfitReports.rejected, (state) => {
        state.loading = false;
      })
      //getAllTransectionByAdmin
      .addCase(getAllTransectionByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTransectionByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.transectionAdminList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllTransectionByAdmin.rejected, (state) => {
        state.loading = false;
      })
      //getAllUserTransection
      .addCase(getAllUserTransection.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUserTransection.fulfilled, (state, action) => {
        state.loading = false;
        state.transectionList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllUserTransection.rejected, (state) => {
        state.loading = false;
      })
      //getTransectionDetailsByAdmin
      .addCase(getTransectionDetailsByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTransectionDetailsByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.currentTransection = action.payload.result;
      })
      .addCase(getTransectionDetailsByAdmin.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentTransection } = transectionSlice.actions;
export default transectionSlice.reducer;
