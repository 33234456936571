import { createSlice } from "@reduxjs/toolkit";
import { login, logout, registerUser } from "../actions/auth";
import { isAuth, updateUserApiKey } from "../actions/users";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    data: {
      id: "",
      name: "",
      email: "",
      apiKey: "",
      wallet: {},
      extWallet: 0,
      setting: {},
    },

    token: null,
    auth: false,
    isAdmin: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //register
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state) => {
        state.loading = false;
      })
      //Sign In
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.data = {
          _id: action.payload.user._id,
          email: action.payload.user.email,
          name: action.payload.user.name,
          apiKey: action.payload.user.apiKey,
          role: action.payload.user.roleId,
        };
        state.auth = true;
        state.isAdmin =
          parseInt(action.payload.user.roleId) === 0 ? true : false;
        state.token = action.payload.token;
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("users", action.payload.user);
      })
      .addCase(login.rejected, (state) => {
        state.loading = false;
        state.auth = false;
      })
      // Sign out
      .addCase(logout.fulfilled, (state) => {
        state.data = {
          loading: false,
          user: {
            id: "",
            name: false,
            email: "",
            apiKey: "",
            wallet: "",
            extWallet: 0,
          },
        };
        state.auth = false;
        state.isAdmin = false;
        state.token = null;
        localStorage.removeItem("token");
      })
      //isAuth
      .addCase(isAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(isAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.data = {
          _id: action.payload.data._id,
          email: action.payload.data.email,
          name: action.payload.data.name,
          role: action.payload.data.roleId,
          apiKey: action.payload.data.apiKey,
          wallet: action.payload.data.wallet,
          setting: action.payload.data.setting,
          extWallet: 0,
        };
        state.auth = true;
        state.isAdmin =
          parseInt(action.payload.data.roleId) === 0 ? true : false;
        state.token = localStorage.getItem("token");
      })
      .addCase(isAuth.rejected, (state) => {
        state.loading = false;
        state.auth = false;
      })
      //updateUserApiKey
      .addCase(updateUserApiKey.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserApiKey.fulfilled, (state, action) => {
        state.loading = false;
        state.data = {
          apiKey: action.payload.user.apiKey,
        };
      })
      .addCase(updateUserApiKey.rejected, (state) => {
        state.loading = false;
        state.auth = false;
      });
  },
});

export default authSlice.reducer;
