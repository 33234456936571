import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";

const FaqHeader = ({ onSearch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [searchText, setSearchText] = useState("");

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setShowCloseButton(true);
    onSearch({
      limit: "10",
      page: "1",
      keywordName: "questions",
      keyword: e.target.defaultSearch.value,
    });
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchClose = (e) => {
    e.preventDefault();
    setSearchText("");
    setShowCloseButton(false);
    onSearch({
      limit: "10",
      page: "1",
    });
  };

  return (
    <>
      <div className="header-hederTwo bg-slate-50">
        <div className="admin-headerTwo flex flex-col sm:flex-row justify-between items-center">
          {/* Search Form */}
          <div className="flex items-center w-full lg:w-[50%]  md:w-[50%]">
            <form className=" flex w-full " onSubmit={handleSearch}>
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-3 h-3 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  name="defaultSearch"
                  value={searchText}
                  onChange={handleSearchText}
                  className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-0 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-0 dark:focus:border-blue-500"
                  placeholder="Search..."
                  required
                />

                <button
                  type="submit"
                  className="text-white absolute right-0 bottom-1 top-1 bg-blue-500 hover:bg-blue-700  focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-4 py-0 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Search
                </button>
                {showCloseButton && (
                  <button
                    type="submit"
                    className="text-white absolute right-20 bottom-1 top-1 bg-red-500 hover:bg-red-700  focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-2 py-0 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={handleSearchClose}
                  >
                    Clear
                  </button>
                )}
              </div>
            </form>
          </div>
          {/* Add New Button and Modal */}
          <div className="flex items-center justify-end w-full sm:w-auto mt-0 sm:mt-0">
            <button
              onClick={toggleModal}
              className="action-btn add-new text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5"
            >
              <IoMdAdd /> Add New
            </button>

            {isModalOpen && (
              <div
                id="crud-modal"
                className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-50"
              >
                <div className="relative p-4 w-full max-w-md h-auto">
                  {/* Modal Content */}
                  <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    {/* Modal Header */}
                    <div className="flex items-center justify-between p-4 border-b rounded-t">
                      <h3 className="text-lg font-semibold">
                        Create New Entry
                      </h3>
                      <button
                        type="button"
                        className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg w-8 h-8"
                        onClick={toggleModal}
                      >
                        <IoClose className="w-5 h-5" />
                      </button>
                    </div>

                    {/* Modal Body */}
                    <form className="p-4">
                      <div className="grid gap-4 mb-4">
                        <div className="col-span-2">
                          <label
                            htmlFor="question"
                            className="block mb-2 text-sm"
                          >
                            Question
                          </label>
                          <textarea
                            id="question"
                            rows="3"
                            className="block w-full p-2.5 text-sm rounded-lg border bg-gray-50 focus:ring-primary-600"
                            placeholder="Enter the question"
                            required
                          ></textarea>
                        </div>

                        <div className="col-span-2">
                          <label
                            htmlFor="answer"
                            className="block mb-2 text-sm"
                          >
                            Answer
                          </label>
                          <textarea
                            id="answer"
                            rows="4"
                            className="block w-full p-2.5 text-sm rounded-lg border bg-gray-50 focus:ring-primary-600"
                            placeholder="Enter the answer"
                            required
                          ></textarea>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="text-white w-full justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqHeader;
