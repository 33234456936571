import { createSlice } from "@reduxjs/toolkit";
import {
  saveAdminPaymentRequest,
  getMyPaymentRequest,
  getAllPaymentRequestByAdmin,
  getMyPaymentRequestDetails,
  editPaymentRequestByAdmin,
  approvePaymentRequestByAdmin,
  addMoneyToUser,
  withdrawMoneyFromUser,
} from "../actions/paymentRequest";

export const paymentRequestSlice = createSlice({
  name: "paymentRequest",
  initialState: {
    loading: false,
    payRequestList: [],
    adminPayRequestList: [],
    currentPRequest: {},
    totalCount: 0,
  },
  reducers: {
    setCurrentPRequest: (state, action) => {
      state.currentPRequest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //savePaymentRequest
      .addCase(saveAdminPaymentRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveAdminPaymentRequest.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(saveAdminPaymentRequest.rejected, (state) => {
        state.loading = false;
      })
      //getMyPaymentRequest
      .addCase(getMyPaymentRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyPaymentRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.payRequestList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getMyPaymentRequest.rejected, (state) => {
        state.loading = false;
      })
      //getAllPaymentRequest
      .addCase(getAllPaymentRequestByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPaymentRequestByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.adminPayRequestList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllPaymentRequestByAdmin.rejected, (state) => {
        state.loading = false;
      })
      //getPaymentRequestDetails
      .addCase(getMyPaymentRequestDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyPaymentRequestDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currentPRequest = { ...action.payload.data };
      })
      .addCase(getMyPaymentRequestDetails.rejected, (state) => {
        state.loading = false;
      })
      //editPaymentRequest
      .addCase(editPaymentRequestByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(editPaymentRequestByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.currentPRequest = { ...action.payload.data.result };
      })
      .addCase(editPaymentRequestByAdmin.rejected, (state) => {
        state.loading = false;
      })
      //approvePaymentRequest
      .addCase(approvePaymentRequestByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(approvePaymentRequestByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.currentPRequest = { ...action.payload.result };
      })
      .addCase(approvePaymentRequestByAdmin.rejected, (state) => {
        state.loading = false;
      })
      //addMoneyToUser
      .addCase(addMoneyToUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(addMoneyToUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addMoneyToUser.rejected, (state) => {
        state.loading = false;
      })
      //withdrawMoneyFromUser
      .addCase(withdrawMoneyFromUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(withdrawMoneyFromUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(withdrawMoneyFromUser.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentService } = paymentRequestSlice.actions;
export default paymentRequestSlice.reducer;
