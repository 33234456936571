import React, { useState, useEffect } from "react";
import { BiLogOut } from "react-icons/bi";
// import { LuLayoutDashboard } from "react-icons/lu";
import {
  MdOutlineManageAccounts,
  MdAddShoppingCart,
  MdOutlineAccountBalanceWallet,
} from "react-icons/md";
import { FaQrcode } from "react-icons/fa6";
import { AiOutlineApi } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
// import { IoTicketOutline } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";

import { Link, useLocation, useNavigate } from "react-router-dom";
import "./AdminSidebar.css";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/auth";

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [financialDropdown, setFinancialDropdown] = useState(false);
  const [settingsDropdown, setSettingsDropdown] = useState(false);
  const location = useLocation(); // Hook to get the current route
  // Function to toggle dropdown
  const toggleDropdown = (dropdownSetter) => {
    dropdownSetter((prev) => !prev);
  };

  const handleSignout = () => {
    dispatch(logout());
    setTimeout(() => {
      navigate("/");
    }, 600);
  };

  return (
    <div>
      <div className="admin-side flex flex-col justify-start items-center bg-white w-full  text-gray-800 md:block">
        {/* Sidebar menu */}
        <div className="flex-1 mt-4 w-full">
          <ul className="sidebar-menu w-full">
          <li
              className={`flex items-center p-1 my-3 bg-white rounded-lg transition-all ease-in-out hover:bg-teal-500 ${
                location.pathname === "/admin" ? "active" : ""
              }`}
            >
              <Link
                to="/admin"
                className="flex items-center text-gray-800 text-lg"
              >
                <RxDashboard className="sidebar-icon text-2xl mr-3" />
                Dashboard
              </Link>
            </li>
            <li
              className={`flex items-center p-1 my-3 bg-white rounded-lg transition-all ease-in-out hover:bg-teal-500 ${
                location.pathname === "/admin/userManagement" ? "active" : ""
              }`}
            >
              <Link
                to="/admin/userManagement"
                className="flex items-center text-gray-800 text-lg"
              >
                <MdOutlineManageAccounts className="sidebar-icon text-2xl mr-3" />
                User Management
              </Link>
            </li>

            <li
              className={`flex items-center p-2 my-3 bg-white rounded-lg transition-all ease-in-out hover:bg-teal-500 hover:shadow-lg  ${
                location.pathname === "/admin/order" ? "active" : ""
              }`}
            >
              <Link
                to="/admin/order"
                className="flex items-center text-gray-800 text-lg"
              >
                <MdAddShoppingCart className="sidebar-icon text-2xl mr-3" />
                Orders details
              </Link>
            </li>

            {/* Financial Dropdown */}
            <Link to="/admin/financial">
              <li
                className="dropdown flex items-center p-2 my-3 bg-white rounded-lg cursor-pointer transition-all ease-in-out hover:bg-teal-500 hover:shadow-lg "
                onClick={() => toggleDropdown(setFinancialDropdown)}
              >
                <MdOutlineAccountBalanceWallet className="sidebar-icon text-2xl mr-3" />
                Admin Management
              </li>
            </Link>
            {financialDropdown && (
              <ul className="dropdown-menu list-none mt-1 ml-3">
                <li
                  className={`py-2 ${
                    location.pathname === "/admin/financial/paymentRequest"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="financial/paymentRequest"
                    className="text-gray-800 hover:text-white hover:bg-teal-500 px-3 rounded-md"
                  >
                    Payment Request
                  </Link>
                </li>
                <li
                  className={`py-2 ${
                    location.pathname === "/admin/financial" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/admin/financial"
                    className="text-gray-800 hover:text-white hover:bg-teal-500 px-3 rounded-md"
                  >
                    User Ledger
                  </Link>
                </li>
                <li
                  className={`py-2 ${
                    location.pathname === "/admin/financial/adminLedger"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="financial/adminLedger"
                    className="text-gray-800 hover:text-white hover:bg-teal-500 px-3 rounded-md"
                  >
                    Admin Wallet History
                  </Link>
                </li>
                <li
                  className={`py-2 ${
                    location.pathname === "/admin/financial/report"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="financial/report"
                    className="text-gray-800 hover:text-white hover:bg-teal-500 px-3 rounded-md"
                  >
                    Profit & Loss
                  </Link>
                </li>
              </ul>
            )}
            <li
              className={`flex items-center p-2 my-3 bg-white rounded-lg transition-all ease-in-out hover:bg-teal-500 hover:shadow-lg ${
                location.pathname === "/admin/faq" ? "active" : ""
              }`}
            >
              <Link
                to="/admin/faq"
                className="flex items-center text-gray-800 text-lg"
              >
                <FaQrcode className="sidebar-icon text-2xl mr-3" />
                FAQ
              </Link>
            </li>

            <li
              className={`flex items-center p-2 my-3 bg-white rounded-lg transition-all ease-in-out hover:bg-teal-500 hover:shadow-lg ${
                location.pathname === "/admin/api" ? "active" : ""
              }`}
            >
              <Link
                to="/admin/api"
                className="flex items-center text-gray-800 text-lg"
              >
                <AiOutlineApi className="sidebar-icon text-2xl mr-3" />
                API Document
              </Link>
            </li>

            {/* Settings Dropdown */}
            <Link to="/admin/settings">
              <li
                className="dropdown flex items-center p-2 my-3 bg-white rounded-lg cursor-pointer transition-all ease-in-out hover:bg-teal-500 hover:shadow-lg"
                onClick={() => toggleDropdown(setSettingsDropdown)}
              >
                <FiSettings className="sidebar-icon text-2xl mr-3" />
                Settings
              </li>
            </Link>
            {settingsDropdown && (
              <ul className="dropdown-menu list-none mt-1 ml-3">
                <li
                  className={`py-2 ${
                    location.pathname === "/admin/settings" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/admin/settings"
                    className="text-gray-800 hover:text-white hover:bg-teal-500 px-3 rounded-md"
                  >
                    Services List
                  </Link>
                </li>
                {/* <li className="py-2">
                <Link
                  to="settings/category"
                  className="text-gray-800 hover:text-white hover:bg-teal-500 px-3 rounded-md"
                >
                  Category
                </Link>
              </li> */}
                <li
                  className={`py-2 ${
                    location.pathname === "/admin/settings/country"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="settings/country"
                    className="text-gray-800 hover:text-white hover:bg-teal-500 px-3 rounded-md"
                  >
                    Country List
                  </Link>
                </li>
                <li
                  className={`py-2 ${
                    location.pathname === "/admin/settings/provider"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="settings/provider"
                    className="text-gray-800 hover:text-white hover:bg-teal-500 px-3 rounded-md"
                  >
                    Provider & Pricing
                  </Link>
                </li>
              </ul>
            )}
          </ul>
        </div>

        {/* Logout button */}
        <button className="logout-btn" onClick={handleSignout}>
          <BiLogOut className="logout-icon" />
          <h2 className="">Log out</h2>
        </button>
      </div>
    </div>
  );
};

export default AdminSidebar;
