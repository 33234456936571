import { configureStore } from "@reduxjs/toolkit";
import SiteReducer from "./reducers/site";
import AuthReducer from "./reducers/auth";
import NotificationReducer from "./reducers/notification";
import ApiReducer from "./reducers/api";
import CounteryReducer from "./reducers/countery";
import FaqReducer from "./reducers/faq";
import OrderReducer from "./reducers/order";
import PaymentRequestReducer from "./reducers/paymentRequest";
import ServiceReducer from "./reducers/service";
import ProviderReducer from "./reducers/provider";
import UserManagementReducer from "./reducers/userManagement";
import TransectionReducer from "./reducers/transections";
import WalletsReducer from "./reducers/wallets";

export const store = configureStore({
  reducer: {
    site: SiteReducer,
    notifications: NotificationReducer,
    auth: AuthReducer,
    countery: CounteryReducer,
    faqs: FaqReducer,
    api: ApiReducer,
    order: OrderReducer,
    paymentRequest: PaymentRequestReducer,
    service: ServiceReducer,
    provider: ProviderReducer,
    transection: TransectionReducer,
    userManagement: UserManagementReducer,
    wallets: WalletsReducer,
  },
  devTools:false,
});
