import axios from "axios";
import { errorGlobal, successGlobal } from "../reducers/notification";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthHeader } from "../../utills/tools";

export const saveCountries = createAsyncThunk(
  "user/country/save",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/countery/new`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const editCountries = createAsyncThunk(
  "user/country/edit",
  async (data, { dispatch }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/countery/${data.id}`,
        data.data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getDetailCountries = createAsyncThunk(
  "user/country/detail",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/countery/${id}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getAllCountries = createAsyncThunk(
  "user/country/all",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/countery/all`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const removeCountry = createAsyncThunk(
  "user/country/detail",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/countery/${id}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);
