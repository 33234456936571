import React from "react";
import { FaFlagUsa } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-600 text-white w-full py-4 md:fixed lg:fixed bottom-0 z-10">
      <div className="container mx-auto flex flex-col items-center justify-between sm:flex-row gap-4">
        {/* Main Section */}
        <div className="flex items-center gap-2">
          <h4 className="text-sm font-medium">
            Copyright @2022 All rights reserved.
          </h4>
          <FaFlagUsa className="text-base hidden sm:block" />
        </div>

        {/* Links Section */}
        <div className="flex items-center gap-6">
          <h4 className="text-sm font-medium cursor-pointer">Privacy Policy</h4>
          <h4 className="text-sm font-medium cursor-pointer">
            Terms and Conditions
          </h4>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
