import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminLayout from '../adminComponent/adminLayout/AdminLayout';

const index = () => {
  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  )
}

export default index