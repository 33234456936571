import React from "react";
import OrdersTab from "./ordersTab/OrdersTab";

const Orders = () => {
  return (
    <>
  <OrdersTab/>
    </>
  );
};

export default Orders;
