import React from "react";
import "./Faq.css";
// import FaqHeader from "./FaqHeader";
import FaqTab from "./faqTab";

const Faq = () => {
  return (
    <>
      <div className="flex flex-col relative ml-2">
        
        <div className="mt-0">
          <FaqTab />
        </div>
      </div>
    </>
  );
};

export default Faq;
