import React from "react";
import ApiTab from "./ApiTab";

const Api = () => {
  return (
    <>
      <div className="flex  ml-2">
        <div className="mt-0 w-full">
          <ApiTab />
        </div>
      </div>
    </>
  );
};

export default Api;
