import { createSlice } from "@reduxjs/toolkit";
import {
  getAllFaq,
  saveFaq,
  editFaq,
  getFaqDetails
} from "../actions/faq";

export const faqSlice = createSlice({
  name: "faqs",
  initialState: {
    loading: false,
    faqsList: [],
    currentFaqs: {},
    totalCount: 0,
  },
  reducers: {
    setCurrentFaqs: (state, action) => {
      state.currentFaqs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //getAllFaq
      .addCase(getAllFaq.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFaq.fulfilled, (state, action) => {
        state.loading = false;
        state.faqsList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllFaq.rejected, (state) => {
        state.loading = false;
      })
      //saveFaq
      .addCase(saveFaq.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveFaq.fulfilled, (state, action) => {
        state.loading = false;
        state.faqsList = [...state.faqsList, action.payload.data.result];
      })
      .addCase(saveFaq.rejected, (state) => {
        state.loading = false;
      })

      //editFaq
      .addCase(editFaq.pending, (state) => {
        state.loading = true;
      })
      .addCase(editFaq.fulfilled, (state, action) => {
        state.loading = false;
        state.faqsList = [...state.faqsList, action.payload.data.result];
      })
      .addCase(editFaq.rejected, (state) => {
        state.loading = false;
      })
      //getFaqDetails
      .addCase(getFaqDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFaqDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currentFaqs = action.payload.data.result;
      })
      .addCase(getFaqDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentCategory } = faqSlice.actions;
export default faqSlice.reducer;
