import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const AuthGuard = (props) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  let location = useLocation();
  if (!token && role !== 1) {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return props.children;
};

export default AuthGuard;
