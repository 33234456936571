import React, { useState } from "react";
import { IoSearch } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoClose } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import { useDispatch } from "react-redux";
import { saveAPI } from "../../store/actions/apis";

const ApiHeader = ({ onSearch }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [searchText, setSearchText] = useState("");

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setShowCloseButton(true);
    onSearch({
      limit: "10",
      page: "1",
      keywordName: ["name", "desc", "types", "url"],
      keyword: e.target.defaultSearch.value,
    });
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const handleClearSearch = () => {
    onSearch({
      limit: "10",
      page: "1",
    });
    setShowCloseButton(false);
    setSearchText("");
  };

  const handelAddApi = (e) => {
    e.preventDefault();
    dispatch(
      saveAPI({
        name: e.target.name.value,
        desc: e.target.desc.value,
        url: e.target.url.value,
        parameter: e.target.parameter.value,
        body: e.target.body.value,
        types: e.target.types.value,
      })
    );
    toast.success("Add new api successfully!", {
      position: "top-right",
      autoClose: 600,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="admin-headerTwo flex flex-col sm:flex-row justify-between items-center  bg-slate-50 z-10">
        {/* Search Form */}
        <div className="flex items-center w-full lg:w-[50%]  md:w-[50%]">
          <form className="flex w-full" onSubmit={handleSearch}>
            <label htmlFor="default-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-3 h-3 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                name="defaultSearch"
                value={searchText}
                onChange={handleSearchText}
                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-0 focus:border-blue-500"
                placeholder="Search..."
                required
              />
              <button
                type="submit"
                className="text-white absolute right-0 bottom-1 top-1 bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none font-medium rounded-lg text-sm px-4 py-0 mr-2"
              >
                Search
              </button>
              {showCloseButton && (
                <button
                  type="submit"
                  className="text-white absolute right-20 bottom-1 top-1 bg-red-500 hover:bg-red-700  focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-2 py-0 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={handleClearSearch}
                >
                  Clear
                </button>
              )}
            </div>
          </form>
        </div>

        {/* Add New Button and Modal */}
        <div className="flex items-center justify-end w-full sm:w-auto mt-0 sm:mt-0">
          <button
            onClick={toggleModal}
            className="action-btn add-new text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5"
          >
            <IoMdAdd /> Add New
          </button>

          {isModalOpen && (
            <div
              id="crud-modal"
              className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-50"
            >
              <div className="relative p-4 w-full max-w-md h-auto">
                {/* Modal Content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* Modal Header */}
                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      Create New Api
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={toggleModal}
                    >
                      <IoClose className="w-5 h-5" />
                    </button>
                  </div>

                  {/* Modal Body */}
                  <div className="p-4 md:p-5 max-h-96 overflow-y-auto">
                    {" "}
                    {/* Added max height and overflow */}
                    <form onSubmit={handelAddApi}>
                      <div className="grid gap-4 mb-4 grid-cols-2">
                        <div className="col-span-2">
                          <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Service Name"
                            required
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Types
                          </label>
                          <input
                            type="text"
                            id="types"
                            name="types"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="types"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <label
                            htmlFor="url"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            URL
                          </label>
                          <input
                            type="text"
                            id="url"
                            name="url"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Enter URL"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <label
                            htmlFor="description"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Description
                          </label>
                          <textarea
                            id="description"
                            rows="3"
                            name="desc"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-600 focus:border-primary-600 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Enter service description"
                            required
                          ></textarea>
                        </div>

                        <div className="col-span-2">
                          <label
                            htmlFor="parameter"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Parameter
                          </label>
                          <input
                            type="text"
                            id="parameter"
                            name="parameter"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Enter parameter"
                          />
                        </div>

                        <div className="col-span-2">
                          <label
                            htmlFor="body"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Body
                          </label>
                          <textarea
                            id="body"
                            rows="4"
                            name="body"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-600 focus:border-primary-600 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Enter request body"
                          ></textarea>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="text-white inline-flex w-full justify-center items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ApiHeader;
