import { toast } from "react-toastify";

export const Loader = () => (
  <div className="flex justify-center items-center h-screen">
    <div
      className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export const showToast = (type, msg) => {
  switch (type) {
    case "SUCCESS":
      toast.success(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      break;
    case "ERROR":
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      break;
    default:
      return false;
  }
};

export const getTokenCookie = () => localStorage.getItem("token");
export const removeTokenCookie = () => localStorage.removeItem("token");
export const getAuthHeader = () => {
  return { headers: { Authorization: `Bearer ${getTokenCookie()}` } };
};
export const getPostAuthHeader = () => {
  return { headers: { Authorization: `Bearer ${getTokenCookie()}` } };
};

export const ApiUrl = "http://localhost:4002";
