import React, { useEffect, useState } from "react";
import moment from "moment";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserOrders } from "../../store/actions/order";
import { Loader } from "../../utills/tools";
// import India from "../../assets/india.png";

const headers = [
  { keys: "Date & Time" },
  // { keys: "Order ID" },
  { keys: "Service" },
  { keys: "Number" },
  { keys: "Status" },
  // { keys: "Op. bal." },
  { keys: "Price" },
  // { keys: "Cl. Bal." },
];

const History = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filterQuery, setFilterQuery] = useState({
    limit: "10",
    page: "1",
    status: "0",
  });

  //redux logic
  const orders = useSelector((state) => state.order);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(orders.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  useEffect(() => {
    dispatch(getAllUserOrders(filterQuery));
  }, [filterQuery, setFilterQuery]);

  useEffect(() => {
    setProducts(orders.orderList);
  }, [orders]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);
    setShowCloseButton(true);
    setFilterQuery({ ...filterQuery, keyword: searchTerm });
  };

  const handleSearchClose = () => {
    setShowCloseButton(false);
    setSearchValue("");
    setFilterQuery({
      limit: "10",
      page: "1",
      status: "0",
    });
  };

  return (
    <>
      <div className="myNumber-all">
        <div className="fixed dark:bg-textB top-20 left-0 flex flex-col bg-slate-50 z-10  sm:flex-row items-center justify-end px-1 py-1 w-full gap-2 ">
          <div className="  relative w-full md:w-[24%] sm:w-full">
            <i className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-textW">
              <IoSearchOutline />
            </i>
            <input
              type="text"
              placeholder="Search"
              name="searchKey"
              className="w-full pl-8 py-2 border rounded-md bg-transparent focus:ring-0 focus:ring-blue-500 focus:outline-none text-sm dark:text-textW"
              value={searchValue}
              onChange={handleSearch}
            />
            {showCloseButton && (
              <button
                type="submit"
                className="text-red-500 absolute right-0 bottom-1 top-1  focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-2 py-0 mr-2 "
                onClick={handleSearchClose}
              >
                ❌
              </button>
            )}
          </div>
        </div>
        {orders && orders.loading ? (
          <Loader />
        ) : (
          <div className=" shadow-md sm:rounded-lg mt-10 ">
            {products && products.length == 0 ? (
              <>
                <div className="flex justify-center items-center md:my-[8rem] lg:my-[8rem]">
                  <h1 className="text-3xl align-center md:mb-[8rem] lg:mb-[8rem]">
                    You have Yet to buy any numbers!
                  </h1>
                </div>
              </>
            ) : (
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-textW">
                  <tr>
                    {headers.map((header, index) => (
                      <th scope="col" key={index} className="px-6 py-3">
                        {header.keys}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="overflow-y-auto">
                  {products &&
                    products.map((product, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 dark:text-textW"
                      >
                        <td className="px-6 py-4">
                          {moment(new Date(product?.createdAt)).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        {/* <td className="px-6 py-4 ">{product?.oReqId}</td> */}

                        <td className="px-6 py-4 ">
                          {/* Service name and icon */}
                          <div className="flex items-center gap-2 text-sm ">
                            <img
                              // src={product?.serviceId.icon}
                              src={`${process.env.REACT_APP_IMAGE_URL}/${product?.serviceId.icon}`}
                              // src={India}
                              alt="Service Icon"
                              className="h-4 w-4 object-cover"
                            />
                            <p>{product?.serviceId.name}</p>
                          </div>

                          {/* Provider name and icon */}
                          <div className="flex items-center gap-2 mt-1 text-sm">
                            <img
                              // src={product?.providerId.icon}
                              src={`${process.env.REACT_APP_IMAGE_URL}/${product?.providerId.icon}`}
                              // src={India}
                              alt="Provider Icon"
                              className="h-4 w-4 object-cover"
                            />
                            <p>{product?.providerId.name}</p>
                          </div>
                        </td>

                        {/* <td className="px-6 py-4">{product?.providerId.name}</td> */}
                        <td className="px-6 py-4">{product?.mobileNo}</td>
                        <td className="px-6 py-4">{product?.order_status}</td>
                        {/* <td className="px-6 py-4">
                          {(product?.transections &&
                            product?.transections?.[0]?.opening_balance) ||
                            "none"}
                        </td> */}
                        <td className="px-6 py-4">{product?.amount}</td>
                        {/* <td className="px-6 py-4">
                          {product?.transections &&
                          product?.order_status == "CANCELED"
                            ? product?.transections[1]?.closing_balance
                            : product?.transections?.[0]?.closing_balance ||
                              "none"}
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            )}

            {/* Pagination */}
          </div>
        )}
        <nav
          className=" flex flex-col md:flex-row lg:flex-row gap-2 items-center md:justify-between lg:justify-between mt-2 bg-white py-2 mb-2 "
          aria-label="Table navigation"
        >
          <div className=" flex flex-row gap-2">
            <h4 className="text-base font-medium text-black"> Showing</h4>
            <span className="font-medium text-black text-base">
              {indexOfFirstRow + 1} -{" "}
              {Math.min(indexOfLastRow, orders.totalCount)}
            </span>{" "}
            of{" "}
            <span className="font-medium text-black text-base">
              {orders.totalCount}
            </span>
          </div>

          <ul className="inline-flex items-center -space-x-px text-sm h-8">
            {/* Previous Button */}
            <li>
              <button
                className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-black bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-black dark:bg-gray-800 dark:border-gray-700 dark:text-textW "
                onClick={handlePrev}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>

            {/* Page Buttons */}
            {currentPage > 2 && (
              <li>
                <button
                  className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => goToPage(1)}
                >
                  1
                </button>
              </li>
            )}

            {currentPage > 3 && <li className="px-2">...</li>}

            {/* Previous Page */}
            {currentPage > 1 && (
              <li>
                <button
                  className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => goToPage(currentPage - 1)}
                >
                  {currentPage - 1}
                </button>
              </li>
            )}

            {/* Current Page */}
            <li>
              <button
                className="flex items-center justify-center px-3 h-8 leading-tight font-medium text-blue-600 bg-blue-50 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => goToPage(currentPage)}
              >
                {currentPage}
              </button>
            </li>

            {/* Next Page */}
            {currentPage < totalPages && (
              <li>
                <button
                  className="flex items-center justify-center px-3 h-8 leading-tight text-black font-medium bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => goToPage(currentPage + 1)}
                >
                  {currentPage + 1}
                </button>
              </li>
            )}

            {currentPage < totalPages - 2 && <li className="px-2">...</li>}

            {currentPage < totalPages - 1 && (
              <li>
                <button
                  className="flex items-center justify-center px-3 h-8 leading-tight text-black font-medium bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => goToPage(totalPages)}
                >
                  {totalPages}
                </button>
              </li>
            )}

            {/* Next Button */}
            <li>
              <button
                className="flex items-center justify-center px-3 h-8 leading-tight font-medium text-black bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-textW "
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default History;
