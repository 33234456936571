import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    global:{
        error:false,
        success:false,
        msg:"",
    },
  },
  reducers: {
    errorGlobal: (state, action) => {
      state.global.error = true;
      state.global.success = false;
      state.global.msg = action.payload;
    },
    successGlobal: (state, action) => {
      state.global.error = false;
      state.global.success = true;
      state.global.msg = action.payload;
    },
    clearNotifications: (state) => {
      state.global.error = false;
      state.global.success = false;
      state.global.msg = "";
    }
  },
});

export const { errorGlobal, successGlobal, clearNotifications } = notificationsSlice.actions;
export default notificationsSlice.reducer;