import { createSlice } from "@reduxjs/toolkit";
import {
  getCurrentReport,
  getMyWallet,
  getOrderReport,
  getUserReport,
} from "../actions/wallets";

export const walletsSlice = createSlice({
  name: "wallets",
  initialState: {
    wallet: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      ///getMyWallet
      .addCase(getMyWallet.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyWallet.fulfilled, (state, action) => {
        state.loading = false;
        state.wallet = action.payload.data;
      })
      .addCase(getMyWallet.rejected, (state) => {
        state.loading = false;
      })
      ///getCurrentReport
      .addCase(getCurrentReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentReport.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getCurrentReport.rejected, (state) => {
        state.loading = false;
      })
      ///getOrderReport
      .addCase(getOrderReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderReport.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getOrderReport.rejected, (state) => {
        state.loading = false;
      })
      ///getUserReport
      .addCase(getUserReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserReport.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getUserReport.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default walletsSlice.reducer;
