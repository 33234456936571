import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./HeaderTwo.css";
import { FaUser, FaHistory, FaQuestionCircle } from "react-icons/fa"; // Importing relevant icons
import { AiOutlineApi } from "react-icons/ai";

const HeaderTwo = () => {
  const location = useLocation(); // Get the current location

  // Function to check if a link is active
  const isActive = (path) => location.pathname === path;

  return (
    <>
      <div className="fixed  w-full z-10 border-b border-gray-200 dark:border-gray-700 mt-12 md:mt-11 lg:md-11   ">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center md:gap-4 text-black dark:text-textW bg-slate-100  dark:bg-slate-800 w-full  ">
          {/* Get a real number */}
          <li className="me-1">
            <Link
              to="/user"
              className={`inline-flex items-center justify-center p-2 border-b-2  font-normal  ${
                isActive("/user") ? "active-tab" : "border-transparent"
              } rounded-t-lg tab-hover dark:hover:text-gray-300 group`}
            >
              <FaUser className="hidden md:inline-flex w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-textW dark:group-hover:text-gray-300" />
              Get Service
            </Link>
          </li>

          {/* My Numbers */}
          <li className="me-1">
            <Link
              to="myNumber"
              className={`inline-flex items-center justify-center p-2 border-b-2 font-normal ${
                isActive("/user/myNumber") ? "active-tab" : "border-transparent"
              } rounded-t-lg tab-hover dark:hover:text-gray-300 group`}
            >
              <FaUser className=" hidden md:inline-flex w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-textW dark:group-hover:text-gray-300" />
              My Numbers
            </Link>
          </li>

          {/* History */}
          <li className="me-1">
            <Link
              to="history"
              className={`inline-flex items-center justify-center p-2 border-b-2 font-normal ${
                isActive("/user/history") ? "active-tab" : "border-transparent"
              } rounded-t-lg tab-hover dark:hover:text-gray-300 group`}
            >
              <FaHistory className="hidden md:inline-flex w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-textW dark:group-hover:text-gray-300" />
              Txn History
            </Link>
          </li>

          {/* Api */}
          <li className="me-1">
            <Link
              to="userApi"
              className={`inline-flex items-center justify-center p-2 border-b-2 font-normal ${
                isActive("/user/userApi") ? "active-tab" : "border-transparent"
              } rounded-t-lg tab-hover dark:hover:text-gray-300 group`}
            >
              <AiOutlineApi className="hidden md:inline-flex w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-textW dark:group-hover:text-gray-300" />
              Api
            </Link>
          </li>

          {/* FAQ */}
          <li className="me-1">
            <Link
              to="userFaq"
              className={`inline-flex items-center justify-center p-2 border-b-2 font-normal ${
                isActive("/user/userFaq") ? "active-tab" : "border-transparent"
              } rounded-t-lg tab-hover dark:hover:text-gray-300 group`}
            >
              <FaQuestionCircle className="hidden md:inline-flex w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-textW dark:group-hover:text-gray-300" />
              FAQ
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default HeaderTwo;
