import axios from "axios";
import { errorGlobal, successGlobal } from "../reducers/notification";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthHeader } from "../../utills/tools";

export const getAllServices = createAsyncThunk(
  "user/service",
  async (data, { dispatch }) => {
    try {
      const qurey = new URLSearchParams(data).toString();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/service/all?${qurey}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

//Admin APi Parts
export const saveServicesByAdmin = createAsyncThunk(
  "admin/service/save",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/service/new`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getAllServiceByAdmin = createAsyncThunk(
  "admin/service/all",
  async (query, { dispatch }) => {
    try {
      const query = "limit=10000";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/service/all?${query}`,
        // `${process.env.REACT_APP_API_URL}/service/all?`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getServiceDetails = createAsyncThunk(
  "admin/service/details",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/service/service/${id}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const editServicesByAdmin = createAsyncThunk(
  "admin/service/edit",
  async (data, { dispatch }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/service/service/${data.id}`,
        data.data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const removeServicesByAdmin = createAsyncThunk(
  "admin/service/remove",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/service/service/${id}`,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);
