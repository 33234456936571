import { createSlice } from "@reduxjs/toolkit";
import {
  saveOrder,
  editOrder,
  getAllUserOrders,
  getAllOrders,
  getOrderDetails,
  cancelOrder,
  getAllActiveUserOrders,
} from "../actions/order";

export const orderSlice = createSlice({
  name: "orders",
  initialState: {
    loading: false,
    orderList: [],
    adminOrderList: [],
    currentOrder: {},
    totalCount: 0,
  },
  reducers: {
    setCurrentOrder: (state, action) => {
      state.currentOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //saveOrder
      .addCase(saveOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.currentOrder = {};
      })
      .addCase(saveOrder.rejected, (state) => {
        state.loading = false;
      })
      //getAllUserOrders
      .addCase(getAllUserOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUserOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orderList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllUserOrders.rejected, (state) => {
        state.loading = false;
      })
      //getAllActiveUserOrders
      .addCase(getAllActiveUserOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllActiveUserOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orderList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllActiveUserOrders.rejected, (state) => {
        state.loading = false;
      })
      //getAllOrders
      .addCase(getAllOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.adminOrderList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllOrders.rejected, (state) => {
        state.loading = false;
      })
      //getPaymentRequestDetails
      .addCase(getOrderDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currentOrder = { ...action.payload.data };
      })
      .addCase(getOrderDetails.rejected, (state) => {
        state.loading = false;
      })
      //editPaymentRequest
      .addCase(editOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(editOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.currentOrder = { ...action.payload.result };
      })
      .addCase(editOrder.rejected, (state) => {
        state.loading = false;
      })
      //cancelOrder
      .addCase(cancelOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.currentOrder = { ...action.payload.result };
      })
      .addCase(cancelOrder.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentService } = orderSlice.actions;
export default orderSlice.reducer;
