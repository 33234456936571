import axios from "axios";
import { errorGlobal, successGlobal } from "../reducers/notification";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getMyWallet = createAsyncThunk("wallets/me", async () => {
  try {
    const request = await axios.get(
      `${process.env.REACT_APP_API_URL}/wallet/me`,
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );
    return { data: request.data.result[0] };
  } catch (error) {
    throw error;
  }
});

export const getCurrentReport = createAsyncThunk(
  "report/current",
  async (_, { rejectWithValue }) => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}/report/currentData`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return { data: request.data.result };
    } catch (error) {
      return rejectWithValue(error.response.data || "Order failed");
    }
  }
);

export const getUserReport = createAsyncThunk(
  "report/users",
  async (_, { rejectWithValue }) => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}/report/users`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return { data: request.data.result };
    } catch (error) {
      return rejectWithValue(error.response.data || "Order failed");
    }
  }
);

export const getOrderReport = createAsyncThunk(
  "report/orders",
  async (_, { rejectWithValue }) => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}/report/orders`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return { data: request.data.result };
    } catch (error) {
      return rejectWithValue(error.response.data || "Order failed");
    }
  }
);
