import React, { useEffect, useState, useRef } from "react";
import "./GetNumber.css";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getAllprovider } from "../../store/actions/provider";
import { saveOrder } from "../../store/actions/order";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../utills/tools";
import { FaChevronDown } from "react-icons/fa";
import { getAllCountries } from "../../store/actions/countery";
import { getMyWallet } from "../../store/actions/wallets";

const GetNumber = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [activeServiceId, setActiveServiceId] = useState(null);
  const [serviceData, setServiceData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    counteryCode: "22",
    icon: "uploads/icon/6702736cc446151a5b770635/1729316345819.png",
    name: "india",
  });
  const [isCountryIndia, setIsCountryIndia] = useState(false);
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
  const [allServiceData, setAllServiceData] = useState([]); // Store all services
  const [allCounteryData, setAllCounteryData] = useState([]); // Store all services
  const [servicesToShow, setServicesToShow] = useState(20); // Track number of services to show
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  //redux logic
  const providers = useSelector((state) => state.provider);
  const countries = useSelector((state) => state.countery);
  const orders = useSelector((state) => state.order);

  useEffect(() => {
    if (countries.counteryList.length < 1) {
      dispatch(getAllCountries());
    }
  }, [dispatch, countries.counteryList.length]);

  useEffect(() => {
    if (providers.providerList.length < 1) {
      dispatch(
        getAllprovider({
          status: "1",
          code: selectedCountry?.counteryCode || "22",
        })
      );
    }
  }, [dispatch, providers.providerList.length, selectedCountry]);

  useEffect(() => {
    if (!providers.loading) {
      setAllServiceData(providers.providerList); // Store all the data
      setServiceData(providers.providerList.slice(0, 40)); // Show only 20 initially
      setAllCounteryData(countries.counteryList);
    }
  }, [providers, countries]);

  const handleServiceClick = (item) => {
    const isActive = activeServiceId === item._id;
    setActiveServiceId(isActive ? null : item._id); // Toggle active service state by ID
  };

  const handleGenerateOrder = (item) => {
    if (!item) {
      toast.error("Please select a country");
    } else {
      if (!orders.loading) {
        dispatch(
          saveOrder({
            serviceId: item.serviceId._id,
            providerId: item._id,
          })
        )
          .then((result) => {
            // console.log("order test result ", result);
            if (!result?.payload?.success) {
              toast.error(result?.payload?.message, {
                position: "top-right", // Use string instead of POSITION.TOP_RIGHT
                autoClose: 600,
              });
            } else {
              toast.success("Order is successfull!", {
                position: "top-right", // Use string instead of POSITION.TOP_RIGHT
                autoClose: 600,
                onClose: () => {
                  navigate("/user/myNumber");
                },
              });
            }
          })
          .catch((err) => {
            // Handle unexpected errors (rare case)
            console.log("Order test err ", err);
          });
      }
    }
  };

  const handleSearchService = (event) => {
    setShowCloseButton(true);
    setSearchValue(event.target.value);
    const regex = new RegExp(event.target.value, "i");
    const filteredData = providers.providerList.filter((service) =>
      regex.test(service.serviceId.name)
    );
    setAllServiceData(filteredData); // Update all services to show filtered result
    setServiceData(filteredData.slice(0, 20)); // Show only 20 filtered results
  };
  const handleSearchServiceClose = () => {
    setShowCloseButton(false);
    setSearchValue("");
    setAllServiceData(providers.providerList); // Update all services to show filtered result
    setServiceData(providers.providerList.slice(0, 20)); // Show only 20 filtered results
  };

  const handleShowMoreServices = () => {
    const newServicesToShow = servicesToShow + 15;
    setServicesToShow(newServicesToShow);
    setServiceData(allServiceData.slice(0, newServicesToShow)); // Add 10 more services
  };
  // Handle country selection
  const handleselectCountry = (country) => {
    setSelectedCountry(country);
    if (country?.counteryCode != "22") {
      setIsCountryIndia(true);
    } else {
      setIsCountryIndia(false);
    }

    setIsCountryDropdownOpen(false); // Close dropdown
  };

  //handle countery dropdown search
  const handleCountrySearch = (event) => {
    const regex = new RegExp(event.target.value, "i");
    const filteredData = countries.counteryList.filter((countery) =>
      regex.test(countery.name)
    );
    setAllCounteryData(filteredData);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside both dropdowns
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsCountryDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Toggle country dropdown visibility
  const toggleCountryDropdown = () => {
    setIsCountryDropdownOpen((prev) => !prev);
  };

  // console.log("service: ", serviceData);
  // console.log("......", allCounteryData);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        className="custom-toast"
      />
      <div className="getNumber-main mt-14">
        {/* Service List */}
        <div className="fixed top-20 left-0 flex flex-row bg-slate-50 dark:bg-textB z-10  sm:flex-row items-center justify-between px-3 py-1 w-full gap-2 ">
          {/* Country Dropdown */}
          <div
            className="relative inline-block sm:w-[24%] lg:w-[24%] w-[50%]  z-10"
            ref={dropdownRef}
          >
            <div
              className=" border border-gray-300 rounded-md p-2 cursor-pointer flex justify-between items-center mt-0"
              onClick={toggleCountryDropdown}
            >
              {selectedCountry ? (
                <div
                  key={selectedCountry.counteryCode}
                  className="flex flex-row items-center justify-start text-left gap-3"
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}/${selectedCountry.icon}`}
                    alt={`${selectedCountry.name} flag`}
                    className="h-5 w-5 rounded-full object-cover"
                  />
                  <span className="font-medium text-sm text-gray-700 dark:text-textW">
                    {selectedCountry.name}
                  </span>
                </div>
              ) : (
                <div className="flex items-center justify-center text-center m-0">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}/${selectedCountry.icon}`}
                    alt={`${selectedCountry.name} flag`}
                    className="h-5 w-5 rounded-full object-cover"
                  />

                  <span className="font-normal text-sm">India</span>
                </div>
              )}
              <FaChevronDown className="text-gray-500 font-normal h-3 w-3 mt-1" />
            </div>

            {isCountryDropdownOpen && (
              <ul className=" w-full absolute mt-1 md:w-full lg:w-full border border-gray-300 rounded-md bg-white dark:bg-textB dark:text-textW max-h-60 overflow-y-auto shadow-lg z-10">
                {/* Fixed Input Field */}
                <li className="sticky top-0 bg-white dark:bg-textB z-10 px-1 py-1">
                  <input
                    type="search"
                    name="search"
                    autoComplete="off"
                    className="focus:ring-0 focus:border-blue-500 block w-full sm:text-sm border-gray-300 dark:text-textB rounded-md p-1 m-0"
                    placeholder="Search a country"
                    onChange={(e) => handleCountrySearch(e)}
                  />
                </li>
                <hr />

                {/* Country List */}
                {allCounteryData.length > 0 ? (
                  allCounteryData.map((country) => (
                    <li
                      key={country.counteryCode}
                      className="flex flex-row gap-4 justify-start items-center p-1 hover:bg-gray-100 cursor-pointer text-center"
                      onClick={() => handleselectCountry(country)}
                    >
                      {/* <span className="mr-2 font-normal text-sm">
                        {country.counteryCode}
                      </span> */}
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}/${country.icon}`}
                        alt=""
                        className="h-4 w-4"
                      />
                      <h4 className="font-normal text-sm">{country.name}</h4>
                    </li>
                  ))
                ) : (
                  <li className="py-2 px-3 text-gray-500">
                    No countries found
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="  relative sm:w-[24%] lg:w-[24%] w-[50%]  ">
            <i className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-textW">
              <IoSearchOutline />
            </i>
            <input
              type="text"
              placeholder="Search"
              className="w-full pl-8 py-2 border rounded-md dark:text-textW bg-transparent focus:ring-0 focus:ring-blue-500 dark:focus:ring-white focus:outline-none text-sm"
              value={searchValue}
              onChange={handleSearchService}
            />
            {showCloseButton && (
              <button
                type="submit"
                className="text-red-500 absolute right-0 bottom-1 top-1  focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-2 py-0 mr-2"
                onClick={handleSearchServiceClose}
              >
                ❌
              </button>
            )}
          </div>
        </div>
        {providers && providers.loading ? (
          <Loader />
        ) : (
          <div className="">
            {/* Content below the fixed header */}
            <div
              className="getNumber-services"
              data-aos="zoom-in-up"
              data-aos-duration="1000"
            >
              {/* Add margin-top to adjust for the fixed header height */}
              {isCountryIndia ? (
                <>
                  <h2>We are currently only working in India</h2>
                </>
              ) : serviceData.length > 0 ? (
                serviceData.map((item) => (
                  <div
                    className={`getNumber-service  w-full relative overflow-hidden transition-transform duration-300 ease-in-out transform dark:hover:text-textB ${
                      activeServiceId === item._id ? "active" : ""
                    }`}
                    key={item._id}
                    onClick={() => handleServiceClick(item)}
                  >
                    <div className="getNumber-service-i min-w-[45px] ">
                      <img
                        src={
                          item?.serviceId?.icon
                            ? `${process.env.REACT_APP_IMAGE_URL}/${item?.serviceId?.icon}`
                            : `${process.env.REACT_APP_IMAGE_URL}/${item?.icon}`
                        }
                        alt=""
                        className="h-full w-10 rounded-lg"
                      />
                    </div>
                    <div className="flex flex-col gap-0 p-2 min-w-[60%] dark:text-textW ">
                      <div className=" ">
                        <h5 className="short-name  ">
                          {item?.serviceId.name}
                        </h5>
                        {/* <h5 className="full-name hidden">
                          {item?.serviceId.name}
                        </h5> */}
                      </div>
                      <div className="flex flex-row gap-2">
                        {" "}
                        <div className="flex items-center gap-1">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}/${item?.icon}`}
                            alt=".."
                            className="h-4 w-4"
                          />
                          <h4 className="text-sm">{item?.name}</h4>
                        </div>
                        <div>
                          <p className="text-sm">
                            Stock:
                            <span className="getNumber-service-price">
                              {item?.available_unit}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <button
                      className="bg-buttonBg hover:bg-buttonBgH text-white rounded-md min-w-[60px] flex flex-col items-center justify-center text-start text-sm px-1 py-1 mr-1 transition-colors duration-300 ease-in-out"
                      onClick={() => handleGenerateOrder(item)}
                    >
                      Buy @<br />
                      ₹:{item?.sellPrice}
                    </button>
                  </div>
                ))
              ) : (
                <p>No services available</p>
              )}
            </div>

            {/* Show More Button */}
            <div className="mt-4 mb-4">
              {serviceData.length < allServiceData.length && (
                <button
                  onClick={handleShowMoreServices}
                  className="flex justify-center text-sm py-2 items-center text-white hover:bg-buttonBgH bg-buttonBg rounded md:w-1/4 mx-auto px-3"
                >
                  Show More Services
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GetNumber;
