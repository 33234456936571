import React, { useState } from "react";
import "./AdminHeaderTwo.css";
import { IoSearch } from "react-icons/io5";
import { MdFilterList } from "react-icons/md";
import { TiExportOutline } from "react-icons/ti";
import { IoMdAdd } from "react-icons/io";
import { IoClose, IoEyeOff, IoEye } from "react-icons/io5"; // Import eye icons for show/hide
import { useDispatch } from "react-redux";
import { registerUser } from "../../../store/actions/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminHeaderTwo = ({ onSearch }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [searchText, setSearchText] = useState("");

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const handleSaveUser = (e) => {
    e.preventDefault();
    const targetValue = e.target;
    dispatch(
      registerUser({
        name: targetValue.name.value,
        email: targetValue.email.value,
        password: targetValue.password.value,
        createdSource: "Admin",
      })
    )
      .then((result) => {
        if (!result?.payload?.success) {
          toast.error(result?.payload?.message, {
            position: "top-right", // Use string instead of POSITION.TOP_RIGHT
            autoClose: 600,
          });
        } else {
          // Show a success toast message
          toast.success("Add user successfully!", {
            position: "top-right",
            autoClose: 600,
            onClose: () => window.location.reload(),
          });
        }
      })
      .catch((err) => {
        // Handle unexpected errors (rare case)
        console.log("Registration test err ", err);
      });
  };
  const handleSearch = (e) => {
    e.preventDefault();
    onSearch({
      limit: "10",
      page: "1",
      keyword: e.target.defaultSearch.value,
    });
    setShowCloseButton(true);
  };

  const handleSearchClear = () => {
    onSearch({
      limit: "10",
      page: "1",
    });
    setShowCloseButton(false);
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const handleFilter = (e) => {
    e.preventDefault(); // Prevent default form submission (if inside a form)
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="admin-headerTwo bg-slate-50 w-full ">
        <div className="flex items-center w-full sm:w-auto md:w-2/5">
          <form className=" flex w-full " onSubmit={handleSearch}>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-3 h-3 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                name="defaultSearch"
                value={searchText}
                onChange={handleSearchText}
                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-0 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-0 dark:focus:border-blue-500"
                placeholder="Search..."
                required
              />

              <button
                type="submit"
                className="text-white absolute right-0 bottom-1 top-1 bg-blue-500 hover:bg-blue-700  focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-4 py-0 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Search
              </button>
              {showCloseButton && (
                <button
                  type="submit"
                  className="text-white absolute right-20 bottom-1 top-1 bg-red-500 hover:bg-red-700  focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-2 py-0 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={handleSearchClear}
                >
                  Clear
                </button>
              )}
            </div>
          </form>
        </div>

        <div className="admin-headerTwo-actions flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="sm:mb-0">
            <button
              onClick={toggleModalFilter}
              className="action-btn mr-2 mb-2 sm:mb-0"
              type="button"
            >
              <MdFilterList /> Filter
            </button>
            {/* Filter Modal */}
            {isModalFilter && (
              <div
                id="crud-modal"
                className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-50"
              >
                <div className="relative p-4 w-full max-w-md h-auto">
                  {/* Modal Content */}
                  <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    {/* Modal Header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                        Filter User
                      </h3>
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={toggleModalFilter}
                      >
                        <IoClose className="w-5 h-5" />
                      </button>
                    </div>

                    {/* Modal Body */}
                    <form onClick={handleFilter} className="p-4 md:p-5">
                      <div className="grid gap-4 mb-4 grid-cols-1 sm:grid-cols-2">
                        <div>
                          <label
                            htmlFor="dateTo"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Date to
                          </label>
                          <input
                            type="date"
                            id="dateTo"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            required
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="dateFrom"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Date From
                          </label>
                          <input
                            type="date"
                            id="dateFrom"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            required
                          />
                        </div>

                        <div className="flex items-center">
                          <input
                            id="active"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="active"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Active
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="inactive"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="inactive"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="w-full mt-2 justify-center	 text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
          <button className="action-btn mr-2 mb-2 sm:mb-0 sm:mr-0">
            <TiExportOutline /> Export
          </button>
          <div className="flex items-center flex-row ">
            <button
              onClick={toggleModal}
              className=" action-btn add-new block text-white bg-blue-500 hover:bg-blue-700   focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  text-center"
              type="button"
            >
              <IoMdAdd /> Add New
            </button>
            {/* Modal */}
            {isModalOpen && (
              <div
                id="crud-modal"
                className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-50"
              >
                <div className="relative p-4 w-full max-w-md h-auto">
                  {/* Modal Content */}
                  <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    {/* Modal Header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                        Add New User
                      </h3>
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={toggleModal}
                      >
                        <IoClose className="w-5 h-5" />
                      </button>
                    </div>

                    {/* Modal Body */}
                    <form className="p-4 md:p-5" onSubmit={handleSaveUser}>
                      <div className="grid gap-4 mb-4 grid-cols-1">
                        <div>
                          <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Enter your name"
                            required
                          />
                        </div>

                        <div>
                          <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Enter your email"
                            required
                          />
                        </div>

                        <div>
                          <label
                            htmlFor="password"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Password
                          </label>
                          <div className="relative">
                            <input
                              type={showPassword ? "text" : "password"}
                              id="password"
                              name="password"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Enter your password"
                              required
                            />
                            <button
                              type="button"
                              onClick={togglePasswordVisibility}
                              className="absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                              {showPassword ? (
                                <IoEye className="w-5 h-5 text-gray-500" />
                              ) : (
                                <IoEyeOff className="w-5 h-5 text-gray-500" />
                              )}
                            </button>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="role"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Role
                          </label>
                          <select
                            id="role"
                            name="role"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            required
                          >
                            <option value="" disabled selected>
                              Select role
                            </option>
                            {/* <option value="admin">Admin</option> */}
                            <option value="user">User</option>
                          </select>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="w-full justify-center text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminHeaderTwo;
