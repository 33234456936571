import React from "react";
import "./Country.css";
import CountryHeader from "./CountryHeader";
import CountryTab from "./CountryTab";

const Country = () => {
  return (
    <>
      <CountryTab />
    </>
  );
};

export default Country;
