import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editProvider,
  getAllprovider,
  updatePriceAndUnits,
} from "../../../store/actions/provider";
import { IoClose } from "react-icons/io5";
import { Loader } from "../../../utills/tools";
import ProviderHeader from "./ProviderHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TfiReload } from "react-icons/tfi";

const ProviderTab = () => {
  const dispatch = useDispatch();
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("all");
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState(null);
  const [filterQuery, setFilterQuery] = useState({
    limit: "10",
    page: "1",
  });
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [isModalScran, setIsModalScran] = useState(false);

  const headers = [
    { keys: "S.No" },
    { keys: "Service" },
    { keys: "Name" },
    { keys: "Code" },
    { keys: "Priority" },
    { keys: "Available Units" },
    { keys: "Sell Price" },
    { keys: "Price" },
    { keys: "Info" },
    { keys: "Status" },
    { keys: "Action" },
  ];

  //  Reduc logic.....
  const provider = useSelector((state) => state.provider);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(provider.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const handleGetAllProvider = () => {
    dispatch(getAllprovider(filterQuery));
  };

  useEffect(() => {
    handleGetAllProvider();
  }, [filterQuery, setFilterQuery]);

  console.log(filterQuery);

  useEffect(() => {
    setProducts(provider.providerList);
  }, [provider]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  // Function to handle tab switching
  const handleTabClick = (tab) => {
    let query = { ...filterQuery };
    setActiveTab(tab); // Set the clicked tab as the active tab
    if (tab == "active") {
      query.status = "1";
    } else if (tab == "inactive") {
      query.status = "0";
    } else {
      delete query.status;
    }
    setFilterQuery(query);
  };

  const toggleModalEdit = (item) => {
    setIsModalEdit(true);
    setCurrentItems(item);
  };
  const toggleModalEditClose = () => {
    setIsModalEdit(false);
    setCurrentItems();
  };

  const handlecancelProvider = (item) => {
    dispatch(
      editProvider({
        id: item?._id,
        data: { status: "0" },
      })
    );
    toast.success("Block successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => handleGetAllProvider(),
    });
  };

  const handleActivateProvider = (item) => {
    dispatch(
      editProvider({
        id: item?._id,
        data: { status: "1" },
      })
    );
    toast.success("Unblock successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => handleGetAllProvider(),
    });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(
      editProvider({
        id: currentItems._id,
        data: {
          name: e.target.name.value,
          code: e.target.code.value,
          sellPrice: e.target.sellPrice.value,
          price: e.target.price.value,
          available_unit: e.target.availableUnit.value,
          info: e.target.info.value,
          priority: parseInt(e.target.priority.value) ||null,
        },
      })
    );
    setIsModalEdit(false);
    setCurrentItems(null);
    toast.success("Product edit successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => handleGetAllProvider(),
    });
  };
  const updateOnePriceAndUnit = (item) => {
    dispatch(updatePriceAndUnits({ providerId: item }));
    toast.success("Provider Updated successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => handleGetAllProvider(),
    });
  };
  const updateAllPriceAndUnit = () => {
    dispatch(updatePriceAndUnits({}));

    toast.success("All Providers will be updated in a while!", {
      position: "top-right",
      autoClose: 600,
    });
    setIsModalScran(false);
  };

  return (
    <div className="flex flex-col relative ml-2">
      <div className="fixed w-full max-w-[calc(100%-17rem)] z-10 ">
        <ProviderHeader crntSearch={filterQuery} onSearch={setFilterQuery} />
      </div>
      <div className="mt-14">
        <div className="relative flex flex-col">
          <div className="flex flex-row justify-between gap-14 w-full max-w-[calc(100%-17rem)] items-center text-center tab-container fixed z-9 bg-white">
            <div>
              <h2 className="text-lg font-medium mx-2">Provider Pricing</h2>
            </div>
            <div className="flex justify-center items-center my-3">
              <button
                className="flex items-center text-sm font-medium gap-2 text-blue-500 px-4 py-1 rounded-md border hover:text-black hover:bg-gray-200 transition-colors duration-300"
                onClick={() => setIsModalScran(true)} // Open the modal
              >
                External Api Sync <TfiReload className="mt-0" />
              </button>

              {/* Modal */}
              {isModalScran && (
                <div
                  data-aos="zoom-in-up"
                  data-aos-duration="500"
                  className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
                  id="popup-modal"
                >
                  <div className="relative p-4 w-full max-w-md max-h-full bg-white rounded-lg shadow-lg">
                    <button
                      type="button"
                      className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                      onClick={() => setIsModalScran(false)} // Close modal
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                    <div className="p-4 text-center">
                      <h3 className="mb-5 text-lg font-normal text-gray-500">
                        Are you sure you want to External Api Sync?
                      </h3>
                      <button
                        className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2"
                        onClick={updateAllPriceAndUnit} // Yes action
                      >
                        Yes, I'm sure
                      </button>
                      <button
                        className="text-gray-900 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-300 text-sm font-medium px-5 py-2.5"
                        onClick={() => setIsModalScran(false)} // No action
                      >
                        No, cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              <ul
                className="flex flex-wrap -mb-px text-sm font-medium text-center"
                role="tablist"
              >
                <li className="me-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "all" ? "tab-button-active" : ""
                    }`}
                    id="all"
                    type="button"
                    onClick={() => handleTabClick("all")}
                    aria-selected={activeTab === "all"}
                  >
                    All
                  </button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "active" ? "tab-button-active" : ""
                    }`}
                    id="active"
                    type="button"
                    onClick={() => handleTabClick("active")}
                    aria-selected={activeTab === "active"}
                  >
                    Active
                  </button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "inactive" ? "tab-button-active" : ""
                    }`}
                    id="inactive"
                    type="button"
                    onClick={() => handleTabClick("inactive")}
                    aria-selected={activeTab === "inactive"}
                  >
                    Inactive
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div id="tab-content">
            <div
            // className={`tab-content ${
            //   activeTab === "all" ? "tab-content-active" : ""
            // }`}
            // id="all-content"
            >
              {/* Table Start Code.......  */}
              {/* <p>This is the content for the All tab.</p> */}

              <div className=" shadow-md sm:rounded-lg mt-14">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8 ">
                  <thead className="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      {headers.map((header, index) => (
                        <th scope="col" className="px-6 py-3">
                          {header.keys}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="overflow-y-auto">
                    {provider && provider.loading ? (
                      <Loader />
                    ) : (
                      products.map((product, index) => (
                        <tr
                          key={product._id}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                          <th className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                            {indexOfFirstRow + index + 1}
                          </th>
                          <th className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                            {product?.serviceId?.name}
                          </th>
                          <td className="px-6 py-4">{product?.name}</td>
                          <td className="px-6 py-4">{product?.code}</td>
                          <td className="px-6 py-4">{product?.priority}</td>
                          <td className="px-6 py-4">
                            {product?.available_unit}
                          </td>
                          <td className="px-6 py-4">{product?.sellPrice}</td>
                          <td className="px-6 py-4">{product?.price}</td>
                          <td className="px-6 py-4">{product?.info}</td>
                          <td className="px-6 py-4">
                            {product?.status == 1 ? "Active" : "Inactive"}
                          </td>
                          <td className="px-6 py-4 space-x-4 flex mt-2">
                            <button
                              type="button"
                              className="font-extrabold	  text-white text-sm px-2 rounded  bg-indigo-500 hover:bg-indigo-600"
                              onClick={() => updateOnePriceAndUnit(product._id)}
                            >
                              <TfiReload />
                            </button>
                            <button
                              type="button"
                              onClick={() => toggleModalEdit(product)}
                              className="font-normal  text-white text-sm px-2 rounded  bg-blue-600 hover:bg-blue-800"
                            >
                              Edit
                            </button>
                            {isModalEdit && (
                              <div
                                id="crud-modal"
                                className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10"
                              >
                                <div className="relative p-4 w-full max-w-lg h-[500px]">
                                  {/* Modal Content */}
                                  <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    {/* Modal Header */}
                                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                        Edit Product Details
                                      </h3>
                                      <button
                                        type="button"
                                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                        onClick={toggleModalEditClose} // Correct function for toggling modal
                                      >
                                        <IoClose className="w-5 h-5" />
                                      </button>
                                    </div>

                                    {/* Modal Body */}
                                    <div className="h-[400px] overflow-y-auto scrollbar-hidden">
                                      {" "}
                                      {/* Add scrollbar-hidden class */}
                                      <form
                                        className="p-4 md:p-5"
                                        onSubmit={handleEditSubmit}
                                      >
                                        <div className="grid gap-4 grid-cols-2 mb-4">
                                          {/* Service */}
                                          <div className="col-span-2 md:col-span-1">
                                            <label
                                              htmlFor="service"
                                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                              Service
                                            </label>
                                            <input
                                              type="text"
                                              id="service"
                                              name="service"
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                              placeholder="Enter Service"
                                              defaultValue={
                                                currentItems?.serviceId?.name
                                              }
                                              readOnly
                                              required
                                            />
                                          </div>

                                          {/* Name */}
                                          <div className="col-span-2 md:col-span-1">
                                            <label
                                              htmlFor="name"
                                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                              Name
                                            </label>
                                            <input
                                              type="text"
                                              id="name"
                                              name="name"
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                              placeholder="Enter Name"
                                              defaultValue={currentItems?.name}
                                              required
                                            />
                                          </div>

                                          {/* Code */}
                                          <div className="col-span-2 md:col-span-1">
                                            <label
                                              htmlFor="code"
                                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                              Code
                                            </label>
                                            <input
                                              type="text"
                                              id="code"
                                              name="code"
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                              placeholder="Enter Code"
                                              defaultValue={currentItems?.code}
                                              required
                                            />
                                          </div>

                                          {/* Available Units */}
                                          <div className="col-span-2 md:col-span-1">
                                            <label
                                              htmlFor="available-units"
                                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                              Available Units
                                            </label>
                                            <input
                                              type="number"
                                              id="available-units"
                                              name="availableUnit"
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                              placeholder="Enter Available Units"
                                              step="any"
                                              defaultValue={
                                                currentItems?.available_unit
                                              }
                                              required
                                            />
                                          </div>

                                          {/* Buy Price */}
                                          <div className="col-span-2 md:col-span-1">
                                            <label
                                              htmlFor="buy-price"
                                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                              Sell Price
                                            </label>
                                            <input
                                              type="number"
                                              id="sellPrice"
                                              name="sellPrice"
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                              placeholder="Enter Buy Price"
                                              step="any"
                                              defaultValue={
                                                currentItems?.sellPrice
                                              }
                                              required
                                            />
                                          </div>

                                          {/* Price */}
                                          <div className="col-span-2 md:col-span-1">
                                            <label
                                              htmlFor="price"
                                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                              Price
                                            </label>
                                            <input
                                              type="number"
                                              id="price"
                                              name="price"
                                              step="any"
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                              placeholder="Enter Price"
                                              defaultValue={currentItems?.price}
                                              required
                                            />
                                          </div>
                                          {/* Priority */}
                                          <div className="col-span-2 md:col-span-1">
                                            <label
                                              htmlFor="price"
                                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                              Priority
                                            </label>
                                            <input
                                              type="test"
                                              id="priority"
                                              name="priority"
                                              step="any"
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                              placeholder="Enter priority in number"
                                              defaultValue={currentItems?.priority}
                                              required
                                            />
                                          </div>

                                          {/* Info */}
                                          <div className="col-span-2 md:col-span-2">
                                            <label
                                              htmlFor="info"
                                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                              Info
                                            </label>
                                            <textarea
                                              id="info"
                                              name="info"
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                              placeholder="Enter Additional Info"
                                              defaultValue={currentItems?.info}
                                              rows="3"
                                            ></textarea>
                                          </div>
                                        </div>

                                        <button
                                          type="submit"
                                          className="text-white inline-flex items-center w-full justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                          Edit
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {product?.status == 1 ? (
                              <button
                                className="font-normal text-white text-sm px-4 rounded bg-red-600 hover:bg-red-800"
                                onClick={() => handlecancelProvider(product)}
                              >
                                Block
                              </button>
                            ) : (
                              <button
                                className="font-normal text-white text-sm px-2 rounded bg-green-600 hover:green-red-800"
                                onClick={() => handleActivateProvider(product)}
                              >
                                Unblock
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <nav
                  className="flex items-center justify-between p-2"
                  aria-label="Table navigation"
                >
                  <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    Showing{" "}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {indexOfFirstRow + 1}-
                      {Math.min(indexOfLastRow, provider.totalCount)}
                    </span>{" "}
                    of{" "}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {provider.totalCount}
                    </span>
                  </span>

                  <ul className="inline-flex items-center -space-x-px text-sm h-8">
                    {/* Previous Button */}
                    <li>
                      <button
                        onClick={handlePrev}
                        disabled={currentPage === 1}
                        className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Previous
                      </button>
                    </li>

                    {/* First Page Button */}
                    {currentPage > 2 && (
                      <li>
                        <button
                          onClick={() => goToPage(1)}
                          className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          1
                        </button>
                      </li>
                    )}

                    {/* Ellipsis if necessary */}
                    {currentPage > 3 && <li className="px-2">...</li>}

                    {/* Previous Page */}
                    {currentPage > 1 && (
                      <li>
                        <button
                          onClick={() => goToPage(currentPage - 1)}
                          className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          {currentPage - 1}
                        </button>
                      </li>
                    )}

                    {/* Current Page */}
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-blue-600 bg-blue-50 border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(currentPage)}
                      >
                        {currentPage}
                      </button>
                    </li>

                    {/* Next Page */}
                    {currentPage < totalPages && (
                      <li>
                        <button
                          onClick={() => goToPage(currentPage + 1)}
                          className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          {currentPage + 1}
                        </button>
                      </li>
                    )}

                    {/* Ellipsis if necessary */}
                    {currentPage < totalPages - 2 && (
                      <li className="px-2">...</li>
                    )}

                    {/* Last Page Button */}
                    {currentPage < totalPages - 1 && (
                      <li>
                        <button
                          onClick={() => goToPage(totalPages)}
                          className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          {totalPages}
                        </button>
                      </li>
                    )}

                    {/* Next Button */}
                    <li>
                      <button
                        onClick={handleNext}
                        disabled={currentPage === totalPages}
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>

              {/* Table End Code .....  */}
            </div>
            {/* <div
          className={`tab-content ${
            activeTab === "active" ? "tab-content-active" : ""
          }`}
          id="active-content"
        >
          <p>This is the content for the Active tab.</p>
        </div>
        <div
          className={`tab-content ${
            activeTab === "inactive" ? "tab-content-active" : ""
          }`}
          id="inactive-content"
        >
          <p>This is the content for the Inactive tab.</p>
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderTab;
