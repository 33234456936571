import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ServiceTab.css";
import {
  editServicesByAdmin,
  getAllServices,
} from "../../../store/actions/services";
import { IoClose } from "react-icons/io5";
import { Loader } from "../../../utills/tools";
import ServiceHeader from "./ServiceHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ServiceTab = () => {
  const dispatch = useDispatch();
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("all");
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterQuery, setFilterQuery] = useState({
    limit: "10",
    page: "1",
  });
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [currentService, setCurrentService] = useState({});

  // Redux logic...
  const service = useSelector((state) => state.service);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(service.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  // Headers
  const headers = [
    { keys: "S.No" },
    { keys: "Icon" },
    { keys: "Name" },
    { keys: "Code" },
    { keys: "Available Units" },
    { keys: "Info" },
    { keys: "Status" },
    { keys: "Action" },
  ];

  useEffect(() => {
    setProducts(service.serviceList);
  }, [service]);

  const getServices = () => {
    dispatch(getAllServices(filterQuery));
  };

  useEffect(() => {
    getServices();
  }, [filterQuery, setFilterQuery]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  // Function to handle tab switching
  const handleTabClick = (tab) => {
    let query = { ...filterQuery };
    setActiveTab(tab); // Set the clicked tab as the active tab
    if (tab == "active") {
      query.status = "1";
    } else if (tab == "inactive") {
      query.status = "0";
    } else {
      delete query.status;
    }
    setFilterQuery(query);
  };
  const toggleModalEdit = (item) => {
    setCurrentService(item);
    setIsModalEdit(true);
  };
  const toggleModalEditClose = () => {
    setIsModalEdit(false);
  };

  const handleEditService = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("SaveIcon", e.target.SaveIcon.files[0]);
    formData.append("name", e.target.name.value);
    formData.append("code", e.target.code.value);
    formData.append("available_unit", e.target.available_unit.value);
    formData.append("price", e.target.price.value);
    formData.append("info", e.target.info.value);
    dispatch(editServicesByAdmin({ id: currentService._id, data: formData }));
    setIsModalEdit(false);
    toast.success("Service edit successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => getServices(),
    });
  };

  const handleblockServices = (id) => {
    dispatch(editServicesByAdmin({ id: id, data: { status: 0 } }));
    toast.success("Service Block successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => getServices(),
    });
  };
  const handleUnblockServices = (id) => {
    dispatch(editServicesByAdmin({ id: id, data: { status: 1 } }));
    toast.success("Unblock successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => getServices(),
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex flex-col relative ml-2">
        <div className="fixed w-full max-w-[calc(100%-17rem)] z-10 ">
          <ServiceHeader onSearch={setFilterQuery} />
        </div>
        <div className="mt-14">
          <div className="relative flex flex-col">
            <div className="flex flex-row justify-between gap-14 w-full max-w-[calc(100%-17rem)] items-center text-center tab-container fixed z-9 bg-white">
              <h2 className="text-lg font-medium mx-2">Service List</h2>
              <ul
                className="flex flex-wrap -mb-px text-sm font-medium text-center"
                role="tablist"
              >
                <li className="me-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "all" ? "tab-button-active" : ""
                    }`}
                    id="all"
                    type="button"
                    onClick={() => handleTabClick("all")}
                    aria-selected={activeTab === "all"}
                  >
                    All
                  </button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "active" ? "tab-button-active" : ""
                    }`}
                    id="active"
                    type="button"
                    onClick={() => handleTabClick("active")}
                    aria-selected={activeTab === "active"}
                  >
                    Active
                  </button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "inactive" ? "tab-button-active" : ""
                    }`}
                    id="inactive"
                    type="button"
                    onClick={() => handleTabClick("inactive")}
                    aria-selected={activeTab === "inactive"}
                  >
                    Inactive
                  </button>
                </li>
              </ul>
            </div>
            <div id="tab-content">
              <div
              // className={`tab-content ${
              //   activeTab === "all" ? "tab-content-active" : ""
              // }`}
              // id="all-content"
              >
                {/* Table Start Code.......  */}
                {/* <p>This is the content for the All tab.</p> */}

                <div className=" shadow-md sm:rounded-lg mt-14">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8 ">
                    <thead className="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        {headers.map((header, index) => (
                          <th scope="col" key={index} className="px-6 py-3">
                            {header.keys}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                      {service && service.loading ? (
                        <Loader />
                      ) : (
                        products.map((product, index) => (
                          <tr
                            key={product?._id}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                          >
                            <th className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                              {indexOfFirstRow + index + 1}
                            </th>
                            <td className="px-6 py-4">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_URL}/${product?.icon}`}
                                alt=""
                                className="h-6 w-6"
                              />
                            </td>
                            <td className="px-6 py-4">{product?.name}</td>
                            <td className="px-6 py-4">{product?.code}</td>
                            <td className="px-6 py-4">
                              {product?.available_unit}
                            </td>
                            <td className="px-6 py-4">{product?.info}</td>
                            <td className="px-6 py-4">
                              {product?.status == 1 ? "Active" : "Inactive"}
                            </td>
                            <td className="px-6 py-4 space-x-8 flex">
                              <button
                                type="button"
                                onClick={() => toggleModalEdit(product)}
                                className="font-normal  text-white text-sm px-2 rounded  bg-blue-600 hover:bg-blue-800"
                              >
                                Edit
                              </button>
                              {isModalEdit && (
                                <div
                                  id="crud-modal"
                                  className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10"
                                >
                                  <div className="relative p-4 w-full max-w-lg h-[500px]">
                                    {/* Modal Content */}
                                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                      {/* Modal Header */}
                                      <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                          Edit Product Details
                                        </h3>
                                        <button
                                          type="button"
                                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                          onClick={toggleModalEditClose} // Correct function for toggling modal
                                        >
                                          <IoClose className="w-5 h-5" />
                                        </button>
                                      </div>

                                      {/* Modal Body */}
                                      <div className="h-[400px] overflow-y-auto scrollbar-hidden">
                                        {" "}
                                        {/* Add scrollbar-hidden class */}
                                        <form
                                          className="p-4 md:p-5"
                                          onSubmit={handleEditService}
                                        >
                                          <div className="grid gap-4 grid-cols-2 mb-4">
                                            {/* Icon (File input) */}
                                            <div className="col-span-2 md:col-span-1">
                                              <label
                                                htmlFor="icon"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                              >
                                                Icon
                                              </label>
                                              <input
                                                type="file"
                                                id="SaveIcon"
                                                name="SaveIcon"
                                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400"
                                              />
                                            </div>

                                            {/* Name */}
                                            <div className="col-span-2 md:col-span-1">
                                              <label
                                                htmlFor="name"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                              >
                                                Name
                                              </label>
                                              <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                placeholder="Enter Name"
                                                defaultValue={
                                                  currentService?.name
                                                }
                                                required
                                              />
                                            </div>

                                            {/* Code */}
                                            <div className="col-span-2 md:col-span-1">
                                              <label
                                                htmlFor="code"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                              >
                                                Code
                                              </label>
                                              <input
                                                type="text"
                                                id="code"
                                                name="code"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                placeholder="Enter Code"
                                                defaultValue={
                                                  currentService?.code
                                                }
                                                required
                                              />
                                            </div>

                                            {/* Available */}
                                            <div className="col-span-2 md:col-span-1">
                                              <label
                                                htmlFor="available_unit"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                              >
                                                Available
                                              </label>
                                              <input
                                                type="number"
                                                id="available_unit"
                                                name="available_unit"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                placeholder="Enter Available Quantity"
                                                step="any"
                                                defaultValue={
                                                  currentService?.available_unit
                                                }
                                                required
                                              />
                                            </div>
                                            {/* Price */}
                                            <div className="col-span-2 md:col-span-1">
                                              <label
                                                htmlFor="price"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                              >
                                                Price
                                              </label>
                                              <input
                                                type="number"
                                                id="price"
                                                name="price"
                                                step="any"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                placeholder="Enter price"
                                                defaultValue={
                                                  currentService?.price
                                                }
                                                required
                                              />
                                            </div>

                                            {/* Info */}
                                            <div className="col-span-2 md:col-span-2">
                                              <label
                                                htmlFor="info"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                              >
                                                Info
                                              </label>
                                              <textarea
                                                id="info"
                                                name="info"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                placeholder="Enter Additional Info"
                                                defaultValue={
                                                  currentService?.info
                                                }
                                                rows="3"
                                              ></textarea>
                                            </div>
                                          </div>

                                          <button
                                            type="submit"
                                            className="text-white inline-flex items-center w-full justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                          >
                                            Edit
                                          </button>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {product?.status == 1 ? (
                                <button
                                  className="font-normal text-white text-sm px-2 rounded bg-red-600 hover:bg-red-800"
                                  onClick={() =>
                                    handleblockServices(product?._id)
                                  }
                                >
                                  Block
                                </button>
                              ) : (
                                <button
                                  className="font-medium text-green-600 dark:text-green-500 hover:underline"
                                  onClick={() =>
                                    handleUnblockServices(product?._id)
                                  }
                                >
                                  Unblock
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  <nav
                    className="flex items-center justify-between p-2"
                    aria-label="Table navigation"
                  >
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                      Showing{" "}
                      <span className="font-semibold text-gray-900 dark:text-white">
                        {indexOfFirstRow + 1} -{" "}
                        {Math.min(indexOfLastRow, service.totalCount)}
                      </span>{" "}
                      of{" "}
                      <span className="font-semibold text-gray-900 dark:text-white">
                        {service.totalCount}
                      </span>
                    </span>

                    <ul className="inline-flex items-center -space-x-px text-sm h-8">
                      {/* Previous Button */}
                      <li>
                        <button
                          className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          onClick={handlePrev}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>

                      {/* Page Buttons */}
                      {currentPage > 2 && (
                        <li>
                          <button
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            onClick={() => goToPage(1)}
                          >
                            1
                          </button>
                        </li>
                      )}

                      {currentPage > 3 && <li className="px-2">...</li>}

                      {/* Previous Page */}
                      {currentPage > 1 && (
                        <li>
                          <button
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            onClick={() => goToPage(currentPage - 1)}
                          >
                            {currentPage - 1}
                          </button>
                        </li>
                      )}

                      {/* Current Page */}
                      <li>
                        <button
                          className="flex items-center justify-center px-3 h-8 leading-tight text-blue-600 bg-blue-50 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          onClick={() => goToPage(currentPage)}
                        >
                          {currentPage}
                        </button>
                      </li>

                      {/* Next Page */}
                      {currentPage < totalPages && (
                        <li>
                          <button
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            onClick={() => goToPage(currentPage + 1)}
                          >
                            {currentPage + 1}
                          </button>
                        </li>
                      )}

                      {currentPage < totalPages - 2 && (
                        <li className="px-2">...</li>
                      )}

                      {currentPage < totalPages - 1 && (
                        <li>
                          <button
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            onClick={() => goToPage(totalPages)}
                          >
                            {totalPages}
                          </button>
                        </li>
                      )}

                      {/* Next Button */}
                      <li>
                        <button
                          className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          onClick={handleNext}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>

                {/* Table End Code .....  */}
              </div>
              {/* <div
          className={`tab-content ${
            activeTab === "active" ? "tab-content-active" : ""
          }`}
          id="active-content"
        >
          <p>This is the content for the Active tab.</p>
        </div>
        <div
          className={`tab-content ${
            activeTab === "inactive" ? "tab-content-active" : ""
          }`}
          id="inactive-content"
        >
          <p>This is the content for the Inactive tab.</p>
        </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceTab;
