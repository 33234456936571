import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import { editAPI, getAllAPI } from "../../store/actions/apis";
import { Loader } from "../../utills/tools";
import ApiHeader from "./ApiHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ApiTab = () => {
  const dispatch = useDispatch();
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("all");
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterQuery, setFilterQuery] = useState({
    limit: "10",
    page: "1",
  });
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [currentApiData, setCurrentApiData] = useState(null);

  const apis = useSelector((state) => state.api);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(apis.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const handleGetData = () => {
    dispatch(getAllAPI(filterQuery));
  };

  useEffect(() => {
    handleGetData();
  }, [filterQuery, setFilterQuery]);

  useEffect(() => {
    setApiData(apis.apisList);
  }, [apis]);

  const headers = [
    { keys: "S.No" },
    { keys: "Name" },
    { keys: "Url" },
    { keys: "Description" },
    { keys: "Parameter" },
    { keys: "Body" },
    { keys: "Status" },
    { keys: "Action" },
  ];

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  // Function to handle tab switching
  const handleTabClick = (tab) => {
    let query = { ...filterQuery };
    setActiveTab(tab); // Set the clicked tab as the active tab
    if (tab === "active") {
      query.status = "1";
    } else if (tab === "inactive") {
      query.status = "0";
    } else {
      delete query.status;
    }
    setFilterQuery(query);
  };

  const toggleModalEdit = (item) => {
    setCurrentApiData(item);
    setIsModalEdit(true);
  };
  const toggleModalEditClose = () => {
    setCurrentApiData(null);
    setIsModalEdit(false);
  };
  const handelEditApi = (e) => {
    e.preventDefault();
    dispatch(
      editAPI({
        id: currentApiData?._id,
        data: {
          name: e.target.name.value,
          desc: e.target.desc.value,
          url: e.target.url.value,
          parameter: e.target.parameter.value,
          body: e.target.body.value,
          types: e.target.types.value,
        },
      })
    );
    toast.success("Edit Api successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => {
        handleGetData();
        setCurrentApiData(null);
        setIsModalEdit(false);
      },
    });
  };
  const handelBlock = (id) => {
    dispatch(editAPI({ id: id, data: { status: 0 } }));
    toast.success("Block successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => {
        handleGetData();
      },
    });
  };
  const handelUnblock = (id) => {
    dispatch(editAPI({ id: id, data: { status: 1 } }));
    toast.success("Block successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => {
        handleGetData();
      },
    });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="relative flex flex-col">
        <div className="fixed w-full max-w-[calc(100%-17rem)] z-10 ">
          <ApiHeader onSearch={setFilterQuery} />
        </div>
          <div className="flex flex-row justify-between gap-14 mt-12 w-full max-w-[calc(100%-17rem)] items-center text-center tab-container fixed z-9 bg-white">
          <h2 className="text-lg font-medium mx-2">Api Documents</h2>
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`tab-button ${
                  activeTab === "all" ? "tab-button-active" : ""
                }`}
                id="all"
                type="button"
                onClick={() => handleTabClick("all")}
                aria-selected={activeTab === "all"}
              >
                All
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`tab-button ${
                  activeTab === "active" ? "tab-button-active" : ""
                }`}
                id="active"
                type="button"
                onClick={() => handleTabClick("active")}
                aria-selected={activeTab === "active"}
              >
                Active
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`tab-button ${
                  activeTab === "inactive" ? "tab-button-active" : ""
                }`}
                id="inactive"
                type="button"
                onClick={() => handleTabClick("inactive")}
                aria-selected={activeTab === "inactive"}
              >
                Inactive
              </button>
            </li>
          </ul>
        </div>

        <div id="tab-content ">
          <div
            // className={`tab-content ${
            //   activeTab === "all" ? "tab-content-active" : ""
            // }`}
            // id="all-content"
          ></div>
          {/* Table Start Code.......  */}
          <div className=" shadow-md sm:rounded-lg mt-24">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8 ">
              <thead className=" text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-2">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label for="checkbox-all-search" className="sr-only">
                        CheckBox
                      </label>
                    </div>
                  </th>
                  {headers.map((header, index) => (
                    <th scope="col" className="px-2 py-3">
                      {header.keys}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="overflow-y-auto">
                {" "}
                {apis && apis.loading ? (
                  <Loader />
                ) : (
                  apiData.map((apiData, index) => (
                    <tr
                      key={apiData._id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="w-4 p-2">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </td>
                      <th className="px-2 py-2 font-medium text-gray-900 dark:text-white">
                        {indexOfFirstRow + index + 1}
                      </th>
                      <th className="px-2 py-2 font-medium text-gray-900 dark:text-white">
                        {apiData?.name}
                      </th>
                      <td className="px-2 py-2">{apiData?.url}</td>
                      <td className="px-2 py-2">{apiData?.desc}</td>
                      <td className="px-2 py-2">{apiData?.parameter}</td>
                      <td className="px-2 py-2">{apiData?.body}</td>
                      <td className="px-2 py-2">
                        {apiData?.status == "1" ? "Active" : "Inactive"}
                      </td>

                      <td className="px-2 py-2 space-x-4 flex  mt-2">
                        <button
                          type="button"
                          onClick={() => toggleModalEdit(apiData)}
                          className="font-normal  text-white text-sm px-2 rounded  bg-blue-600 hover:bg-blue-800"
                        >
                          Edit
                        </button>
                        {currentApiData && isModalEdit && (
                          <div
                            id="crud-modal"
                            className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10" // Same background as before
                          >
                            <div className="relative p-4 w-full max-w-md h-auto">
                              {/* Modal Content */}
                              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                {/* Modal Header */}
                                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Edit API Details
                                  </h3>
                                  <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleModalEditClose} // Correct function for toggling modal
                                  >
                                    <IoClose className="w-5 h-5" />
                                  </button>
                                </div>

                                {/* Modal Body */}
                                <div className="p-4 md:p-5 max-h-96 overflow-y-auto">
                                  <form onSubmit={handelEditApi}>
                                    <div className="grid gap-4 mb-4 grid-cols-2">
                                      {/* Name */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="name"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Name
                                        </label>
                                        <input
                                          type="text"
                                          id="name"
                                          name="name"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter Name"
                                          defaultValue={currentApiData?.name}
                                          required
                                        />
                                      </div>
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="types"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Types
                                        </label>
                                        <input
                                          type="text"
                                          id="types"
                                          name="types"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter types"
                                          defaultValue={currentApiData?.types}
                                          required
                                        />
                                      </div>

                                      {/* URL */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="url"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          URL
                                        </label>
                                        <input
                                          type="url"
                                          id="url"
                                          name="url"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter URL"
                                          defaultValue={currentApiData?.url}
                                          required
                                        />
                                      </div>

                                      {/* Description */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="desc"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Description
                                        </label>
                                        <textarea
                                          id="desc"
                                          name="desc"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter Description"
                                          defaultValue={currentApiData?.desc}
                                          rows="3"
                                        ></textarea>
                                      </div>

                                      {/* Parameter */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="parameter"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Parameter
                                        </label>
                                        <input
                                          type="text"
                                          id="parameter"
                                          name="parameter"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter Parameter"
                                          defaultValue={
                                            currentApiData?.parameter
                                          }
                                          required
                                        />
                                      </div>

                                      {/* Body */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="body"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Body
                                        </label>
                                        <textarea
                                          id="body"
                                          name="body"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter Body"
                                          defaultValue={currentApiData?.body}
                                          rows="3"
                                        ></textarea>
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="text-white w-full justify-center inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      Edit
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {apiData?.status == "1" ? (
                          <button
                            onClick={() => handelBlock(apiData._id)}
                            className="font-normal text-white text-sm px-2 rounded bg-red-600 hover:bg-red-800"
                          >
                            Block
                          </button>
                        ) : (
                          <button
                            onClick={() => handelUnblock(apiData._id)}
                            className="font-normal text-white text-sm px-2 rounded bg-green-600 hover:bg-green-800"
                          >
                            Unblock
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <nav
              className="flex items-center justify-between p-2"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {indexOfFirstRow + 1} -{" "}
                  {Math.min(indexOfLastRow, apis.totalCount)}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {apis.totalCount}
                </span>
              </span>

              <ul className="inline-flex items-center -space-x-px text-sm h-8">
                {/* Previous Button */}
                <li>
                  <button
                    className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={handlePrev}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {/* Page Buttons */}
                {currentPage > 2 && (
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(1)}
                    >
                      1
                    </button>
                  </li>
                )}

                {currentPage > 3 && <li className="px-2">...</li>}

                {/* Previous Page */}
                {currentPage > 1 && (
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(currentPage - 1)}
                    >
                      {currentPage - 1}
                    </button>
                  </li>
                )}

                {/* Current Page */}
                <li>
                  <button
                    className="flex items-center justify-center px-3 h-8 leading-tight text-blue-600 bg-blue-50 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={() => goToPage(currentPage)}
                  >
                    {currentPage}
                  </button>
                </li>

                {/* Next Page */}
                {currentPage < totalPages && (
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(currentPage + 1)}
                    >
                      {currentPage + 1}
                    </button>
                  </li>
                )}

                {currentPage < totalPages - 2 && <li className="px-2">...</li>}

                {currentPage < totalPages - 1 && (
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(totalPages)}
                    >
                      {totalPages}
                    </button>
                  </li>
                )}

                {/* Next Button */}
                <li>
                  <button
                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          {/* Table End Code .....  */}
        </div>
      </div>
    </>
  );
};

export default ApiTab;
