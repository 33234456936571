import React from "react";
import ReportTab from "./ReportTab";

const Report = () => {
  return (
    <div>
      <ReportTab />
    </div>
  );
};

export default Report;
