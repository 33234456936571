import React, { useEffect, useState } from "react";
import moment from "moment";
import "./MyNumbers.css";
import { useDispatch, useSelector } from "react-redux";
import { BiCopyAlt } from "react-icons/bi";
import { FaCopy } from "react-icons/fa6";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast, ToastContainer, Bounce } from "react-toastify";
import India from "../../assets/india.png";
import Runing from "../../assets/running-run.gif";
import smsSound from "../../assets/sms.mp3";

import {
  cancelOrder,
  getAllActiveUserOrders,
  saveOrder,
} from "../../store/actions/order";
import { useNavigate } from "react-router-dom";
import { getMyWallet } from "../../store/actions/wallets";

const MyNumber = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [widthData, setWidthData] = useState("0%");
  const [visible, setVisible] = useState(true);
  const [filterQuery, setFilterQuery] = useState({
    limit: "10",
    page: "1",
  });

  const headers = [
    {
      key: "Service",
    },
    { key: "Date & Time" },
    { key: "Number" },
    { key: "Sms" },
    { key: "Action" },
  ];

  //redux logic
  const orders = useSelector((state) => state.order);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(orders.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  
  const fetchData = () => {
    // Fetch data with the latest filterQuery
    dispatch(getAllActiveUserOrders(filterQuery)).then((orders) => {
      if (orders.payload.result.length) {
        dispatch(getMyWallet());
      }
    });
  };

  useEffect(() => {
    // Call fetchData immediately
    fetchData();

    // Set up the interval to call fetchData every 6 seconds
    const intervalId = setInterval(fetchData, 3000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [filterQuery]);

  useEffect(() => {
    // Start the progress bar animation after the component mounts
    const timer = setTimeout(() => {
      setWidthData("100%");
    }, 200); // Slight delay to trigger the transition
    const timer2 = setTimeout(() => {
      setVisible(false);
      setWidthData("0%");
    }, 5030);

    // Cleanup timeout on unmount
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);
  useEffect(() => {
    setProducts(orders.orderList);
  }, [orders]);

  const handlePlaySound = () => {
    const audio = new Audio(smsSound);
    audio.play();
  };
  useEffect(() => {
    const result = products.some((obj) => obj.seen === true);
    if (result) {
      handlePlaySound();
    }
  }, [products, setProducts]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  const handleCancelOrder = (orderId) => {
    // Handle the cancel order logic here
    dispatch(cancelOrder(orderId));
    toast.error("Order successfully cancelled!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
    });
  };

  const handleRepeatOrder = (order) => {
    if (!orders.loading) {
      dispatch(
        saveOrder({
          serviceId: order.serviceId._id,
          providerId: order.providerId._id,
        })
      )
        .then((result) => {
          // console.log("order test result ", result);
          if (!result?.payload?.success) {
            toast.error(result?.payload?.message, {
              position: "top-right", // Use string instead of POSITION.TOP_RIGHT
              autoClose: 600,
            });
          } else {
            toast.success("Repeat Order successfully placed!", {
              position: "top-right", // Use string instead of POSITION.TOP_RIGHT
              autoClose: 600,
            });
          }
        })
        .catch((err) => {
          // Handle unexpected errors (rare case)
          console.log("Order test err ", err);
        });
    }
  };

  const getTimeRemaining = (orderdate) => {
    const currentTime = moment();
    const orderTime = moment(orderdate);
    const timeDifference = currentTime.diff(orderTime, "seconds");
    let secRemaining = 1380 - parseInt(timeDifference);
    let minutes = Math.floor(secRemaining / 60); // Get total minutes
    let seconds = secRemaining % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )} Min`;
  };

  const copyNumberToClipboard = () => {
    toast.success("Copied 🤟!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
        className="custom-toast"
      />

      <div className="myNumber-all mt-0">
        {visible ? (
          <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 -mt-1 ">
            <div
              className="bg-blue-700 text-xs font-medium text-white text-center p-1 leading-none rounded-full"
              style={{ width: widthData, transition: "width 5s ease" }}
            >
              {widthData}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className=" shadow-md sm:rounded-lg ">
          {products && products.length == 0 ? (
            <>
              <div className="flex flex-col justify-center items-center md:my-[2rem] lg:my-[2rem]">
                <img
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1000"
                  src={Runing}
                  alt=""
                />
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="text-sm justify-center mt-6 mb-6 text-black dark:text-textW md:text-3xl lg:text-3xl align-center md:mb-[8rem] lg:mb-[8rem]"
                >
                  You have Yet to buy any numbers!
                </h1>
              </div>
            </>
          ) : (
            <div className="shadow-md sm:rounded-lg ">
              <table className="hidden md:table w-full text-sm text-left rtl:text-right text-gray-500  mb-8">
                <thead className="  text-sm text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-textW">
                  <tr className="">
                    {headers.map((header, index) => (
                      <th scope="col" key={index} className="px-6 py-3">
                        {header.key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="overflow-y-auto h-18 dark:text-textW">
                  {products &&
                    products.map((product) => (
                      <tr
                        key={product?._id}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="px-6 py-2 ">
                          {/* Service name and icon */}
                          <div className="flex items-center gap-2 text-sm ">
                            <img
                              // src={product?.serviceId.icon}
                              // src={India}
                              src={`${process.env.REACT_APP_IMAGE_URL}/${product?.serviceId.icon}`}
                              alt="Service Icon"
                              className="h-4 w-4 object-cover"
                            />
                            <p>{product?.serviceId.name}</p>
                          </div>

                          {/* Provider name and icon */}
                          <div className="flex items-center gap-2 mt-1 text-sm">
                            <img
                              // src={product?.providerId.icon}
                              // src={India}
                              src={`${process.env.REACT_APP_IMAGE_URL}/${product?.providerId.icon}`}
                              alt="Provider Icon"
                              className="h-4 w-4 object-cover"
                            />
                            <p>{product?.providerId.name}</p>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-black dark:text-textW">
                          {moment(product?.createdAt).format(
                            "DD/MM/YYYY HH:mm"
                          )}{" "}
                          | {getTimeRemaining(product?.createdAt)}
                        </td>
                        <td className="px-4 py-2 ">
                          {/* <div className="flex flex-col justify-center items-center text-center"> */}
                          <div className="flex flex-row gap-1 items-center ">
                            <p className="py-2 text-black text-base font-medium dark:text-textW">
                              {product?.mobileNo?.slice(-10)}
                            </p>
                            <CopyToClipboard
                              text={product?.mobileNo?.slice(-10)}
                            >
                              <button
                                className="text-base hover:text-black "
                                onClick={copyNumberToClipboard}
                              >
                                <abbr title="Copy number">
                                  <FaCopy className="text-black text-xl dark:text-textW" />
                                </abbr>
                              </button>
                            </CopyToClipboard>
                          </div>
                          {/* </div> */}
                        </td>
                        <td className="px-6 py-2">
                          <textarea
                            className="w-full p-1 text-sm bg-transparent border border-gray-300 resize-none focus:outline-none focus:ring-0 focus:ring-gray-500 focus:border-gray-500 rounded"
                            value={product?.sms.replace("FULL_SMS:", "")}
                            readOnly
                          />
                        </td>
                        <td className="px-6 py-2 space-x-8">
                          {product?.order_status == "PENDING" && (
                            <>
                              <button
                                className="font-medium text-white px-2 rounded bg-red-500 hover:bg-red-700 "
                                onClick={() => handleCancelOrder(product?._id)}
                              >
                                Cancel
                              </button>
                            </>
                          )}
                          {product?.order_status == "REJECTED" && (
                            <h2 className="bg-red-700 text-white px-2 py-4">
                              NO Number Found
                            </h2>
                          )}
                          {product?.order_status != "REJECTED" && (
                            <button
                              className="font-medium bg-green-600 hover:bg-green-800 text-white px-2 rounded"
                              onClick={() => handleRepeatOrder(product)}
                            >
                              Repeat Order
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {/* Mobile View */}
              {products &&
                products.map((product) => (
                  <div
                    key={product?._id}
                    className="block md:hidden border-b border-gray-200 bg-white dark:bg-gray-800 p-2 rounded-lg shadow-md mb-4" // Add background, padding, shadow, and margin
                  >
                    <div className="flex flex-col">
                      {/* data div .. */}
                      <div className="flex flex-row justify-between ">
                        {/* country service ..  */}
                        <div>
                          <div className="flex items-center gap-2 ">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}/${product?.serviceId.icon}`}
                              alt="service icon"
                              className="h-6 w-6 mt-1"
                            />
                            <p className="font-normal text-xl text-black dark:text-textW">
                              {product?.serviceId.name}
                            </p>
                          </div>

                          <div className="flex items-center gap-2 mt-1">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}/${product?.providerId.icon}`}
                              alt=""
                              className="h-6 w-6 mt-1"
                            />
                            <p className="font-normal text-xl text-black dark:text-textW ">
                              {product?.providerId.name}{" "}
                            </p>
                          </div>
                        </div>
                        {/* Number Time..  */}
                        <div className="">
                          <div className="flex flex-row  gap-4">
                            {/* Justify between for better spacing */}
                            <p className="text-black text-xl font-medium dark:text-textW">
                              {product?.mobileNo?.slice(-10)}{" "}
                            </p>
                            <div className="flex">
                              <CopyToClipboard
                                text={product?.mobileNo?.slice(-10)}
                              >
                                <button
                                  className="text-base hover:text-black dark:text-textW"
                                  onClick={copyNumberToClipboard}
                                >
                                  <abbr title="Copy number">
                                    <FaCopy className="text-black text-lg dark:text-textW" />
                                  </abbr>
                                </button>
                              </CopyToClipboard>
                            </div>
                          </div>
                          <div className="text-gray-600 dark:text-gray-400 mb-2 dark:text-textW">
                            {moment(new Date(product?.createdAt)).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                            | {getTimeRemaining(product?.createdAt)}
                          </div>
                        </div>
                      </div>

                      <textarea
                        className="w-full p-2 mt-1 text-sm dark:text-textW bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md resize-none focus:outline-none"
                        value={product?.sms.replace("FULL_SMS:", "")}
                        readOnly
                      />
                      <div className="flex justify-between space-x-2 mt-2">
                        <button
                          className="font-normal text-white py-1 bg-red-600 hover:bg-red-800 rounded flex-1 mr-1" // Adjust spacing for buttons
                          onClick={() => handleCancelOrder(product?._id)}
                        >
                          Cancel
                        </button>
                        <button
                          className="font-normal text-white py-1 bg-green-600 hover:bg-green-800 rounded flex-1"
                          onClick={() => handleRepeatOrder(product)}
                        >
                          Repeat Order
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          <nav
            className="flex flex-row gap-4 items-center justify-center md:justify-between lg:justify-between mt-2 bg-white py-2 px-2 mb-2"
            aria-label="Table navigation"
          >
            <div className="flex flex-row gap-2">
              <span className="font-medium text-black text-base">
                {indexOfFirstRow + 1} -{" "}
                {Math.min(indexOfLastRow, orders.totalCount)}
              </span>{" "}
              of{" "}
              <span className="font-medium text-black text-base">
                {orders.totalCount}
              </span>
            </div>

            <ul className="inline-flex items-center -space-x-px text-sm h-8">
              {/* Previous Button */}
              <li>
                <button
                  className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-black dark:text-textW bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-black dark:bg-gray-800 dark:border-gray-700"
                  onClick={handlePrev}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>

              {/* Page 1 Button */}
              <li>
                <button
                  className={`flex items-center justify-center px-3 h-8 leading-tight text-black bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 ${
                    currentPage === 1 ? "font-bold text-blue-600" : ""
                  }`}
                  onClick={() => goToPage(1)}
                >
                  1
                </button>
              </li>

              {/* Ellipsis if more than 3 pages */}
              {totalPages > 3 && <li className="px-2">...</li>}

              {/* Last Page Button */}
              {totalPages > 1 && (
                <li>
                  <button
                    className={`flex items-center justify-center px-3 h-8 leading-tight text-black bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 ${
                      currentPage === totalPages
                        ? "font-bold text-blue-600"
                        : ""
                    }`}
                    onClick={() => goToPage(totalPages)}
                  >
                    {totalPages}
                  </button>
                </li>
              )}

              {/* Next Button */}
              <li>
                <button
                  className="flex items-center justify-center px-3 h-8 leading-tight font-medium text-black bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-textW"
                  onClick={handleNext}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default MyNumber;
