import { createSlice } from "@reduxjs/toolkit";
import {
  getAllAPI,
  getAPIDetails,
  saveAPI,
  editAPI
} from "../actions/apis";

export const apiSlice = createSlice({
  name: "apis",
  initialState: {
    loading: false,
    apisList: [],
    currentApis: {},
    totalCount: 0,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      //getAllAPI
      .addCase(getAllAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.apisList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllAPI.rejected, (state) => {
        state.loading = false;
      })
      //saveAPI
      .addCase(saveAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.apisList = [...state.apisList, action.payload.result];
      })
      .addCase(saveAPI.rejected, (state) => {
        state.loading = false;
      })

      //editAPI
      .addCase(editAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(editAPI.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editAPI.rejected, (state) => {
        state.loading = false;
      })
      //getAPIDetails
      .addCase(getAPIDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAPIDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currentApis = action.payload.data.result;
      })
      .addCase(getAPIDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentCategory } = apiSlice.actions;
export default apiSlice.reducer;
