import React from 'react'
import UserLedgerTab from './UserLedgerTab'


const UserLedger = () => {
  return (
    <>
<UserLedgerTab/>
    </>
  )
}

export default UserLedger