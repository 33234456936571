import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../store/actions/auth";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic form validation
    if (name === "" || email === "" || password === "") {
      toast.error("Please fill in all fields!", {
        position: "top-right",
        autoClose: 1000,
      });
    } else {
      const targetValue = e.target;
      dispatch(
        registerUser({
          name: targetValue.name.value,
          email: targetValue.email.value,
          password: targetValue.password.value,
          createdSource: "ExtRegistration",
        })
      )
        .then((result) => {
          if (!result?.payload?.success) {
            toast.error(result?.payload?.message, {
              position: "top-right", // Use string instead of POSITION.TOP_RIGHT
              autoClose: 600,
              onClose: () => {
                navigate("/");
              },
            });
          } else {
            toast.success(
              "Registration successfully, Please Login to your new account!",
              {
                position: "top-right",
                autoClose: 600,
                onClose: () => navigate("/"),
              }
            );
          }
        })
        .catch((err) => {
          // Handle unexpected errors (rare case)
          console.log("Registration test err ", err);
        });
      // You can add your API call or logic to handle registration here
    }
  };

  return (
    <div className="login-page">
      <h1 className="login-title">Register</h1>
      <div className="login-container">
        <div className="login-text">
          <h5>Welcome!</h5>
          <h1>Create your account</h1>
        </div>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-input">
            <label htmlFor="name">User Name</label>
            <input
              type="text"
              id="name"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="login-input">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="login-input password-input">
            <label htmlFor="password">Password</label>
            <input
              type={passwordVisible ? "text" : "password"}
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className="password-toggle-icon"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <button type="submit" className="login-button">
            Register
          </button>
        </form>
        <div className="login-new-account">
          <h4>Already have an account?</h4>
          <Link to="/">
            <h3>Login</h3>
          </Link>
        </div>
      </div>

      {/* ToastContainer to display toast notifications */}
      <ToastContainer className="custom-toast" />
    </div>
  );
};

export default Register;
