import { createSlice } from "@reduxjs/toolkit";

import {
  resetUserPassword,
  adminGetAllUser,
  adminEditUser,
  adminGetUserDetails,
  getPaymentSettings,
  savePaymentSettings,
  getUserWalletAmount,
} from "../actions/users";

let DEFAULT_USER_STATE = {
  loading: false,
  userList: [],
  userSellerList: [],
  currentUser: {},
  totalCount: 0,
  sellerCount: 0,
};

export const userSlice = createSlice({
  name: "userManagement",
  initialState: DEFAULT_USER_STATE,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      ///Reset user Password
      .addCase(resetUserPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetUserPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetUserPassword.rejected, (state) => {
        state.loading = false;
      })

      ///Admin Edit a User
      .addCase(adminEditUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminEditUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(adminEditUser.rejected, (state) => {
        state.loading = false;
      })

      ///Admin get a User details
      .addCase(adminGetUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminGetUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = { ...action.payload };
      })
      .addCase(adminGetUserDetails.rejected, (state) => {
        state.loading = false;
      })

      ///Admin Get All User
      .addCase(adminGetAllUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminGetAllUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(adminGetAllUser.rejected, (state) => {
        state.loading = false;
      })
      ///save Payment Settings
      .addCase(savePaymentSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(savePaymentSettings.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(savePaymentSettings.rejected, (state) => {
        state.loading = false;
      })
      ///get Payment Settings
      .addCase(getPaymentSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentSettings.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getPaymentSettings.rejected, (state) => {
        state.loading = false;
      })
      ///get User Wallet Amount
      .addCase(getUserWalletAmount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserWalletAmount.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getUserWalletAmount.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
