import React from 'react'
import PaymentTab from './PaymentTab'

const PaymentRequest = () => {
  return (
    <>
        <PaymentTab /> 
    </>
  )
}

export default PaymentRequest