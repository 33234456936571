import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  editCountries,
  getAllCountries,
} from "../../../store/actions/countery";
import { IoClose } from "react-icons/io5";
import CountryHeader from "./CountryHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CountryTab = () => {
  const dispatch = useDispatch();
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [counteryList, setCountryList] = useState([]);
  const [currentCountery, setCurrentCountery] = useState(null);
  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    limit: 10,
  });
  const [isModalEdit, setIsModalEdit] = useState(false);

  const headers = [
    { keys: "Name" },
    { keys: "Icon" },
    { keys: "Country Code" },
    { keys: "Status" },
    { keys: "Action" },
  ];

  //redux logic
  const countries = useSelector((state) => state.countery);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(countries.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const handleGetAllCountery = () => {
    dispatch(getAllCountries(filterQuery));
  };

  useEffect(() => {
    handleGetAllCountery();
  }, [filterQuery, setFilterQuery]);

  useEffect(() => {
    setCountryList(countries.counteryList);
  }, [countries]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  // Function to handle tab switching
  const handleTabClick = (tab) => {
    let query = { ...filterQuery };
    setActiveTab(tab); // Set the clicked tab as the active tab
    if (tab == "active") {
      query.status = "1";
    } else if (tab == "inactive") {
      query.status = "0";
    } else {
      delete query.status;
    }
    setFilterQuery(query);
  };

  const toggleModalEdit = (item) => {
    setIsModalEdit(true);
    setCurrentCountery(item);
  };
  const toggleModalEditclose = () => {
    setIsModalEdit(false);
    setCurrentCountery(null);
  };

  const handleDeleteCountery = (item) => {
    dispatch(
      editCountries({
        id: item?._id,
        data: { status: "0" },
      })
    );
    toast.success("Countery Block successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => handleGetAllCountery(),
    });
  };
  const handleActivateCountery = (item) => {
    dispatch(
      editCountries({
        id: item?._id,
        data: { status: "1" },
      })
    );
    toast.success("Unblock successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => handleGetAllCountery(),
    });
  };
  const handleEditCountery = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("SaveIcon", e.target.SaveIcon.files[0]);
    formData.append("name", e.target.name.value);
    formData.append("counteryCode", e.target.counteryCode.value);
    dispatch(
      editCountries({
        id: currentCountery?._id,
        data: formData,
      })
    );
    toast.success("Edit Country successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => handleGetAllCountery(),
    });
    setIsModalEdit(false);
    setCurrentCountery(null);
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex flex-col relative ml-2">
        <div className="fixed w-full max-w-[calc(100%-17rem)] z-10 ">
          <CountryHeader onSearch={setFilterQuery} />
        </div>
        <div className="mt-14">
          <div className="relative flex flex-col">
            <div className="flex flex-row justify-between gap-14 w-full max-w-[calc(100%-17rem)] items-center text-center tab-container fixed z-9 bg-white">
              <h2 className="text-lg font-medium mx-2">Country List</h2>
              <ul
                className="flex flex-wrap -mb-px text-sm font-medium text-center"
                role="tablist"
              >
                <li className="me-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "all" ? "tab-button-active" : ""
                    }`}
                    id="all"
                    type="button"
                    onClick={() => handleTabClick("all")}
                    aria-selected={activeTab === "all"}
                  >
                    All
                  </button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "active" ? "tab-button-active" : ""
                    }`}
                    id="active"
                    type="button"
                    onClick={() => handleTabClick("active")}
                    aria-selected={activeTab === "active"}
                  >
                    Active
                  </button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "inactive" ? "tab-button-active" : ""
                    }`}
                    id="inactive"
                    type="button"
                    onClick={() => handleTabClick("inactive")}
                    aria-selected={activeTab === "inactive"}
                  >
                    Inactive
                  </button>
                </li>
              </ul>
            </div>

            <div id="tab-content">
              <div
              // className={`tab-content ${
              //   activeTab === "all" ? "tab-content-active" : ""
              // }`}
              // id="all-content"
              >
                {/* Table Start Code.......  */}
                {/* <p>This is the content for the All tab.</p> */}

                <div className=" shadow-md sm:rounded-lg mt-14">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8 ">
                    <thead className="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        {headers.map((header, index) => (
                          <th scope="col" className="px-6 py-3">
                            {header.keys}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                      {counteryList &&
                        counteryList.map((countries, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                          >
                            {/* <td className="w-4 p-4">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td> */}
                            <th className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                              {countries?.name}
                            </th>
                            <td className="px-6 py-4">
                              {" "}
                              <img
                                src={`${process.env.REACT_APP_IMAGE_URL}/${countries?.icon}`}
                                alt=""
                                className="h-6 w-6"
                              />
                            </td>
                            <td className="px-6 py-4">
                              {countries?.counteryCode}
                            </td>
                            <td className="px-6 py-4">
                              {countries?.status == 1 ? "Active" : "Inactive"}
                            </td>
                            <td className="px-6 py-4 space-x-8">
                              <button
                                type="button"
                                onClick={() => toggleModalEdit(countries)}
                                className="font-normal  text-white text-sm px-2 rounded  bg-blue-600 hover:bg-blue-800"
                              >
                                Edit
                              </button>
                              {currentCountery && isModalEdit && (
                                <div
                                  id="crud-modal"
                                  className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10"
                                >
                                  <div className="relative p-4 w-full max-w-lg h-[500px]">
                                    {/* Modal Content */}
                                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                      {/* Modal Header */}
                                      <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                          Edit Country Details
                                        </h3>
                                        <button
                                          type="button"
                                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                          onClick={toggleModalEditclose} // Correct function for toggling modal
                                        >
                                          <IoClose className="w-5 h-5" />
                                        </button>
                                      </div>

                                      {/* Modal Body */}
                                      <div className=" overflow-y-auto scrollbar-hidden">
                                        {" "}
                                        {/* Add scrollbar-hidden class */}
                                        <form
                                          className="p-4 md:p-5"
                                          onSubmit={handleEditCountery}
                                        >
                                          <div className="grid gap-4 grid-cols-2 mb-4">
                                            {/* Icon (File input) */}
                                            <div className="col-span-2 md:col-span-1">
                                              <label
                                                htmlFor="icon"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                              >
                                                Icon
                                              </label>
                                              <input
                                                type="file"
                                                id="SaveIcon"
                                                name="SaveIcon"
                                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400"
                                                required
                                              />
                                            </div>

                                            {/* Name */}
                                            <div className="col-span-2 md:col-span-1">
                                              <label
                                                htmlFor="name"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                              >
                                                Name
                                              </label>
                                              <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                placeholder="Enter Name"
                                                defaultValue={
                                                  currentCountery?.name
                                                }
                                                required
                                              />
                                            </div>

                                            {/* Country */}
                                            <div className="col-span-2 md:col-span-1">
                                              <label
                                                htmlFor="country"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                              >
                                                Countery Code
                                              </label>
                                              <input
                                                type="text"
                                                id="counteryCode"
                                                name="counteryCode"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                placeholder="Enter Country"
                                                defaultValue={
                                                  currentCountery?.counteryCode
                                                }
                                                required
                                              />
                                            </div>

                                            {/* Status */}
                                            <div className="col-span-2 md:col-span-1">
                                              <label
                                                htmlFor="status"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                              >
                                                Status
                                              </label>
                                              <select
                                                id="status"
                                                name="status"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                defaultValue={
                                                  currentCountery?.status
                                                }
                                                required
                                              >
                                                <option value="">
                                                  Select Status
                                                </option>
                                                <option value="1">
                                                  Active
                                                </option>
                                                <option value="0">
                                                  Inactive
                                                </option>
                                              </select>
                                            </div>
                                          </div>

                                          <button
                                            type="submit"
                                            className="text-white inline-flex items-center w-full justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                          >
                                            Edit Country
                                          </button>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {countries?.status == 1 ? (
                                <button
                                  className="font-normal text-white text-sm px-2 rounded bg-red-600 hover:bg-red-800"
                                  onClick={() =>
                                    handleDeleteCountery(countries)
                                  }
                                >
                                  Block
                                </button>
                              ) : (
                                <button
                                  className="font-normal text-white text-sm px-2 rounded  bg-green-600 hover:bg-green-500 "
                                  onClick={() =>
                                    handleActivateCountery(countries)
                                  }
                                >
                                  Unblock
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <nav
                    className="flex items-center justify-between p-2"
                    aria-label="Table navigation"
                  >
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                      Showing{" "}
                      <span className="font-semibold text-gray-900 dark:text-white">
                        {indexOfFirstRow + 1}-
                        {Math.min(indexOfLastRow, countries.totalCount)}
                      </span>{" "}
                      of{" "}
                      <span className="font-semibold text-gray-900 dark:text-white">
                        {countries.totalCount}
                      </span>
                    </span>

                    <ul className="inline-flex items-center -space-x-px text-sm h-8">
                      {/* Previous Button */}
                      <li>
                        <button
                          onClick={handlePrev}
                          disabled={currentPage === 1}
                          className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          Previous
                        </button>
                      </li>

                      {/* First Page Button */}
                      {currentPage > 2 && (
                        <li>
                          <button
                            onClick={() => goToPage(1)}
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          >
                            1
                          </button>
                        </li>
                      )}

                      {/* Ellipsis if necessary */}
                      {currentPage > 3 && <li className="px-2">...</li>}

                      {/* Previous Page */}
                      {currentPage > 1 && (
                        <li>
                          <button
                            onClick={() => goToPage(currentPage - 1)}
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          >
                            {currentPage - 1}
                          </button>
                        </li>
                      )}

                      {/* Current Page */}
                      <li>
                        <button
                          className="flex items-center justify-center px-3 h-8 leading-tight text-blue-600 bg-blue-50 border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          onClick={() => goToPage(currentPage)}
                        >
                          {currentPage}
                        </button>
                      </li>

                      {/* Next Page */}
                      {currentPage < totalPages && (
                        <li>
                          <button
                            onClick={() => goToPage(currentPage + 1)}
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          >
                            {currentPage + 1}
                          </button>
                        </li>
                      )}

                      {/* Ellipsis if necessary */}
                      {currentPage < totalPages - 2 && (
                        <li className="px-2">...</li>
                      )}

                      {/* Last Page Button */}
                      {currentPage < totalPages - 1 && (
                        <li>
                          <button
                            onClick={() => goToPage(totalPages)}
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          >
                            {totalPages}
                          </button>
                        </li>
                      )}

                      {/* Next Button */}
                      <li>
                        <button
                          onClick={handleNext}
                          disabled={currentPage === totalPages}
                          className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>

                {/* Table End Code .....  */}
              </div>
              {/* <div
          className={`tab-content ${
            activeTab === "active" ? "tab-content-active" : ""
          }`}
          id="active-content"
        >
          <p>This is the content for the Active tab.</p>
        </div>
        <div
          className={`tab-content ${
            activeTab === "inactive" ? "tab-content-active" : ""
          }`}
          id="inactive-content"
        >
          <p>This is the content for the Inactive tab.</p>
        </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountryTab;
