import React from 'react'

import TicketHeader from './TicketHeader'

import TicketTab from './TicketTab'

const TicketSupport = () => {
  return (
   <>

 <TicketHeader/>
    <TicketTab/>
  
   </>
  )
}

export default TicketSupport