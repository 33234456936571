import axios from "axios";
import { errorGlobal, successGlobal } from "../reducers/notification";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthHeader } from "../../utills/tools";

// User APi Parts
export const getMyPaymentRequest = createAsyncThunk(
  "user/paymentrequest/me",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment-request/me/all`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getMyPaymentRequestDetails = createAsyncThunk(
  "user/paymentrequest/me/info",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-request/request/${id}`,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

// Admin request
export const saveAdminPaymentRequest = createAsyncThunk(
  "admin/paymentrequest/save",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment-request/new`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getAllPaymentRequestByAdmin = createAsyncThunk(
  "admin/paymentrequest/all",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment-request/all`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const editPaymentRequestByAdmin = createAsyncThunk(
  "admin/paymentrequest/edit",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment-request/request/${data.id}`,
        data.data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const approvePaymentRequestByAdmin = createAsyncThunk(
  "admin/paymentrequest/approve",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment-request/admin/approve/${data.id}`,
        data.data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);
export const addMoneyToUser = createAsyncThunk(
  "admin/paymentrequest/addMoney",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/transection/addMoney/user`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);
export const withdrawMoneyFromUser = createAsyncThunk(
  "admin/paymentrequest/withdraw",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/transection/withdraw`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);
