import React, { useEffect, useState } from "react";
import "./Faq.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllFaq } from "../../store/actions/faq";
import { Loader } from "../../utills/tools";

const Faq = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqData, setFaqData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterQuery, setFilterQuery] = useState({
    limit: "10",
    page: "1",
  });

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Redux logic...
  const faqs = useSelector((state) => state.faqs);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(faqs.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  useEffect(() => {
    dispatch(getAllFaq(filterQuery));
  }, [filterQuery, setFilterQuery]);

  useEffect(() => {
    setFaqData(faqs.faqsList);
  }, [faqs]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  return (
    <>
      <div className=" mt-2 px-2 mb-auto">
        {faqs && faqs.loading ? (
          <Loader />
        ) : (
          <div className="faq-container-data ">
            {faqData.map((faq, index) => (
              <div key={faq._id} className="faq-item">
                <div className="faq-question " onClick={() => toggleFaq(index)}>
                  {faq.questions}
                  <span>{activeIndex === index ? "-" : "+"}</span>
                </div>
                <div
                  className={`faq-answer ${
                    activeIndex === index ? "open" : ""
                  }`}
                >
                  {faq.answers}
                </div>
              </div>
            ))}
            <nav
              className=" flex flex-col md:flex-row lg:flex-row gap-2 items-center md:justify-between lg:justify-between mt-2 bg-white py-2 "
              aria-label="Table navigation"
            >
              <div className=" flex flex-row gap-2">
                <h4 className="text-base font-medium text-black"> Showing</h4>
                <span className="font-medium text-black text-base">
                  {indexOfFirstRow + 1} -{" "}
                  {Math.min(indexOfLastRow, faqs.totalCount)}
                </span>{" "}
                of{" "}
                <span className="font-medium text-black text-base">
                  {faqs.totalCount}
                </span>
              </div>

              <ul className="inline-flex items-center -space-x-px text-sm h-8">
                {/* Previous Button */}
                <li>
                  <button
                    className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-black dark:text-textW bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-black dark:bg-gray-800 dark:border-gray-700 "
                    onClick={handlePrev}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {/* Page Buttons */}
                {currentPage > 2 && (
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(1)}
                    >
                      1
                    </button>
                  </li>
                )}

                {currentPage > 3 && <li className="px-2">...</li>}

                {/* Previous Page */}
                {currentPage > 1 && (
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(currentPage - 1)}
                    >
                      {currentPage - 1}
                    </button>
                  </li>
                )}

                {/* Current Page */}
                <li>
                  <button
                    className="flex items-center justify-center px-3 h-8 leading-tight font-medium text-blue-600 bg-blue-50 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={() => goToPage(currentPage)}
                  >
                    {currentPage}
                  </button>
                </li>

                {/* Next Page */}
                {currentPage < totalPages && (
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-black font-medium bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(currentPage + 1)}
                    >
                      {currentPage + 1}
                    </button>
                  </li>
                )}

                {currentPage < totalPages - 2 && <li className="px-2">...</li>}

                {currentPage < totalPages - 1 && (
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-black font-medium bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(totalPages)}
                    >
                      {totalPages}
                    </button>
                  </li>
                )}

                {/* Next Button */}
                <li>
                  <button
                    className="flex items-center justify-center px-3 h-8 leading-tight font-medium text-black dark:text-textW bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700  "
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}

        {/* <div className="fixed bottom-12 left-0 right-0 mb-auto bg-white dark:bg-gray-700"> */}

        {/* </div> */}
      </div>
    </>
  );
};

export default Faq;
