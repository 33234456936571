import axios from "axios";
import { errorGlobal, successGlobal } from "../reducers/notification";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthHeader } from "../../utills/tools";

export const saveAPI = createAsyncThunk(
  "admin/apis/save",
  async (faqData, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/apis/new`,
        faqData,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getAllAPI = createAsyncThunk(
  "user/apis/all",
  async (query, { dispatch,getState,rejectWithValue }) => {
    try {
      // Convert JSON to URL query string
      const queryString = new URLSearchParams(query).toString();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/apis/all?${queryString}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getAPIDetails = createAsyncThunk(
  "admin/apis/deatils",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/apis/${id}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const editAPI = createAsyncThunk(
  "admin/api/edit",
  async (data, { dispatch }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/apis/${data.id}`,
        data.data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);
