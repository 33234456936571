import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCountries,
  saveCountries,
  getDetailCountries,
  editCountries
} from "../actions/countery";

export const categorySlice = createSlice({
  name: "category",
  initialState: {
    loading: false,
    counteryList: [],
    currentCountery: {},
    selectedCountery: {},
    totalCount: 0,
  },
  reducers: {
    setCurrentCountery: (state, action) => {
      state.selectedCountery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //getAllCountries
      .addCase(getAllCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.counteryList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllCountries.rejected, (state) => {
        state.loading = false;
      })
      //saveCountries
      .addCase(saveCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.counteryList = [
          ...state.counteryList,
          action.payload.result,
        ];
      })
      .addCase(saveCountries.rejected, (state) => {
        state.loading = false;
      })

      //editCategory
      .addCase(editCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(editCountries.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editCountries.rejected, (state) => {
        state.loading = false;
      })
      //getDetailCountries
      .addCase(getDetailCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDetailCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCountery = action.payload.result;
      })
      .addCase(getDetailCountries.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentCountery } = categorySlice.actions;
export default categorySlice.reducer;
