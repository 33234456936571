import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { getAllFaq } from "../../store/actions/faq";
import { Loader } from "../../utills/tools";
import FaqHeader from "./FaqHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FaqTab = () => {
  const dispatch = useDispatch();
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("all");
  const [faqData, setFaqData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterQuery, setFilterQuery] = useState({
    limit: "10",
    page: "1",
  });
  const [isModalEdit, setIsModalEdit] = useState(false);

  const faqs = useSelector((state) => state.faqs);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(faqs.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  useEffect(() => {
    dispatch(getAllFaq(filterQuery));
  }, [filterQuery, setFilterQuery]);

  useEffect(() => {
    setFaqData(faqs.faqsList);
  }, [faqs]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  const headers = [
    { keys: "S.No" },
    { keys: "Question" },
    { keys: "Answer" },
    { keys: "Status" },
    { keys: "Action" },
  ];

  // Function to handle tab switching
  const handleTabClick = (tab) => {
    let query = { ...filterQuery };
    setActiveTab(tab); // Set the clicked tab as the active tab
    if (tab == "active") {
      query.status = "1";
    } else if (tab == "inactive") {
      query.status = "0";
    } else {
      delete query.status;
    }
    setFilterQuery(query);
  };

  const toggleModalEdit = () => {
    setIsModalEdit(!isModalEdit);
  };
  const handleEdit = () => {
    toast.success("Faq edit successfully!", {
      position: "top-right",
      autoClose: 600,
    });
  };
  const handelBlock = () => {
    toast.success("Faq block successfully!", {
      position: "top-right",
      autoClose: 600,
    });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="relative flex flex-col">
        <div className="fixed w-full max-w-[calc(100%-17rem)] z-10 ">
          <FaqHeader onSearch={setFilterQuery} />
        </div>
        <div className="flex flex-row justify-between gap-14 mt-12 w-full max-w-[calc(100%-17rem)] items-center text-center tab-container fixed z-9 bg-white">
          <h2 className="text-lg font-medium mx-2">Faq</h2>
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`tab-button ${
                  activeTab === "all" ? "tab-button-active" : ""
                }`}
                id="all"
                type="button"
                onClick={() => handleTabClick("all")}
                aria-selected={activeTab === "all"}
              >
                All
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`tab-button ${
                  activeTab === "active" ? "tab-button-active" : ""
                }`}
                id="active"
                type="button"
                onClick={() => handleTabClick("active")}
                aria-selected={activeTab === "active"}
              >
                Active
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`tab-button ${
                  activeTab === "inactive" ? "tab-button-active" : ""
                }`}
                id="inactive"
                type="button"
                onClick={() => handleTabClick("inactive")}
                aria-selected={activeTab === "inactive"}
              >
                Inactive
              </button>
            </li>
          </ul>
        </div>

        <div id="tab-content ">
          <div
          // className={`tab-content  ${
          //   activeTab === "all" ? "tab-content-active" : ""
          // }`}
          // id="all-content"
          >
            {/* Table Start Code.......  */}
            <div className=" shadow-md sm:rounded-lg mt-24">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8 ">
                <thead className=" text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {headers.map((header, index) => (
                      <th scope="col" className="px-2 py-3">
                        {header.keys}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="overflow-y-auto">
                  {faqs && faqs.loading ? (
                    <Loader />
                  ) : (
                    faqData.map((faqData, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <th className="px-2 py-2 font-medium text-gray-900 dark:text-white">
                          {indexOfFirstRow + index + 1}
                        </th>
                        <th className="px-2 py-2 font-medium text-gray-900 dark:text-white">
                          {faqData?.questions}
                        </th>
                        <td className="px-2 py-2">{faqData?.answers}</td>
                        <td className="px-2 py-2">
                          {faqData?.status == 1 ? "Active" : "Inactive"}
                        </td>
                        <td className="px-2 py-2 space-x-8 flex flex-row">
                          <button
                            type="button"
                            onClick={toggleModalEdit}
                            className="font-normal  text-white text-sm px-2 rounded  bg-blue-600 hover:bg-blue-800"
                          >
                            Edit
                          </button>
                          {isModalEdit && (
                            <div
                              id="crud-modal"
                              className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10"
                            >
                              <div className="relative p-4 w-full max-w-md h-auto">
                                {/* Modal Content */}
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                  {/* Modal Header */}
                                  <div className="flex items-center justify-between p-2 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                      Edit FAQ
                                    </h3>
                                    <button
                                      type="button"
                                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                      onClick={toggleModalEdit} // Correct function for toggling modal
                                    >
                                      <IoClose className="w-5 h-5" />
                                    </button>
                                  </div>

                                  {/* Modal Body */}
                                  <form
                                    className="p-4 md:p-5"
                                    onClick={handleEdit}
                                  >
                                    <div className="grid gap-4 mb-4 grid-cols-2">
                                      {/* Question */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="question"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Question
                                        </label>
                                        <input
                                          type="text"
                                          id="question"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter Question"
                                          required
                                        />
                                      </div>

                                      {/* Answer */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="answer"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Answer
                                        </label>
                                        <textarea
                                          id="answer"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter Answer"
                                          rows="3"
                                          required
                                        ></textarea>
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="text-white inline-flex items-center w-full justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      Edit
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}

                          <button
                            onClick={handelBlock}
                            to="#"
                            className="font-normal text-white text-sm px-2 rounded bg-red-600 hover:bg-red-800"
                          >
                            Block
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <nav
                className="flex items-center justify-between p-2"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Showing{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {indexOfFirstRow + 1} -{" "}
                    {Math.min(indexOfLastRow, faqs.totalCount)}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {faqs.totalCount}
                  </span>
                </span>

                <ul className="inline-flex items-center -space-x-px text-sm h-8">
                  {/* Previous Button */}
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handlePrev}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>

                  {/* Page Buttons */}
                  {currentPage > 2 && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(1)}
                      >
                        1
                      </button>
                    </li>
                  )}

                  {currentPage > 3 && <li className="px-2">...</li>}

                  {/* Previous Page */}
                  {currentPage > 1 && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(currentPage - 1)}
                      >
                        {currentPage - 1}
                      </button>
                    </li>
                  )}

                  {/* Current Page */}
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-blue-600 bg-blue-50 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(currentPage)}
                    >
                      {currentPage}
                    </button>
                  </li>

                  {/* Next Page */}
                  {currentPage < totalPages && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(currentPage + 1)}
                      >
                        {currentPage + 1}
                      </button>
                    </li>
                  )}

                  {currentPage < totalPages - 2 && (
                    <li className="px-2">...</li>
                  )}

                  {currentPage < totalPages - 1 && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(totalPages)}
                      >
                        {totalPages}
                      </button>
                    </li>
                  )}

                  {/* Next Button */}
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handleNext}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            {/* Table End Code .....  */}
          </div>
          {/* <div
          className={`tab-content ${
            activeTab === "active" ? "tab-content-active" : ""
          }`}
          id="active-content"
        >
          <p>This is the content for the Active tab.</p>
        </div>
        <div
          className={`tab-content ${
            activeTab === "inactive" ? "tab-content-active" : ""
          }`}
          id="inactive-content"
        >
          <p>This is the content for the Inactive tab.</p>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default FaqTab;
