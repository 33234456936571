import React, { useEffect, useState, useRef } from "react";
import "./HeaderOne.css";
import { RiCustomerService2Line } from "react-icons/ri";
import { IoWalletOutline } from "react-icons/io5";
// import Profile from "../../../assets/profile.png";
import { CgProfile } from "react-icons/cg";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/actions/auth";
import { FaSignOutAlt } from "react-icons/fa"; // Import the logout icon
import ThemeToggle from "../../ThemeToggle";
import { getMyWallet } from "../../../store/actions/wallets";

const HeaderOne = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State management
  const [currentUser, setCurrentUser] = useState(null);
  const profileDropdownRef = useRef(null); // Ref for the profile dropdown
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  // Redux state
  const wallets = useSelector((state) => state.wallets);

  // Fetch user authentication status and countries
  useEffect(() => {
    dispatch(getMyWallet());
  }, [dispatch]);

  // Set current user data
  useEffect(() => {
    setCurrentUser(wallets.wallet);
  }, [wallets]);

  // Toggle profile dropdown visibility
  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen((prev) => !prev);
  };

  // Handle signout
  const handleSignout = () => {
    // Clear local storage
    localStorage.removeItem("role");
    localStorage.removeItem("theme");
    localStorage.removeItem("token");
    localStorage.removeItem("users");

    // Dispatch logout action
    dispatch(logout());

    // Navigate and refresh the page after a delay
    setTimeout(() => {
      navigate("/");

      // Optionally reload the page after navigating to clear any cached state
      window.location.reload(); // Refresh the page to ensure a full logout
    }, 600);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside both dropdowns
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileDropdownRef]);

  return (
    <div className=" dark:bg-textB flex flex-row justify-end sticky top-0 right-0 left-0 z-40 bg-gray-50">
      <div className="header-one">
        {/* Dark Mode  */}
        <div>
          <ThemeToggle />
        </div>
        {/* Support Link */}
        <div className="dark:text-textW">
          <Link to="https://t.me/coffin_91sim" className="link">
            <div className="header-one-customer">
              <RiCustomerService2Line className="header-one-icon dark:text-textW" />
              <h4>Support</h4>
            </div>
          </Link>
        </div>
        {/* Wallet Display */}
        <div>
          <Link to="/user/userWallet" className="link">
            <div className="header-one-wallet">
              <div className="header-one-wallet-icons bg-buttonBg">
                <IoWalletOutline className="header-one-icon2" />
              </div>
              <div className="header-one-wallet-price rounded-r">
                <h4>₹{parseFloat(currentUser?.amount).toFixed(3)}</h4>
              </div>
            </div>
          </Link>
        </div>
        {/* Profile Dropdown */}
        <div
          className="relative inline-block text-left mr-2"
          ref={profileDropdownRef}
        >
          <div>
            <button
              type="button"
              className="inline-flex w-full justify-center py-3 text-sm font-semibold text-gray-900 hover:bg-gray-50 dark:text-white dark:hover:bg-textB"
              id="menu-button"
              aria-expanded={isProfileDropdownOpen}
              aria-haspopup="true"
              onClick={toggleProfileDropdown}
            >
              <CgProfile className="h-6 w-6" />
            </button>
          </div>

          {isProfileDropdownOpen && (
            <div
              className="absolute right-0 z-10 mt-1 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black dark:ring-opacity-40"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="-1"
            >
              <div className="py-1" role="none">
                <Link
                  to="/user/userProfile"
                  className="block px-4 py-2 text-sm text-black hover:bg-slate-100 dark:text-white dark:hover:bg-gray-700"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                  onClick={toggleProfileDropdown}
                >
                  Profile Details
                </Link>

                <button
                  type="button"
                  className="flex items-center block w-full px-4 py-2 text-left text-sm text-black hover:bg-slate-100 dark:text-white dark:hover:bg-gray-700"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-3"
                  onClick={handleSignout}
                >
                  <FaSignOutAlt className="mr-2 dark:text-white" />
                  Log Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderOne;
