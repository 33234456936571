import axios from "axios";
import { errorGlobal, successGlobal } from "../reducers/notification";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthHeader } from "../../utills/tools";

export const getProfitReports = createAsyncThunk(
  "user/transection/profitReport",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/transection/profitReport`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getAllUserTransection = createAsyncThunk(
  "user/transection/all",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/transection/me`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getAllTransectionByAdmin = createAsyncThunk(
  "admin/transection/all",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/transection/all`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getTransectionDetailsByAdmin = createAsyncThunk(
  "admin/transection/details",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/transection/all`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const addmoneyToWalletByAdmin = createAsyncThunk(
  "admin/transection/addMoney",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/transection/addMoney`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);