import React, { useEffect, useState } from "react";
import "./Profile.css";
import ProfileImg from "../../assets/profile.png";
import { BiCopyAlt } from "react-icons/bi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoMdKey } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { resetUserPassword, updateUserApiKey } from "../../store/actions/users";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(null);
  const users = useSelector((state) => state.auth);

  useEffect(() => {
    setCurrentUser(users.data);
  }, [users]);
  const handleChangeAPiKey = () => {
    dispatch(updateUserApiKey());
    toast.success("Api key updated successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 2000,
    });
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    const targetValue = e.target;
    if (targetValue.oldPassword.value === "") {
      toast.error("Please enter old password!", {
        position: "top-right", // Use string instead of POSITION.TOP_RIGHT
        autoClose: 2000,
      });
      return;
    }
    if (targetValue.newPassword.value === "") {
      toast.error("Please enter new password!", {
        position: "top-right", // Use string instead of POSITION.TOP_RIGHT
        autoClose: 2000,
      });
      return;
    }
    if (targetValue.confirmPassword.value === "") {
      toast.error("Please enter confirm password!", {
        position: "top-right", // Use string instead of POSITION.TOP_RIGHT
        autoClose: 2000,
      });
      return;
    }
    if (targetValue.newPassword.value !== targetValue.confirmPassword.value) {
      toast.error("New password and confirm password must be same!", {
        position: "top-right", // Use string instead of POSITION.TOP_RIGHT
        autoClose: 2000,
      });
      return;
    }

    dispatch(
      resetUserPassword({
        oldPassword: targetValue.oldPassword.value,
        newPassword: targetValue.newPassword.value,
        confirmPassword: targetValue.confirmPassword.value,
      })
    );
    toast.success("Password changed successfully", {
      position: "top-right",
      autoClose: 2000,
    });
  };
  const copyNumberToClipboard = () => {
    toast.success("Copied 🤟!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
    });
  };
  return (
    <>
      <div className="profile-complete">
        <div className="profile-start">
          <div>
            <img src={ProfileImg} alt="" />
          </div>
          <div>
            <h2>{currentUser?.email}</h2>
            <h2>Balance: {currentUser?.wallet?.amount}</h2>
          </div>
        </div>
        <div className="profile-two">
          <div className="api-main">
            <h2>{currentUser?.apiKey}</h2>
            <CopyToClipboard text={currentUser?.apiKey}>
              <button className="api-key" onClick={copyNumberToClipboard}>
                <h2>Copy Key</h2>
                <BiCopyAlt />
              </button>
            </CopyToClipboard>
          </div>
          <div className="parent-container">
            <div className="api-main-two" onClick={handleChangeAPiKey}>
              <h4>Generate a new key</h4>
              <AiOutlineFilePdf />
            </div>
          </div>
        </div>
        <form onSubmit={handleChangePassword}>
          <div className="password-container">
            <h2>Change Password</h2>
            <input
              type="password"
              placeholder="Enter Old Password"
              name="oldPassword"
              id="oldPassword"
              className="input"
            />
            <div className="password-row">
              <input
                type="password"
                placeholder="Enter New Password"
                name="newPassword"
                id="newPassword"
                className="input"
              />
              <input
                type="password"
                placeholder="Confirm New Password"
                name="confirmPassword"
                id="confirmPassword"
                className="input"
              />
            </div>
            <div className="password-button">
              <button>
                Change Password <IoMdKey className="password-icon" />
              </button>
            </div>
          </div>
        </form>
        <div className="profile-get">
          <h2>Get push notification when receiving SMS</h2>
          <div className="profile-get-switch">
            <label className="inline-flex items-center mt-3 cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="switch-text ml-4">Toggle me</span>
            </label>
          </div>
        </div>
        <ToastContainer className="custom-toast" />
      </div>
    </>
  );
};

export default Profile;
