import React, { useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Dashboard from "./adminPages/dashboard/Dashboard";
import AdminDashboard from "./adminPages";
import UserManagement from "./adminPages/userManagement/UserManagement";
import Orders from "./adminPages/orders/Orders";
import Faq from "./adminPages/faq/Faq";
import Api from "./adminPages/api/Api";
import Services from "./adminPages/settings/services/Services";
import Category from "./adminPages/settings/category/Category";
import Country from "./adminPages/settings/country/Country";
import Provider from "./adminPages/settings/provider/Provider";
import Login from "./adminPages/adminAuth/login/Login";
import Register from "./adminPages/adminAuth/register/Register";
import Financial from "./adminPages/financial/Financial";
import PaymentRequest from "./adminPages/financial/paymentRequest/PaymentRequest";
import UserLedger from "./adminPages/financial/userLedger/UserLedger";
import AdminLedger from "./adminPages/financial/adminLedger/AdminLedger";
import TicketSupport from "./adminPages/ticketSupport/TicketSupport";
import Settings from "./adminPages/settings/Settings";
import GetNumber from "./userPages/getNumber/GetNumber";
import MyNumber from "./userPages/myNumbers/MyNumber";
import History from "./userPages/history/History";
import UserApi from "./userPages/api/Api";
import UserFaq from "./userPages/faq/Faq";
import Wallet from "./userPages/wallet/Wallet";
import Profile from "./userPages/profile/Profile";
import UserDashboard from "./userPages";
import ForgotPassword from "./adminPages/adminAuth/forgotPassword/ForgotPassword";
import { useDispatch } from "react-redux";
import { isAuth } from "./store/actions/users";
import AuthGuard from "./hoc/authGaurd";
import AdminGuard from "./hoc/adminGaurd";
import Report from "./adminPages/financial/report/Report";
import Aos from "aos";
import "aos/dist/aos.css";
import { toast } from "react-toastify";
import { logout } from "./store/actions/auth";
import AdminLayout from "./adminComponent/adminLayout/AdminLayout";
import Dashboard from "./adminPages/dashboard/Dashboard";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(isAuth())
      .then((result) => {
        if (
          !result?.payload?.message ===
          "User logged into another system,please login again!"
        ) {
          toast.error(result?.payload?.message, {
            position: "top-right", // Use string instead of POSITION.TOP_RIGHT
            autoClose: 600,
            onClose: () => {
              dispatch(logout());
            },
          });
        }
      })
      .catch((err) => {
        // Handle unexpected errors (rare case)
        console.log("auth test err ", err);
      });
  }, [dispatch]);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <div className="App-container">
        <Router>
          <Routes>
            <Route
              path="/admin"
              element={
                <AdminGuard>
                  <AdminDashboard />
                </AdminGuard>
              }
            >
              {/* <Route path="/admin" element={<AdminLayout />}> */}
              <Route index element={<Dashboard />} />
              <Route path="userManagement" element={<UserManagement />} />
              <Route path="order" element={<Orders />} />
              <Route path="financial" element={<Financial />}>
                <Route index element={<UserLedger />} />
                <Route path="paymentRequest" element={<PaymentRequest />} />
                <Route path="adminLedger" element={<AdminLedger />} />
                <Route path="report" element={<Report />} />
              </Route>
              <Route path="faq" element={<Faq />} />
              <Route path="api" element={<Api />} />
              <Route path="settings" element={<Settings />}>
                <Route index element={<Services />} />
                {/* <Route path="category" element={<Category />} /> */}
                <Route path="country" element={<Country />} />
                <Route path="provider" element={<Provider />} />
              </Route>
              <Route path="ticketSupport" element={<TicketSupport />} />
              {/* <Route path="/" element={<Dashboard />} /> */}
              {/* <Route path="/adminWallet" element={<AdminWallet />} /> */}
              {/* <Route path="/notification" element={<Notification />} /> */}
              {/* <Route path="/adminProfile" element={<AdminProfile />} /> */}
            </Route>

            {/* // User Route Pages Start....  */}
            <Route
              path="/user"
              element={
                <AuthGuard>
                  <UserDashboard />
                </AuthGuard>
              }
            >
              <Route index element={<GetNumber />} />
              <Route path="myNumber" element={<MyNumber />} />
              <Route path="history" element={<History />} />
              <Route path="userApi" element={<UserApi />} />
              <Route path="userFaq" element={<UserFaq />} />
              <Route path="userWallet" element={<Wallet />} />
              <Route path="userProfile" element={<Profile />} />
            </Route>
            <Route path="/register" element={<Register />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/" element={<Login />} />
          </Routes>
        </Router>
      </div>
    </>
  );
};

export default App;
