import React, { useEffect, useState } from "react";
import "./UserManageTab.css";
import { useDispatch, useSelector } from "react-redux";
import {
  adminEditUser,
  adminGetAllUser,
  getUserWalletAmount,
} from "../../../store/actions/users";
import { IoClose, IoEyeOff, IoEye } from "react-icons/io5";
import { FaIndianRupeeSign } from "react-icons/fa6";
import AdminHeaderTwo from "./../userManageHead/AdminHeaderTwo";
import {
  addMoneyToUser,
  withdrawMoneyFromUser,
} from "../../../store/actions/paymentRequest";
import {
  updateRegistrationSetting,
  isAuth,
} from "../../../store/actions/users";
import { Loader } from "../../../utills/tools";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserManageTab = () => {
  const dispatch = useDispatch();
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [filterQuery, setFilterQuery] = useState({
    limit: "10",
    page: "1",
  });
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [userwalletAmt, setUserwalletAmt] = useState(0);
  const [isModalWithdraw, setIsModalWithdraw] = useState(false);
  const [isModalAddMoney, setIsModalAddMoney] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false); // State for "select all" checkbox
  const [selectedItems, setSelectedItems] = useState([]); // State for individual checkboxes

  const users = useSelector((state) => state.userManagement);
  const auths = useSelector((state) => state.auth);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(users.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const handleFetchData = () => {
    dispatch(adminGetAllUser(filterQuery));
  };
  const handleFetchUser = () => {
    dispatch(isAuth());
  };
  const handleUserWalletAmount = () => {
    dispatch(getUserWalletAmount()).then((result) => {
      setUserwalletAmt(result?.payload?.totalAmount);
    });
  };

  useEffect(() => {
    handleFetchData();
  }, [filterQuery, setFilterQuery]);

  useEffect(() => {
    handleUserWalletAmount();
  }, [dispatch]);

  useEffect(() => {
    setProducts(users.userList);
  }, [users]);

  useEffect(() => {
    setCurrentUser(auths.data);
  }, [auths]);

  const headers = [
    { keys: "S.No" },
    { keys: "Name" },
    { keys: "Email" },
    { keys: "Password" },
    { keys: "Role" },
    { keys: "Wallet" },
    { keys: "Status" },
    { keys: "Action" },
  ];

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  // Function to handle tab switching
  const handleTabClick = (tab) => {
    let query = { ...filterQuery };
    setActiveTab(tab); // Set the clicked tab as the active tab
    if (tab == "active") {
      query.status = true;
    } else if (tab == "inactive") {
      query.status = false;
    } else {
      delete query.status;
    }
    setFilterQuery(query);
  };
  const toggleModalEdit = (data) => {
    setIsModalEdit(true);
    setCurrentData(data);
  };
  const toggleModalEditClose = () => {
    setIsModalEdit(false);
    setCurrentData(null);
  };
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleEditUser = (e) => {
    e.preventDefault();
    const targetValue = e.target;
    dispatch(
      adminEditUser({
        id: currentData._id,
        data: {
          name: targetValue.name.value,
          email: targetValue.email.value,
          password: targetValue.password.value,
          roleId: targetValue.roleId.value,
        },
      })
    );
    setIsModalEdit(false);
    setCurrentData(null);
    toast.success("User updated successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => {
        handleFetchData();
      },
    });
  };

  const toggleModalAddMoney = (data) => {
    setIsModalAddMoney(true);
    setCurrentData(data);
  };
  const toggleModalAddMoneyClose = () => {
    setIsModalAddMoney(false);
    setCurrentData(null);
  };

  const toggleModalWithdraw = (data) => {
    setIsModalWithdraw(true);
    setCurrentData(data);
  };
  const toggleModalWithdrawClose = () => {
    setIsModalWithdraw(false);
    setCurrentData(null);
  };

  const handleBlockUser = (id) => {
    dispatch(adminEditUser({ id: id, data: { status: 0 } }));
    // Show a success toast message
    toast.success("Block user successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
      onClose: () => {
        handleFetchData();
      },
    });
  };

  const handleUnblockUser = (id) => {
    dispatch(adminEditUser({ id: id, data: { status: 1 } }));
    toast.success("Unblock user successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
      onClose: () => {
        handleFetchData();
      },
    });
  };

  const handleWithdraw = (e) => {
    e.preventDefault();
    dispatch(
      withdrawMoneyFromUser({
        userId: e.target.userId.value,
        amount: e.target.amount.value,
      })
    );
    setIsModalWithdraw(false);
    setCurrentData(null);
    toast.success("Withdraw Successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
      onClose: () => {
        handleFetchData();
      },
    });
  };

  const handleaddMoneyToUser = (e) => {
    e.preventDefault();
    dispatch(
      addMoneyToUser({
        userId: e.target.userId.value,
        amount: e.target.amount.value,
      })
    );
    toast.success("Add Money Successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
      onClose: () => {
        handleFetchData();
      },
    });
    setIsModalAddMoney(false);
    setCurrentData(null);
  };
  const handleSelectAll = () => {
    setSelectedAll(!selectedAll);
    if (!selectedAll) {
      // Select all
      const allIds = products.map((product) => product._id);
      setSelectedItems(allIds);
    } else {
      // Deselect all
      setSelectedItems([]);
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      // If already selected, remove from selection
      setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
    } else {
      // Otherwise, add to selection
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleToggle = () => {
    dispatch(
      updateRegistrationSetting({
        name: "ExternalRegistration",
        rule: {
          desc: "is External registration Active",
          allregistration: !currentUser?.setting?.allregistration,
        },
      })
    );
    toast.success("Eternal Registration setting Updated Successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
      onClose: () => {
        handleFetchUser();
      },
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex flex-col relative ml-2">
        <div className="fixed  w-full max-w-[calc(100%-17rem)] mx-auto z-10">
          <AdminHeaderTwo onSearch={setFilterQuery} />
        </div>

        <div className="mt-14">
          <div className="flex flex-row justify-between gap-14  w-full max-w-[calc(100%-17rem)] items-center text-center tab-container fixed z-9 bg-white">
            <div>
              {" "}
              <h2 className="text-lg font-medium mx-2">User Management</h2>
            </div>
            <div>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={currentUser?.setting?.allregistration}
                  onChange={handleToggle}
                />
                <div className="relative w-11 h-5 bg-gray-200 rounded-full  dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[8px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  External User
                </span>
              </label>
            </div>
            <div className="flex flex-col items-start">
              <div className="flex items-center text-sm">
                <FaIndianRupeeSign />
                <span className="ml-1">{userwalletAmt}</span>
              </div>
              <small className="text-xs text-gray-500">
                amount all User Hold
              </small>
            </div>
            <div>
              <ul
                className="flex flex-wrap -mb-px text-sm font-medium text-center"
                role="tablist"
              >
                <li className="mr-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "all" ? "tab-button-active" : ""
                    }`}
                    id="all"
                    type="button"
                    onClick={() => handleTabClick("all")}
                    role="tab"
                    aria-selected={activeTab === "all"}
                  >
                    All
                  </button>
                </li>
                <li className="mr-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "active" ? "tab-button-active" : ""
                    }`}
                    id="active"
                    type="button"
                    onClick={() => handleTabClick("active")}
                    role="tab"
                    aria-selected={activeTab === "active"}
                  >
                    Active
                  </button>
                </li>
                <li className="mr-2" role="presentation">
                  <button
                    className={`tab-button ${
                      activeTab === "inactive" ? "tab-button-active" : ""
                    }`}
                    id="inactive"
                    type="button"
                    onClick={() => handleTabClick("inactive")}
                    role="tab"
                    aria-selected={activeTab === "inactive"}
                  >
                    Inactive
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div id="tab-content ">
            {/* Table Start Code.......  */}

            <div className="shadow-md sm:rounded-lg sm:overflow-x-auto mt-14">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8">
                <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col-2" className="p-4">
                      <div className="items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          checked={selectedAll}
                          onChange={handleSelectAll}
                        />
                        <label
                          htmlFor="checkbox-all-search"
                          className="sr-only"
                        >
                          Select All
                        </label>
                      </div>
                    </th>
                    {headers.map((header, index) => (
                      <th key={index} scope="col" className="px-2 py-3">
                        {header.keys}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="overflow-y-auto">
                  {users && users.loading ? (
                    <Loader />
                  ) : (
                    products.map((product, index) => (
                      <tr
                        key={product?._id}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="w-4 p-4">
                          <input
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={selectedItems.includes(product._id)}
                            onChange={() => handleCheckboxChange(product._id)}
                          />
                        </td>
                        <th className="px-2 py-2 font-medium text-gray-900 dark:text-white">
                          {indexOfFirstRow + index + 1}
                        </th>
                        <th className="px-2 py-2 font-medium text-gray-900 dark:text-white">
                          {product?.name}
                        </th>
                        <td className="px-2 py-2">{product?.email}</td>
                        <td className="px-2 py-2">{product?.user_password}</td>
                        <td className="px-2 py-2">
                          {product?.roleId == 0 ? "Admin" : "User"}
                        </td>
                        <td className="px-2 py-2">{product?.wallet?.amount}</td>
                        <td className="px-2 py-2">
                          {product?.status ? "Active" : "Inactive"}
                        </td>
                        <td className="px-2 py-4 space-x-4 flex">
                          <button
                            type="button"
                            onClick={() => toggleModalEdit(product)}
                            className="font-normal  text-white text-sm px-2 rounded bg-blue-600 hover:bg-blue-800"
                          >
                            Edit
                          </button>
                          {currentData && isModalEdit && (
                            <div
                              id="crud-modal"
                              className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10"
                            >
                              <div className="relative p-4 w-full max-w-md h-auto">
                                {/* Modal Content */}
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                  {/* Modal Header */}
                                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                      Edit User
                                    </h3>
                                    <button
                                      type="button"
                                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                      onClick={toggleModalEditClose} // Correct function for toggling modal
                                    >
                                      <IoClose className="w-5 h-5" />
                                    </button>
                                  </div>

                                  {/* Modal Body */}
                                  <form
                                    className="p-4 md:p-5"
                                    onSubmit={handleEditUser}
                                  >
                                    <div className="grid gap-4 mb-4 grid-cols-2">
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="name"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Name
                                        </label>
                                        <input
                                          type="text"
                                          id="name"
                                          name="name"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter your name"
                                          defaultValue={currentData.name}
                                          required
                                        />
                                      </div>

                                      <div className="col-span-2">
                                        <label
                                          htmlFor="email"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Email
                                        </label>
                                        <input
                                          type="email"
                                          id="email"
                                          name="email"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter your email"
                                          defaultValue={currentData.email}
                                          required
                                        />
                                      </div>
                                      <div className="col-span-2 relative">
                                        <label
                                          htmlFor="password"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Password
                                        </label>
                                        <input
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          id="password"
                                          name="password"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter your password"
                                          defaultValue={
                                            currentData.user_password
                                          }
                                          required
                                        />
                                        <button
                                          type="button"
                                          onClick={togglePasswordVisibility}
                                          className="absolute right-2 top-10 text-gray-600 dark:text-gray-300"
                                        >
                                          {showPassword ? (
                                            <IoEyeOff />
                                          ) : (
                                            <IoEye />
                                          )}
                                        </button>
                                      </div>

                                      {/* Role Dropdown */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="roleId"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Role
                                        </label>
                                        <select
                                          id="roleId"
                                          name="roleId"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          defaultValue={currentData.roleId}
                                        >
                                          {/* <option value="0">Admin</option> */}
                                          <option value="1">User</option>
                                        </select>
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="text-white  w-full justify-center inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      Edit
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}

                          {product?.status == 1 && (
                            <button
                              className="font-normal text-white text-sm px-4 rounded bg-red-600 hover:bg-red-800"
                              onClick={() => handleBlockUser(product?._id)}
                            >
                              Block
                            </button>
                          )}
                          {product?.status == 0 && (
                            <button
                              className="font-normal text-white text-sm px-2 rounded bg-green-600 hover:bg-green-500 "
                              onClick={() => handleUnblockUser(product?._id)}
                            >
                              Unblock
                            </button>
                          )}
                          <button
                            type="button"
                            onClick={() => toggleModalWithdraw(product)}
                            className="font-normal text-white text-sm px-2 rounded bg-green-900 hover:bg-green-500"
                          >
                            Withdraw
                          </button>
                          {currentData && isModalWithdraw && (
                            <div
                              id="crud-modal"
                              className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10"
                            >
                              <div className="relative p-4 w-full max-w-md h-auto">
                                {/* Modal Content */}
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                  {/* Modal Header */}
                                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                      Withdraw Amount
                                    </h3>
                                    <button
                                      type="button"
                                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                      onClick={toggleModalWithdrawClose} // Correct function for toggling modal
                                    >
                                      <IoClose className="w-5 h-5" />
                                    </button>
                                  </div>

                                  {/* Modal Body */}
                                  <form
                                    className="p-4 md:p-5"
                                    onSubmit={handleWithdraw}
                                  >
                                    <div className="grid gap-4 mb-4 grid-cols-2">
                                      {/* UserId Field */}
                                      <h3>
                                        {currentData?.name} | Bal. :{" "}
                                        {currentData?.wallet?.amount}
                                      </h3>
                                      <div className="col-span-2 hidden">
                                        <label
                                          htmlFor="userId"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          UserName
                                        </label>
                                        <input
                                          type="text"
                                          id="userId"
                                          name="userId"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          defaultValue={currentData?._id}
                                          readOnly
                                          required
                                        />
                                      </div>

                                      {/* Amount Field with Number Input */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="amount"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          id="amount"
                                          name="amount"
                                          step="any"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter withdraw amount"
                                          min="0"
                                          required
                                        />
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="w-full justify-center text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      Withdraw
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}
                          <button
                            type="button"
                            onClick={() => toggleModalAddMoney(product)}
                            className="font-normal text-white text-sm px-2 rounded bg-green-600 hover:bg-green-300"
                          >
                            Add Money
                          </button>
                          {currentData && isModalAddMoney && (
                            <div
                              id="crud-modal"
                              className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10"
                            >
                              <div className="relative p-4 w-full max-w-md h-auto">
                                {/* Modal Content */}
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                  {/* Modal Header */}
                                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                      Add Money to User
                                    </h3>
                                    <button
                                      type="button"
                                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                      onClick={toggleModalAddMoneyClose} // Correct function for toggling modal
                                    >
                                      <IoClose className="w-5 h-5" />
                                    </button>
                                  </div>

                                  {/* Modal Body */}
                                  <form
                                    className="p-4 md:p-5"
                                    onSubmit={handleaddMoneyToUser}
                                  >
                                    <div className="grid gap-4 mb-4 grid-cols-2">
                                      {/* UserId Field */}
                                      <h3>
                                        {currentData?.name} | Bal. :{" "}
                                        {currentData?.wallet?.amount}
                                      </h3>
                                      <div className="col-span-2 hidden">
                                        <label
                                          htmlFor="userId"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          UserName
                                        </label>
                                        <input
                                          type="text"
                                          id="userId"
                                          name="userId"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          defaultValue={currentData?._id}
                                          readOnly
                                          required
                                        />
                                      </div>

                                      {/* Amount Field with Number Input */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="amount"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          id="amount"
                                          name="amount"
                                          step="any"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter amount"
                                          min="0"
                                          required
                                        />
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="w-full justify-center text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      Add Money
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <nav
                className="flex items-center justify-between p-2"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-black dark:text-gray-400">
                  Showing{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {indexOfFirstRow + 1}-
                    {Math.min(indexOfLastRow, users.totalCount)}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {users.totalCount}
                  </span>
                </span>

                <ul className="inline-flex items-center -space-x-px text-sm h-8">
                  {/* Previous Button */}
                  <li>
                    <button
                      onClick={handlePrev}
                      disabled={currentPage === 1}
                      className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-black-600 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      Previous
                    </button>
                  </li>

                  {/* First Page Button */}
                  {currentPage > 2 && (
                    <li>
                      <button
                        onClick={() => goToPage(1)}
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        1
                      </button>
                    </li>
                  )}

                  {/* Ellipsis if necessary */}
                  {currentPage > 3 && <li className="px-2">...</li>}

                  {/* Previous Page */}
                  {currentPage > 1 && (
                    <li>
                      <button
                        onClick={() => goToPage(currentPage - 1)}
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        {currentPage - 1}
                      </button>
                    </li>
                  )}

                  {/* Current Page */}
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-blue-600 bg-blue-50 border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(currentPage)}
                    >
                      {currentPage}
                    </button>
                  </li>

                  {/* Next Page */}
                  {currentPage < totalPages && (
                    <li>
                      <button
                        onClick={() => goToPage(currentPage + 1)}
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        {currentPage + 1}
                      </button>
                    </li>
                  )}

                  {/* Ellipsis if necessary */}
                  {currentPage < totalPages - 2 && (
                    <li className="px-2">...</li>
                  )}

                  {/* Last Page Button */}
                  {currentPage < totalPages - 1 && (
                    <li>
                      <button
                        onClick={() => goToPage(totalPages)}
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        {totalPages}
                      </button>
                    </li>
                  )}

                  {/* Next Button */}
                  <li>
                    <button
                      onClick={handleNext}
                      disabled={currentPage === totalPages}
                      className="flex items-center justify-center px-3 h-8 leading-tight text-black-600 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>

            {/* Table End Code .....  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManageTab;
