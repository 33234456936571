import { createSlice } from "@reduxjs/toolkit";
import {
  getAllServiceByAdmin,
  getAllServices,
  saveServicesByAdmin,
  editServicesByAdmin,
  getServiceDetails
} from "../actions/services";

export const serviceSlice = createSlice({
  name: "services",
  initialState: {
    loading: false,
    serviceList: [],
    serviceAdminList: [],
    currentService: {},
    totalCount: 0,
  },
  reducers: {
    setCurrentService: (state, action) => {
      state.currentService = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //getAllServices
      .addCase(getAllServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllServices.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllServices.rejected, (state) => {
        state.loading = false;
      })
      //getAllServiceByAdmin
      .addCase(getAllServiceByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllServiceByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceAdminList = [...action.payload.data.result];
        state.totalCount = action.payload.data.totalDocument;
      })
      .addCase(getAllServiceByAdmin.rejected, (state) => {
        state.loading = false;
      })
      //saveServicesByAdmin
      .addCase(saveServicesByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveServicesByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceAdminList = [
          ...state.serviceAdminList,
          action.payload.data.result,
        ];
      })
      .addCase(saveServicesByAdmin.rejected, (state) => {
        state.loading = false;
      })

      //editServicesByAdmin
      .addCase(editServicesByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(editServicesByAdmin.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editServicesByAdmin.rejected, (state) => {
        state.loading = false;
      })
      //getServiceDetails
      .addCase(getServiceDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServiceDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currentService = action.payload.data.result;
      })
      .addCase(getServiceDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentService } = serviceSlice.actions;
export default serviceSlice.reducer;
