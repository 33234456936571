import React, { useState } from "react";
import { Link } from "react-router-dom";

const TicketTab = () => {
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("all");

  const products = [
    {
      id: 1,
      name: "Rishi",
      email: "IDDY855hee_hw4",
      password: "Jio Prepaid",
      role: "₹4000.00",
      status: "20-04-2024",
      action: "completed",
    },
    {
        id: 1,
        name: "Rishi",
        email: "IDDY855hee_hw4",
        password: "Jio Prepaid",
        role: "₹4000.00",
        status: "20-04-2024",
        action: "completed",
      },
      {
        id: 1,
        name: "Rishi",
        email: "IDDY855hee_hw4",
        password: "Jio Prepaid",
        role: "₹4000.00",
        status: "20-04-2024",
        action: "completed",
      },
      {
        id: 1,
        name: "Rishi",
        email: "IDDY855hee_hw4",
        password: "Jio Prepaid",
        role: "₹4000.00",
        status: "20-04-2024",
        action: "completed",
      },
      {
        id: 1,
        name: "Rishi",
        email: "IDDY855hee_hw4",
        password: "Jio Prepaid",
        role: "₹4000.00",
        status: "20-04-2024",
        action: "completed",
      },
      {
        id: 1,
        name: "Rishi",
        email: "IDDY855hee_hw4",
        password: "Jio Prepaid",
        role: "₹4000.00",
        status: "20-04-2024",
        action: "completed",
      },
      {
        id: 1,
        name: "Rishi",
        email: "IDDY855hee_hw4",
        password: "Jio Prepaid",
        role: "₹4000.00",
        status: "20-04-2024",
        action: "completed",
      },
      {
        id: 1,
        name: "Rishi",
        email: "IDDY855hee_hw4",
        password: "Jio Prepaid",
        role: "₹4000.00",
        status: "20-04-2024",
        action: "completed",
      },
      {
        id: 1,
        name: "Rishi",
        email: "IDDY855hee_hw4",
        password: "Jio Prepaid",
        role: "₹4000.00",
        status: "20-04-2024",
        action: "completed",
      },
  ];
  const headers = [
    {
        complaintId: "Complaint Id",
        name: "Name",   
        subject: "Subject",
        date: "Date",
        status: "Status",
    },
  ];

  // Function to handle tab switching
  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the clicked tab as the active tab
  };

  return (
    <div>
      <div className="tab-container">
        <ul
          className="flex flex-wrap -mb-px text-sm font-medium text-center"
          role="tablist"
        >
          <li className="me-2" role="presentation">
            <button
              className={`tab-button ${
                activeTab === "all" ? "tab-button-active" : ""
              }`}
              id="all"
              type="button"
              onClick={() => handleTabClick("all")}
              aria-selected={activeTab === "all"}
            >
              All
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`tab-button ${
                activeTab === "completed" ? "tab-button-active" : ""
              }`}
              id="completed"
              type="button"
              onClick={() => handleTabClick("completed")}
              aria-selected={activeTab === "completed"}
            >
              Completed
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`tab-button ${
                activeTab === "pending" ? "tab-button-active" : ""
              }`}
              id="pending"
              type="button"
              onClick={() => handleTabClick("pending")}
              aria-selected={activeTab === "pending"}
            >
              Pending
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`tab-button ${
                activeTab === "rejected" ? "tab-button-active" : ""
              }`}
              id="rejected"
              type="button"
              onClick={() => handleTabClick("rejected")}
              aria-selected={activeTab === "rejected"}
            >
              Rejected
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`tab-button ${
                activeTab === "cancelled" ? "tab-button-active" : ""
              }`}
              id="cancelled"
              type="button"
              onClick={() => handleTabClick("cancelled")}
              aria-selected={activeTab === "cancelled"}
            >
              Cancelled
            </button>
          </li>
        </ul>
      </div>

      <div id="tab-content">
        <div
          // className={`tab-content ${
          //   activeTab === "all" ? "tab-content-active" : ""
          // }`}
          // id="all-content"
        >
          {/* Table Start Code.......  */}
          {/* <p>This is the content for the All tab.</p> */}

          <div className=" shadow-md sm:rounded-lg ">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                {headers.map((header, index) => (
                  <tr>
                    {/* <th scope="col" className="p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label for="checkbox-all-search" className="sr-only">
                          {header.checkbox}
                        </label>
                      </div>
                    </th> */}
                    <th scope="col" className="px-6 py-3">
                      {header.complaintId}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {header.name}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {header.subject}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {header.date}
                    </th>
                  
                    <th scope="col" className="px-6 py-3">
                      {header.status}
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody className="overflow-y-auto">
                {products.map((product, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    {/* <td className="w-4 p-4">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td> */}
                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                      {product.name}
                    </th>
                    <td className="px-6 py-4">{product.email}</td>
                    <td className="px-6 py-4">{product.password}</td>
                
                    <td className="px-6 py-4">{product.status}</td>
                    <td className="px-6 py-4 space-x-8">
                      <Link
                        to="#"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Edit
                      </Link>
                      <Link
                        to="#"
                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                      >
                        Block
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav
              className="flex items-center justify-between p-2"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  1-10
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  1000
                </span>
              </span>
              <ul className="inline-flex items-center -space-x-px text-sm h-8">
                <li>
                  <Link
                    to="#"
                    className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Previous
                  </Link>
                </li>
                {/* Add pagination dynamically */}
                {[1, 2, 3, 4, 5].map((page) => (
                  <li key={page}>
                    <Link
                      to="#"
                      className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                        page === 3
                          ? "text-blue-600 bg-blue-50 dark:bg-gray-700 dark:text-white"
                          : ""
                      }`}
                    >
                      {page}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link
                    to="#"
                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          {/* Table End Code .....  */}
        </div>
        {/* <div
          className={`tab-content ${
            activeTab === "active" ? "tab-content-active" : ""
          }`}
          id="active-content"
        >
          <p>This is the content for the Active tab.</p>
        </div>
        <div
          className={`tab-content ${
            activeTab === "inactive" ? "tab-content-active" : ""
          }`}
          id="inactive-content"
        >
          <p>This is the content for the Inactive tab.</p>
        </div> */}
      </div>
    </div>
  );
};

export default TicketTab;
