import React, { useEffect, useState } from "react";
import AdminSidebar from "./../adminSidebar/AdminSidebar";
import { IoWalletOutline } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAuth } from "../../store/actions/users";

const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [extWallet, setExtWallet] = useState(0);
  const [wallet, setWallet] = useState(0);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const users = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(isAuth());
  }, []);

  useEffect(() => {
    if (users.data) {
      setWallet(users.data.wallet);
    }
  }, [users.data]);
  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen">
      {/* Navbar */}
      <nav className="fixed top-0 z-50 w-full bg-teal-400 border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 shadow-md">
        <div className="px-4 py-1 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={toggleSidebar}
                type="button"
                className="inline-flex items-center p-2 text-sm text-white rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <Link to="#" className="flex items-center ms-2 md:me-24">
                <span className="self-center text-xl font-semibold text-white md:text-lg sm:text-base whitespace-nowrap dark:text-white">
                  91Sim
                </span>
              </Link>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ms-3 gap-8">
                <div className="admin-headerOne-icons flex flex-row items-center justify-center">
                  {/* <abbr title="Api Amount" className="no-underline">
                    <h2 className="text-sm md:font-base text-black text-center bg-blue-100 px-2 rounded-l-lg">
                      {extWallet}
                    </h2>
                  </abbr> */}
                  <IoWalletOutline className="text-4xl text-white bg-blue-400 px-0 py-2 rounded-lg" />
                  <abbr title="Current Wallet Amount" className="no-underline">
                    <h2 className="text-sm md:font-base text-black text-center bg-blue-100 px-2 rounded-r-lg">
                      {wallet?.amount}
                    </h2>
                  </abbr>
                </div>

                <div className="flex items-center space-x-6">
                  <Link to="/admin">
                    <IoMdNotificationsOutline className="text-white text-2xl font-bold hover:text-blue-200 transition-colors" />
                  </Link>
                  <Link to="/admin">
                    <CgProfile className="text-white text-2xl font-bold  hover:text-blue-200 transition-colors" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar */}
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <AdminSidebar />
      </aside>

      {/* Main Content */}
      <div className=" sm:ml-64 mt-12 h-[calc(100vh-10px)] ">
        <div className="admin-layout-body overflow-x-auto"> {children} </div>
        {/* Add your dashboard content here */}
      </div>
    </div>
  );
};

export default AdminLayout;
