import React, { useEffect, useState } from "react";
import moment from "moment";
import { getAllTransectionByAdmin } from "../../../store/actions/transection";
import { useDispatch, useSelector } from "react-redux";
import AdminLedgerHeader from "./AdminLedgerHeader";
import { Loader } from "../../../utills/tools";

const AdminLedgerTab = () => {
  const dispatch = useDispatch();
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [transectionList, setTransectionList] = useState([]);
  const [filterQuery, setFilterQuery] = useState({
    limit: "10",
    page: "1",
    trans_type: ["ADD_MONEY", "WITHDRAW", "PaymentRequest", "ADD_MONEY_USER"],
  });
  //redux data
  const ledgers = useSelector((state) => state.transection);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(ledgers.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  useEffect(() => {
    dispatch(getAllTransectionByAdmin(filterQuery));
  }, [filterQuery, setFilterQuery]);

  useEffect(() => {
    setTransectionList(ledgers.transectionAdminList);
  }, [ledgers]);

  const headers = [
    { keys: "S.No" },
    { keys: "User" },
    { keys: "Nature" },
    { keys: "op. bal." },
    { keys: "Amount" },
    { keys: "cl. bal." },
    { keys: "Date" },
    { keys: "Status" },
  ];

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  // Function to handle tab switching
  const handleTabClick = (tab) => {
    let query = { ...filterQuery };
    setActiveTab(tab); // Set the clicked tab as the active tab
    if (tab === "addMoney") {
      query.trans_type = "ADD_MONEY";
    } else if (tab === "withdraw") {
      query.trans_type = "WITHDRAW";
    } else if (tab === "PaymentRequest") {
      query.trans_type = "PaymentRequest";
    } else if (tab === "addmoneyuser") {
      query.trans_type = "ADD_MONEY_USER";
    } else {
      delete query.trans_type;
    }
    setFilterQuery(query);
  };

  return (
    <>
      <div className="flex flex-col relative ml-2">
        <div className="fixed w-full max-w-[calc(100%-17rem)] z-10 ">
          <AdminLedgerHeader onSearch={setFilterQuery} />
        </div>
        <div className="mt-14">
          <div className="flex flex-row justify-between gap-14 w-full max-w-[calc(100%-17rem)] items-center text-center tab-container fixed z-9 bg-white">
            <h2 className="text-lg font-medium mx-2">Payment Request</h2>
            <ul
              className="flex flex-wrap -mb-px text-sm font-medium text-center"
              role="tablist"
            >
              <li className="me-2" role="presentation">
                <button
                  className={`tab-button ${
                    activeTab === "all" ? "tab-button-active" : ""
                  }`}
                  id="all"
                  type="button"
                  onClick={() => handleTabClick("all")}
                  aria-selected={activeTab === "all"}
                >
                  All
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`tab-button ${
                    activeTab === "addMoney" ? "tab-button-active" : ""
                  }`}
                  id="addMoney"
                  type="button"
                  onClick={() => handleTabClick("addMoney")}
                  aria-selected={activeTab === "addMoney"}
                >
                  Add Money
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`tab-button ${
                    activeTab === "withdraw" ? "tab-button-active" : ""
                  }`}
                  id="withdraw"
                  type="button"
                  onClick={() => handleTabClick("withdraw")}
                  aria-selected={activeTab === "withdraw"}
                >
                  withdraw
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`tab-button ${
                    activeTab === "PaymentRequest" ? "tab-button-active" : ""
                  }`}
                  id="PaymentRequest"
                  type="button"
                  onClick={() => handleTabClick("PaymentRequest")}
                  aria-selected={activeTab === "PaymentRequest"}
                >
                  Payment Request
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`tab-button ${
                    activeTab === "addmoneyuser" ? "tab-button-active" : ""
                  }`}
                  id="addmoneyuser"
                  type="button"
                  onClick={() => handleTabClick("addmoneyuser")}
                  aria-selected={activeTab === "addmoneyuser"}
                >
                  Add Money User
                </button>
              </li>
            </ul>
          </div>
          <div id="tab-content">
            <div className=" shadow-md sm:rounded-lg mt-14 ">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="p-2">
                      <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="checkbox-all-search"
                          className="sr-only"
                        >
                          Checkbox
                        </label>
                      </div>
                    </th>
                    {headers.map((header, index) => (
                      <th scope="col" key={index} className="px-2 py-3">
                        {header.keys}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="overflow-y-auto">
                  {ledgers && ledgers.loading ? (
                    <Loader />
                  ) : (
                    transectionList &&
                    transectionList.map((transection, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="w-4 p-4">
                          <input
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <th className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                          {indexOfFirstRow + index + 1}
                        </th>

                        <td className="px-2 py-2">
                          {transection?.userId?.name} <br />
                          {transection?.userId?.email}
                        </td>
                        <td className="px-2 py-2">
                          {transection?.trans_type} |{" "}
                          {transection?.trans_status}
                          <br />
                          {transection?.transId}
                        </td>
                        <td className="px-2 py-2">
                          {transection?.opening_balance}
                        </td>
                        <td className="px-2 py-2">{transection?.txn_amount}</td>
                        <td className="px-2 py-2">
                          {transection?.closing_balance}
                        </td>
                        <td className="px-2 py-2">
                          {moment(new Date(transection.createdAt)).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        <td className="px-2 py-2">
                          {transection?.status == 1 ? "Active" : "Inactive"}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <nav
                className="flex items-center justify-between p-2"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Showing{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {indexOfFirstRow + 1} -{" "}
                    {Math.min(indexOfLastRow, ledgers.totalCount)}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {ledgers.totalCount}
                  </span>
                </span>

                <ul className="inline-flex items-center -space-x-px text-sm h-8">
                  {/* Previous Button */}
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handlePrev}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>

                  {/* Page Buttons */}
                  {currentPage > 2 && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(1)}
                      >
                        1
                      </button>
                    </li>
                  )}

                  {currentPage > 3 && <li className="px-2">...</li>}

                  {/* Previous Page */}
                  {currentPage > 1 && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(currentPage - 1)}
                      >
                        {currentPage - 1}
                      </button>
                    </li>
                  )}

                  {/* Current Page */}
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-blue-600 bg-blue-50 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(currentPage)}
                    >
                      {currentPage}
                    </button>
                  </li>

                  {/* Next Page */}
                  {currentPage < totalPages && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(currentPage + 1)}
                      >
                        {currentPage + 1}
                      </button>
                    </li>
                  )}

                  {currentPage < totalPages - 2 && (
                    <li className="px-2">...</li>
                  )}

                  {currentPage < totalPages - 1 && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(totalPages)}
                      >
                        {totalPages}
                      </button>
                    </li>
                  )}

                  {/* Next Button */}
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handleNext}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            {/* Table End Code .....  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLedgerTab;
