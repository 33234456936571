import React, { useEffect, useState } from "react";
import "./Api.css";
import { toast, ToastContainer } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiCopyAlt } from "react-icons/bi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getAllAPI } from "../../store/actions/apis";
import { Loader } from "../../utills/tools";
import { updateUserApiKey } from "../../store/actions/users";
const Api = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  //redux logic
  const apis = useSelector((state) => state.api);
  const users = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getAllAPI());
  }, []);

  useEffect(() => {
    setCurrentUser(users.data);
  }, [users]);
  useEffect(() => {
    setApiData(apis.apisList);
  }, [apis]);

  const handleChangeAPiKey = () => {
    dispatch(updateUserApiKey());
    toast.success("Api key updated successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 2000,
    });
  };

  // copy api key ....
  const handleCopyKey = () => {
    // Show success toast after copying
    toast.success("Copied 🤟!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
    });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        className="custom-toast"
      />

      <div className="api-api">
        <div className="api-main">
          <h2 className="dark:text-textW">{currentUser?.apiKey}</h2>
          <CopyToClipboard text={currentUser?.apiKey}>
            <button className="api-key" onClick={handleCopyKey}>
              <h2>Copy Key</h2>
              <BiCopyAlt />
            </button>
          </CopyToClipboard>
        </div>
        <div className="parent-container">
          <button
            className="api-main-two bg-buttonBg hover:bg-buttonBgH"
            onClick={handleChangeAPiKey}
          >
            <h4>Generate a new key</h4>
            <AiOutlineFilePdf />
          </button>
        </div>
        <div className="api-last">
          <div className="api-method">
            <h2 className="dark:text-textW">API URL: https://example.com</h2>
            <h3 className="dark:text-textW">
              valid requests: <span>GET</span> - <span>POST</span>
            </h3>
            <h3 className="dark:text-textW">
              In all requests must contain an API key in the form, as parameter
              "api_key"
            </h3>
          </div>
          {apis && apis.loading ? (
            <Loader />
          ) : (
            <div className="faq-container">
              {/* <h2>Frequently Asked Questions</h2> */}
              {apiData &&
                apiData.map((api, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className="faq-question"
                      onClick={() => toggleFaq(index)}
                    >
                      {api.name}
                      <span>{activeIndex === index ? "-" : "+"}</span>
                    </div>
                    <div
                      className={`faq-answer ${
                        activeIndex === index ? "open" : ""
                      }`}
                    >
                      {api.desc}
                      <br />
                      {api.url}
                      <br />
                      {api.parameter}
                      <br />
                      {api.body}
                      <br />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Api;
