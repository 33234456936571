import React, { useEffect, useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../store/actions/auth";
import { isAuth } from "../../../store/actions/users";
import PreventSignIn from "../../../hoc/preventSignIn";
import { Loader } from "../../../utills/tools";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const users = useSelector((state) => state.auth);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Example validation check
    if (email === "" || password === "") {
      toast.error("Email and Password are required!", {
        position: "top-right", // Use string instead of POSITION.TOP_RIGHT
        autoClose: 2000,
      });
    } else {
      dispatch(login({ userName: email, password }))
        .then((result) => {
          if (!result?.payload?.success) {
            toast.error(result?.payload?.message ||"Unable to login!,try again...", {
              position: "top-right", // Use string instead of POSITION.TOP_RIGHT
              autoClose: 600,
              onClose: () => {
                navigate("/");
              },
            });
          } else {
            toast.success("Login successful!", {
              position: "top-right", // Use string instead of POSITION.TOP_RIGHT
              autoClose: 600,
            });

            if (result?.payload?.user?.roleId === "1") {
              localStorage.setItem("role", "1");
              dispatch(isAuth());
              navigate("/user");
            } else {
              localStorage.setItem("role", "0");
              navigate("/admin");
            }
          }
        })
        .catch((err) => {
          toast.success("Unable to login Currently,Try again later!", {
            position: "top-right", // Use string instead of POSITION.TOP_RIGHT
            autoClose: 600,
            onClose: () => {
              navigate("/");
            },
          });
          // Handle unexpected errors (rare case)
          console.log("login test err ", err);
        });
    }
  };

  return (
    <>
      {users.loading ? (
        <div className="flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <PreventSignIn users={users}>
          <div className="login-page">
            <h1 className="login-title">Login</h1>
            <div className="login-container">
              <div className="login-text">
                <h5>Welcome Back!</h5>
                <h1>Enter your Log in details</h1>
              </div>
              <form className="login-form" onSubmit={handleSubmit}>
                <div className="login-input">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="login-input password-input">
                  <label htmlFor="password">Password</label>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <Link to="/forgotPassword">
                  <h4>Forgot Password?</h4>
                </Link>
                <button type="submit" className="login-button">
                  Login
                </button>
              </form>
              <div className="login-new-account">
                <h4>New user?</h4>
                <Link to="/register">
                  <h3>Create new account</h3>
                </Link>
              </div>
            </div>

            {/* ToastContainer to display toast notifications */}
            <ToastContainer className="custom-toast" />
          </div>
        </PreventSignIn>
      )}
    </>
  );
};

export default Login;
