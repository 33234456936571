import React from "react";
import ServiceHeader from "./ServiceHeader";
import ServiceTab from "./ServiceTab";

const Services = () => {
  return (
    <>
      
          <ServiceTab />
       
    </>
  );
};

export default Services;
