import axios from "axios";
import { errorGlobal, successGlobal } from "../reducers/notification";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthHeader } from "../../utills/tools";

export const saveProvider = createAsyncThunk(
  "admin/provider/save",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/provider/new`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const editProvider = createAsyncThunk(
  "admin/provider/edit",
  async (data, { dispatch }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/provider/${data.id}`,
        data.data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getProviderDetails = createAsyncThunk(
  "admin/provider/details",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/provider/${id}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getAllprovider = createAsyncThunk(
  "admin/provider/all",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/provider/all`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);
export const updatePriceAndUnits = createAsyncThunk(
  "admin/provider/updateExt",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/provider/updateExt`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);
