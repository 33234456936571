import axios from "axios";
import { errorGlobal, successGlobal } from "../reducers/notification";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthHeader } from "../../utills/tools";

export const isAuth = createAsyncThunk(
  "users/isAuth",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/me`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return { data: request.data.user[0] };
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return rejectWithValue(error.response.data || "Login failed");
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "users/updateUserProfile",
  async (data, { dispatch }) => {
    try {
      const profile = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/me/update`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(`profile updated!`));
      return {
        name: profile.name,
        email: profile.email,
        roleId: profile.roleId,
      };
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      throw error;
    }
  }
);

export const updateUserApiKey = createAsyncThunk(
  "users/updateUserApiKey",
  async (_, { dispatch }) => {
    try {
      const profile = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/me/newApiKey`,
        getAuthHeader()
      );
      dispatch(successGlobal(`Profile Api updated!`));
      return profile.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      throw error;
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  "users/resetUserPassword",
  async (data, { dispatch }) => {
    try {
      const profile = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/password/update`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(`profile updated!`));
      return {
        name: profile.name,
        email: profile.email,
        roleId: profile.roleId,
      };
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      throw error;
    }
  }
);

export const adminGetAllUser = createAsyncThunk(
  "admin/getAllUser",
  async (data, { dispatch }) => {
    try {
      const request = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/admin/users`,
        data,
        getAuthHeader()
      );
      return request.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      throw error;
    }
  }
);

export const adminEditUser = createAsyncThunk(
  "admin/updateUser",
  async (data, { dispatch }) => {
    try {
      const request = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/admin/user/${data.id}`,
        data.data,
        getAuthHeader()
      );
      return request.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      throw error;
    }
  }
);

export const adminGetUserDetails = createAsyncThunk(
  "admin/userDetails",
  async (id, { dispatch }) => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/admin/user/${id}`,
        getAuthHeader()
      );
      return request.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      throw error;
    }
  }
);
export const updateRegistrationSetting = createAsyncThunk(
  "admin/user/setting",
  async (data, { dispatch }) => {
    try {
      const request = await axios.put(
        `${process.env.REACT_APP_API_URL}/setting/setting`,
        data,
        getAuthHeader()
      );
      return request.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      throw error;
    }
  }
);
export const savePaymentSettings = createAsyncThunk(
  "admin/payment/setting",
  async (data, { dispatch }) => {
    try {
      const request = await axios.post(
        `${process.env.REACT_APP_API_URL}/setting/savePayment`,
        data,
        getAuthHeader()
      );
      return request.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      throw error;
    }
  }
);
export const getPaymentSettings = createAsyncThunk(
  "admin/payment/setting/all",
  async (_, { dispatch }) => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}/setting/all?name=paymentSettings`,
        getAuthHeader()
      );
      return request.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      throw error;
    }
  }
);

export const getUserWalletAmount = createAsyncThunk(
  "admin/user/wallet/amount",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}/wallet/admin/totalUserWallet`,
        getAuthHeader()
      );
      return request.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Login failed");
    }
  }
);
