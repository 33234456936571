import React from "react";
import UserManageTab from "./userManageTab/UserManageTab";

const UserManagement = () => {
  return (
    <>
      <div>
        <UserManageTab />
      </div>
    </>
  );
};

export default UserManagement;
