import axios from "axios";
import { errorGlobal, successGlobal } from "../reducers/notification";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthHeader } from "../../utills/tools";

export const getAllUserOrders = createAsyncThunk(
  "user/order/me",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/me`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getAllActiveUserOrders = createAsyncThunk(
  "user/order/all/active",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/me/active`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const saveOrder = createAsyncThunk(
  "user/order/save",
  async (data, { dispatch ,rejectWithValue}) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/new`,
        data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return rejectWithValue(error.response.data||"Order failed");
    }
  }
);

export const editOrder = createAsyncThunk(
  "admin/order/edit",
  async (data, { dispatch }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/order/${data.id}`,
        data.data,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "admin/order/cancel",
  async (id, { dispatch }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/order/cancel/${id}`,
        {},
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  "user/order/details",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/${id}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const checkOrderStatus = createAsyncThunk(
  "user/order/extStatus",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/status/${id}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

export const getExternalBalance = createAsyncThunk(
  "user/order/sms",
  async (_, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/apiBalance`,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);

//admin api list
export const getAllOrders = createAsyncThunk(
  "admin/order/all",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/all`,
        data,
        getAuthHeader()
      );
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);
