import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {

    const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (email === "" || changePassword === "" || confirmPassword === "") {
      toast.error("Please fill in all fields!", {
        position: "top-right",
        autoClose: 1000,
      });
      return;
    }

    if (changePassword !== confirmPassword) {
      toast.error("Passwords do not match!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    // Simulate a successful password change
    toast.success("Password changed successfully!", {
      position: "top-right",
      autoClose: 600,
      onClose: () => navigate("/"),
    });
    
    // Reset the form (optional)
    setEmail("");
    setChangePassword("");
    setConfirmPassword("");
  };

  return (
    <div className="login-page">
      <h1 className="login-title">Forgot Password</h1>
      <div className="login-container">
        <div className="login-text">
          <h5>Welcome Back!</h5>
          <h1>Enter your forgot password details</h1>
        </div>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-input">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="login-input password-input">
            <label htmlFor="changePassword">Change Password</label>
            <input
              type={passwordVisible ? "text" : "password"}
              id="changePassword"
              placeholder="Enter new password"
              value={changePassword}
              onChange={(e) => setChangePassword(e.target.value)}
              required
            />
            <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <div className="login-input password-input">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type={passwordVisible ? "text" : "password"}
              id="confirmPassword"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <button type="submit" className="login-button">
            Submit
          </button>
        </form>
        <div className="login-new-account">
          <h4>Go Back?</h4>
          <Link to="/">
            <h3>Click</h3>
          </Link>
        </div>
      </div>

      {/* ToastContainer to display toast notifications */}
      <ToastContainer   className="custom-toast" />
    </div>
  );
};

export default ForgotPassword;
