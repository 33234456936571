import React from "react";
import { IoSearch } from "react-icons/io5";
import { MdFilterList } from "react-icons/md";
import { TiExportOutline } from "react-icons/ti";

const TicketHeader = () => {
  return (
    <>
      <div className="header-hederTwo">
        {" "}
        <div className="admin-headerTwo">
          <div className="admin-headerTwo-search">
            <IoSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search..."
              className="search-input"
            />
          </div>
          <div className="admin-headerTwo-actions">
            <button className="action-btn">
              <MdFilterList /> Filter
            </button>
            <button className="action-btn">
              <TiExportOutline />
              Export
            </button>
            {/* <button className="action-btn add-new">
              <IoMdAdd /> Add New
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketHeader;
