import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { RiWallet3Line } from "react-icons/ri";
import { FaArrowUpLong } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { getOrderReport, getUserReport } from "../../store/actions/wallets";
import moment from "moment";
import { adminEditUser } from "../../store/actions/users";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  const dispatch = useDispatch();

  const userHeader = [
    { key: "user" },
    { key: "Total Order" },
    { key: "Total Success" },
    { key: "Total cancel" },
    { key: "Last Login" },
    { key: "Status" },
    { key: "Action" },
  ];

  const [userProducts, setUserProducts] = useState([]);
  const [orderReport, setOrderReport] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItems = userProducts.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const crrmonth = moment().month() + 1;
  const crrDay = moment().date();
  const crrYear = moment().year();

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const getUserReportList = () => {
    dispatch(getUserReport())
      .then((result) => {
        if (!result?.payload?.success) {
          setUserProducts(result?.payload?.data);
        }
      })
      .catch((err) => {
        // Handle unexpected errors (rare case)
        console.log("User Report not fetched ", err);
      });
  };

  useEffect(() => {
    getUserReportList();
  }, []);

  useEffect(() => {
    dispatch(getOrderReport())
      .then((result) => {
        if (!result?.payload?.success) {
          setOrderReport(result?.payload?.data);
        }
      })
      .catch((err) => {
        // Handle unexpected errors (rare case)
        console.log("User Report not fetched ", err);
      });
  }, [dispatch]);

  const MonthReport = orderReport.find(
    (obj) => obj.types == "Months" && obj.month == crrmonth
  );
  const DailyReport = orderReport.find(
    (obj) => obj.types == "Days" && obj.days == crrDay
  );
  const YearlyReport = orderReport.find(
    (obj) => obj.types == "Months" && obj.year == crrYear
  );
  const currentItems = userProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handleBlockUser = (id) => {
    dispatch(adminEditUser({ id: id, data: { status: 0 } }));
    // Show a success toast message
    toast.success("Block user successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
      onClose: () => getUserReportList(),
    });
  };
  const handleUnblockUser = (id) => {
    dispatch(adminEditUser({ id: id, data: { status: 1 } }));
    toast.success("Unblock user successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
      onClose: () => getUserReportList(),
    });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div>
        <div className="p-4 ">
          <div className="flex flex-col lg:flex-row justify-center items-center lg:my-8 gap-4">
            <div className="bg-white p-4 rounded-lg shadow-lg w-full lg:w-1/3">
              <div className="flex items-center lg:flex-row justify-between gap-2">
                <h4 className="text-black font-semibold text-lg">
                  Today's Report
                </h4>
              </div>
              <div className="flex items-center gap-1">
                <h2>Total Order</h2>
                {DailyReport?.totalOrder}
              </div>
              <div className="flex items-center gap-1">
                <h2>Total Success</h2>
                {DailyReport?.totalSuccess}
              </div>
              <div className="flex items-center gap-1">
                <h2>Total Profit</h2>
                {DailyReport?.totalProfit}
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-lg w-full lg:w-1/3">
              <div className="flex items-center lg:flex-row justify-between gap-2">
                <h4 className="text-black font-semibold text-lg">
                  Monthly Report
                </h4>
              </div>
              <div className="flex items-center gap-1">
                <h2>Total Order</h2>
                {MonthReport?.totalOrder}
              </div>
              <div className="flex items-center gap-1">
                <h2>Total Success</h2>
                {MonthReport?.totalSuccess}
              </div>
              <div className="flex items-center gap-1">
                <h2>Total Profit</h2>
                {MonthReport?.totalProfit}
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-lg w-full lg:w-1/3">
              <div className="flex items-center lg:flex-row justify-between gap-2">
                <h4 className="text-black font-semibold text-lg">
                  Yearly Report
                </h4>
              </div>
              <div className="flex items-center gap-1">
                <h2>Total Order</h2>
                {YearlyReport?.totalOrder}
              </div>
              <div className="flex items-center gap-1">
                <h2>Total Success</h2>
                {YearlyReport?.totalSuccess}
              </div>
              <div className="flex items-center gap-1">
                <h2>Total Profit</h2>
                {YearlyReport?.totalProfit}
              </div>
            </div>
          </div>
        </div>
        <div className="relative bg-card overflow-x-auto shadow-md sm:rounded-lg m-2">
          {/* {loading ? (
        <div className="text-center text-gray-500">Loading...</div>
      ) : totalItems === 0 ? (
        <div className="text-center text-gray-500">No products found</div>
      ) : ( */}
          <>
            <table className="w-full text-sm text-left rtl:text-right bg-card text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-text uppercase bg-cardG">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        aria-label="Select all products"
                      />
                    </div>
                  </th>
                  {userHeader.map((header, index) => (
                    <th key={index} scope="col" className="px-6 py-3">
                      {header.label || header.key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((product, index) => (
                  <tr
                    key={index}
                    className="bg-card border-b dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                          aria-label={`Select product ${product._id}`}
                        />
                      </div>
                    </td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      {product?.userId?.name}
                      <br />
                      {product?.userId?.email}
                    </th>
                    <td className="px-6 py-4">{product.totalOrder}</td>
                    <td className="px-6 py-4">{product.successOrder}</td>
                    <td className="px-6 py-4">{product.cancelOrder}</td>
                    <td className="px-6 py-4">
                      {moment(new Date(product.updatedAt)).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </td>
                    <td className="px-6 py-4">
                      {product?.userId?.status ? "Active" : "Inactive"}
                    </td>
                    <td className="px-6 py-4 gap-4 flex">
                      {product?.userId?.status ? (
                        <button
                          className="font-medium text-blue-600 border border-blue-600 px-2 rounded-lg hover:text-white hover:bg-blue-600"
                          onClick={() => handleBlockUser(product?.userId?._id)}
                        >
                          Block
                        </button>
                      ) : (
                        <button
                          className="font-medium text-blue-600 border border-blue-600 px-2 rounded-lg hover:text-white hover:bg-blue-600"
                          onClick={() =>
                            handleUnblockUser(product?.userId?._id)
                          }
                        >
                          Unblock
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav
              className="flex items-center justify-between m-4"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-text">
                  {(currentPage - 1) * itemsPerPage + 1} -{" "}
                  {Math.min(currentPage * itemsPerPage, totalItems)}
                </span>{" "}
                of <span className="font-semibold text-text">{totalItems}</span>
              </span>
              <ul className="inline-flex -space-x-px text-sm h-8">
                {/* Previous Button */}
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700"
                    aria-disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {/* First Page */}
                <li>
                  <button
                    onClick={() => handlePageChange(1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === 1
                        ? "text-white bg-blue-500"
                        : "text-gray-500 bg-white"
                    } border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700`}
                  >
                    1
                  </button>
                </li>

                {/* Second Page */}
                {totalPages > 1 && (
                  <li>
                    <button
                      onClick={() => handlePageChange(2)}
                      className={`flex items-center justify-center px-3 h-8 ${
                        currentPage === 2
                          ? "text-white bg-blue-500"
                          : "text-gray-500 bg-white"
                      } border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700`}
                    >
                      2
                    </button>
                  </li>
                )}

                {/* Ellipsis for skipped pages */}
                {currentPage > 3 && <span className="px-2">...</span>}

                {/* Last two pages */}
                {currentPage < totalPages - 2 && (
                  <span className="px-2">...</span>
                )}
                {totalPages > 2 && (
                  <li>
                    <button
                      onClick={() => handlePageChange(totalPages - 1)}
                      className={`flex items-center justify-center px-3 h-8 ${
                        currentPage === totalPages - 1
                          ? "text-white bg-blue-500"
                          : "text-gray-500 bg-white"
                      } border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700`}
                    >
                      {totalPages - 1}
                    </button>
                  </li>
                )}
                {totalPages > 1 && (
                  <li>
                    <button
                      onClick={() => handlePageChange(totalPages)}
                      className={`flex items-center justify-center px-3 h-8 ${
                        currentPage === totalPages
                          ? "text-white bg-blue-500"
                          : "text-gray-500 bg-white"
                      } border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700`}
                    >
                      {totalPages}
                    </button>
                  </li>
                )}

                {/* Next Button */}
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700"
                    aria-disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
