import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approvePaymentRequestByAdmin,
  editPaymentRequestByAdmin,
  getAllPaymentRequestByAdmin,
} from "../../../store/actions/paymentRequest";
import { IoClose } from "react-icons/io5";
import PaymentHeader from "./PaymentHeader";
import { Loader } from "../../../utills/tools";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentTab = () => {
  const dispatch = useDispatch();
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [filterQuery, setFilterQuery] = useState({
    limit: "10",
    page: "1",
  });
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [isModalProcess, setIsModalProcess] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  //redux data
  const paymentRequest = useSelector((state) => state.paymentRequest);

  const rowsPerPage = filterQuery.limit;
  const totalPages = Math.ceil(paymentRequest.totalCount / rowsPerPage);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const HandleGetAllPaymentRequest = () => {
    dispatch(getAllPaymentRequestByAdmin(filterQuery));
  };

  useEffect(() => {
    HandleGetAllPaymentRequest();
  }, [filterQuery, setFilterQuery]);

  useEffect(() => {
    setProducts(paymentRequest.adminPayRequestList);
  }, [paymentRequest]);

  const headers = [
    { keys: "S.No" },
    { keys: "Name" },
    { keys: "Transection Id" },
    { keys: "Amount" },
    { keys: "Remarks" },
    { keys: "ScreenShot" },
    { keys: "Status" },
    { keys: "Created on" },
    { keys: "Action" },
  ];

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilterQuery({ ...filterQuery, page: currentPage + 1 }); // Update filter query
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilterQuery({ ...filterQuery, page: currentPage - 1 }); // Update filter query
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterQuery({ ...filterQuery, page: pageNumber }); // Update filter query
  };

  // Function to handle tab switching
  const handleTabClick = (tab) => {
    let query = { ...filterQuery };
    setActiveTab(tab); // Set the clicked tab as the active tab
    if (tab === "approved") {
      query.payment_status = "APPROVED";
    } else if (tab === "pending") {
      query.payment_status = "PENDING";
    } else if (tab === "rejected") {
      query.payment_status = "REJECTED";
    } else {
      delete query.payment_status;
    }
    setFilterQuery(query);
  };

  const toggleModalProcess = (item) => {
    setIsModalProcess(true);
    setCurrentItems(item);
  };
  const toggleModalProcessClose = () => {
    setIsModalProcess(false);
    setCurrentItems(null);
  };

  const handleProcessPayment = (e) => {
    e.preventDefault();
    dispatch(
      approvePaymentRequestByAdmin({
        id: currentItems._id,
        data: {
          payment_status: e.target.status.value,
          admin_remarks: e.target.remarks.value,
        },
      })
    );
    setIsModalProcess(false);
    setCurrentItems(null);
    toast.success("payment Processed successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
      onClose: () => {
        HandleGetAllPaymentRequest();
      },
    });
  };

  const toggleModalEdit = (payRequest) => {
    setCurrentItems(payRequest);
    setIsModalEdit(true);
  };
  const toggleModalEditClose = () => {
    setIsModalEdit(false);
    setCurrentItems(null);
  };
  const handleEdit = (e) => {
    e.preventDefault();
    const dataVal = {
      payment_status: e.target.paymentStatus.value,
      amount: e.target.amount.value,
      transectionId: e.target.transectionId.value,
      remarks: e.target.remarks.value,
    };
    dispatch(
      editPaymentRequestByAdmin({
        id: currentItems._id,
        data: dataVal,
      })
    );
    setIsModalEdit(false);
    setCurrentItems(null);
    toast.success("Edit payment successfully!", {
      position: "top-right", // Use string instead of POSITION.TOP_RIGHT
      autoClose: 600,
      onClose: () => {
        HandleGetAllPaymentRequest();
      },
    });
  };

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage("");
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex flex-col relative ml-2">
        <div className="fixed w-full max-w-[calc(100%-17rem)] z-10 ">
          <PaymentHeader onSearch={setFilterQuery} />
        </div>
        <div className="mt-14">
          <div className="flex flex-row justify-between gap-14 w-full max-w-[calc(100%-17rem)] items-center text-center tab-container fixed z-9 bg-white">
            <h2 className="text-lg font-medium mx-2">Payment Request</h2>
            <ul
              className="flex flex-wrap -mb-px text-sm font-medium text-center"
              role="tablist"
            >
              <li className="me-2" role="presentation">
                <button
                  className={`tab-button ${
                    activeTab === "all" ? "tab-button-active" : ""
                  }`}
                  id="all"
                  type="button"
                  onClick={() => handleTabClick("all")}
                  aria-selected={activeTab === "all"}
                >
                  All
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`tab-button ${
                    activeTab === "approved" ? "tab-button-active" : ""
                  }`}
                  id="approved"
                  type="button"
                  onClick={() => handleTabClick("approved")}
                  aria-selected={activeTab === "approved"}
                >
                  Approved
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`tab-button ${
                    activeTab === "pending" ? "tab-button-active" : ""
                  }`}
                  id="pending"
                  type="button"
                  onClick={() => handleTabClick("pending")}
                  aria-selected={activeTab === "pending"}
                >
                  Pending
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`tab-button ${
                    activeTab === "rejected" ? "tab-button-active" : ""
                  }`}
                  id="rejected"
                  type="button"
                  onClick={() => handleTabClick("rejected")}
                  aria-selected={activeTab === "rejected"}
                >
                  Rejected
                </button>
              </li>
            </ul>
          </div>
          <div id="tab-content">
            <div className=" shadow-md sm:rounded-lg mt-14 ">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8 ">
                <thead className="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="p-2">
                      <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="checkbox-all-search"
                          className="sr-only"
                        >
                          Checkbox
                        </label>
                      </div>
                    </th>
                    {headers.map((header, index) => (
                      <th scope="col" key={index} className="px-2 py-3">
                        {header.keys}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="overflow-y-auto">
                  {paymentRequest && paymentRequest.loading ? (
                    <Loader />
                  ) : (
                    products &&
                    products.map((payRequest, index) => (
                      <tr
                        key={payRequest?._id}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="w-2 p-2 text-left">
                          {" "}
                          {/* Centered checkbox */}
                          <input
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <th className="px-2 py-2 font-medium text-gray-900 dark:text-white text-left">
                          {" "}
                          {/* Centered index */}
                          {indexOfFirstRow + index + 1}
                        </th>
                        <th className="px-2 py-2 font-medium text-gray-900 dark:text-white text-left">
                          {" "}
                          {/* Centered user info */}
                          {payRequest?.userId?.name}
                          <br />
                          {payRequest?.userId?.email}
                        </th>
                        <td className="px-2 py-2 text-left">
                          {payRequest?.transectionId}
                        </td>{" "}
                        {/* Centered transaction ID */}
                        <td className="px-2 py-2 text-left">
                          {payRequest?.amount}
                        </td>{" "}
                        {/* Centered amount */}
                        <td className="px-2 py-2 text-left">
                          {payRequest?.admin_remarks}
                        </td>{" "}
                        <td className="px-2 py-2 text-left group relative">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}/${payRequest?.txnScreenShot}`}
                            alt=""
                            className="h-6 w-6 transition-transform duration-200 ease-in-out group-hover:fixed group-hover:top-1/2 group-hover:left-1/2 group-hover:transform group-hover:-translate-x-1/2 group-hover:-translate-y-1/2 group-hover:h-[400px] group-hover:w-[400px]"
                          />
                        </td>{" "}
                        {/* Centered remarks */}
                        <td className="px-2 py-2 text-left">
                          {payRequest?.payment_status}
                        </td>{" "}
                        {/* Centered payment status */}
                        <td className="px-2 py-2 text-left">
                          {new Date(payRequest?.requested_date).toDateString()}
                        </td>{" "}
                        {/* Centered requested date */}
                        <td className="px-2 py-2 space-x-4 flex flex-row items-center mt-2 ">
                          {" "}
                          {/* Centered action buttons */}
                          <button
                            type="button"
                            onClick={() => toggleModalEdit(payRequest)}
                            className="font-medium text-white px-2 rounded bg-blue-600 hover:bg-blue-800"
                          >
                            Edit
                          </button>
                          {payRequest?.payment_status === "PENDING" && (
                            <button
                              onClick={() => toggleModalProcess(payRequest)}
                              className="font-medium text-yellow-500 dark:text-yellow-500 hover:underline"
                            >
                              Process
                            </button>
                          )}
                          {/* Edit Modal */}
                          {currentItems && isModalEdit && (
                            <div
                              id="crud-modal"
                              className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10"
                            >
                              <div className="relative p-4 w-full max-w-md h-auto">
                                {/* Modal Content */}
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                  {/* Modal Header */}
                                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                      Edit Payment Request
                                    </h3>
                                    <button
                                      type="button"
                                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                      onClick={toggleModalEditClose} // Correct function for toggling modal
                                    >
                                      <IoClose className="w-5 h-5" />
                                    </button>
                                  </div>

                                  {/* Modal Body */}
                                  <form
                                    className="p-4 md:p-5"
                                    onSubmit={handleEdit}
                                  >
                                    <div className="grid gap-4 mb-4 grid-cols-2">
                                      {/* Transaction ID */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="transactionId"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Transaction ID
                                        </label>
                                        <input
                                          type="text"
                                          id="transactionId"
                                          name="transectionId"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter Transaction ID"
                                          defaultValue={
                                            currentItems?.transectionId
                                          }
                                          required
                                        />
                                      </div>

                                      {/* Amount */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="amount"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          id="amount"
                                          name="amount"
                                          step="any"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter Amount"
                                          defaultValue={currentItems?.amount}
                                          required
                                        />
                                      </div>

                                      {/* Remarks */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="remarks"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Remarks
                                        </label>
                                        <textarea
                                          id="remarks"
                                          name="remarks"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter remarks"
                                          defaultValue={currentItems?.remarks}
                                          rows="3"
                                        ></textarea>
                                      </div>
                                      {/* Status Dropdown */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="status"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Status
                                        </label>
                                        <select
                                          id="status"
                                          name="paymentStatus"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          defaultValue={
                                            currentItems?.payment_status
                                          }
                                        >
                                          <option value="PENDING">
                                            PENDING
                                          </option>
                                          <option value="APPROVED">
                                            APPROVED
                                          </option>
                                          <option value="REJECTED">
                                            REJECTED
                                          </option>
                                          <option value="CANCELED">
                                            CANCELED
                                          </option>
                                        </select>
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="text-white inline-flex items-center w-full justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      Edit
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* Process Modal */}
                          {isModalProcess && (
                            <div
                              id="crud-modal"
                              className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10"
                            >
                              <div className="relative p-4 w-full max-w-md h-auto">
                                {/* Modal Content */}
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                  {/* Modal Header */}
                                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                      Process Payment Request
                                    </h3>
                                    <button
                                      type="button"
                                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                      onClick={toggleModalProcessClose} // Correct function for toggling modal
                                    >
                                      <IoClose className="w-5 h-5" />
                                    </button>
                                  </div>

                                  {/* Modal Body */}
                                  <form
                                    className="p-4 md:p-5"
                                    onSubmit={handleProcessPayment}
                                  >
                                    <div className="grid gap-4 mb-4 grid-cols-2">
                                      {/* Remarks */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="remarks"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Remarks
                                        </label>
                                        <textarea
                                          id="remarks"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          placeholder="Enter remarks"
                                          name="remarks"
                                          rows="3"
                                        ></textarea>
                                      </div>

                                      {/* Status Dropdown */}
                                      <div className="col-span-2">
                                        <label
                                          htmlFor="status"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Status
                                        </label>
                                        <select
                                          id="status"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                          name="status"
                                        >
                                          <option value="PENDING">
                                            PENDING
                                          </option>
                                          <option value="APPROVED">
                                            APPROVED
                                          </option>
                                          <option value="REJECTED">
                                            REJECTED
                                          </option>
                                          <option value="PROCESSING">
                                            PROCESSING
                                          </option>
                                        </select>
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      Submit
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <nav
                className="flex items-center justify-between p-2"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Showing{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {indexOfFirstRow + 1} -{" "}
                    {Math.min(indexOfLastRow, paymentRequest.totalCount)}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {paymentRequest.totalCount}
                  </span>
                </span>

                <ul className="inline-flex items-center -space-x-px text-sm h-8">
                  {/* Previous Button */}
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handlePrev}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>

                  {/* Page Buttons */}
                  {currentPage > 2 && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(1)}
                      >
                        1
                      </button>
                    </li>
                  )}

                  {currentPage > 3 && <li className="px-2">...</li>}

                  {/* Previous Page */}
                  {currentPage > 1 && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(currentPage - 1)}
                      >
                        {currentPage - 1}
                      </button>
                    </li>
                  )}

                  {/* Current Page */}
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-blue-600 bg-blue-50 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => goToPage(currentPage)}
                    >
                      {currentPage}
                    </button>
                  </li>

                  {/* Next Page */}
                  {currentPage < totalPages && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(currentPage + 1)}
                      >
                        {currentPage + 1}
                      </button>
                    </li>
                  )}

                  {currentPage < totalPages - 2 && (
                    <li className="px-2">...</li>
                  )}

                  {currentPage < totalPages - 1 && (
                    <li>
                      <button
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => goToPage(totalPages)}
                      >
                        {totalPages}
                      </button>
                    </li>
                  )}

                  {/* Next Button */}
                  <li>
                    <button
                      className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handleNext}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>

            {/* Table End Code .....  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentTab;
