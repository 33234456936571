import { createSlice } from "@reduxjs/toolkit";
import {
  getAllprovider,
  getProviderDetails,
  saveProvider,
  editProvider
} from "../actions/provider";

export const providerSlice = createSlice({
  name: "providers",
  initialState: {
    loading: false,
    providerList: [],
    totalCount: 0,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      //getAllServices
      .addCase(getAllprovider.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllprovider.fulfilled, (state, action) => {
        state.loading = false;
        state.providerList = [...action.payload.result];
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(getAllprovider.rejected, (state) => {
        state.loading = false;
      })
      //saveProvider
      .addCase(saveProvider.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveProvider.fulfilled, (state, action) => {
        state.loading = false;
        state.providerList = [
          ...state.providerList,
          action.payload.data.result,
        ];
      })
      .addCase(saveProvider.rejected, (state) => {
        state.loading = false;
      })

      //editProvider
      .addCase(editProvider.pending, (state) => {
        state.loading = true;
      })
      .addCase(editProvider.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editProvider.rejected, (state) => {
        state.loading = false;
      })
      //getProviderDetails
      .addCase(getProviderDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderDetails.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getProviderDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentService } = providerSlice.actions;
export default providerSlice.reducer;
