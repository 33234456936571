import React from "react";
import HeaderOne from "./headerOne/HeaderOne";
import HeaderTwo from "./headerTwo/HeaderTwo";
import Footer from "./footer/Footer";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen dark:bg-textB">
      {/* Header */}
      <HeaderOne />
      <HeaderTwo className="" />

      {/* Main content area with flex-grow */}
      <div className="flex-grow mt-7 md:mt-6 lg:mt-8 ">{children}</div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Layout;
