import React from 'react'
import AdminLedgerTab from './AdminLedgerTab'

const AdminLadger = () => {
  return (
    <>
<AdminLedgerTab />


    </>
  )
}

export default AdminLadger