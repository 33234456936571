import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getTokenCookie } from "../utills/tools";

const PreventSignIn = (props) => {
  let location = useLocation();
  const [path, setPath] = useState(null);
  const token = getTokenCookie();

  
  useEffect(() => {
    if (token) {
      if (props.users.isAdmin) {
        setPath("/admin");
      } else {
        setPath("/user");
      }
    }
  }, [token]);

  return (
    <>
      {token ? (
        <Navigate to={path} state={{ from: location }} replace />
      ) : (
        props.children
      )}
    </>
  );
};

export default PreventSignIn;
